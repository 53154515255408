const PERMISSION_FOR_COMPONENTS = {
  PlanFiduciary: ['Plan Fiduciary'],
}

const getUserPermissions = () => {
  if (!window.store) return {}
  return window.store.getState()?.root?.global?.currentUser?.permissions || {}
}

const getActiveUserPermissions = () => {
  const activeUserPermissions = []
  const userPermissions = getUserPermissions()
  const permissionNames = Object.keys(userPermissions)

  // eslint-disable-next-line no-unused-vars
  for (const permissionName of permissionNames) {
    if (userPermissions[permissionName])
      activeUserPermissions.push(permissionName)
  }
  return activeUserPermissions
}

const hasPermission = ({ componentName }) => {
  const activeUserPermissions = getActiveUserPermissions()
  const permissions = PERMISSION_FOR_COMPONENTS[componentName] || []
  return permissions.some((permission) =>
    activeUserPermissions.includes(permission)
  )
}

function withPermission(componentName) {
  return hasPermission({ componentName })
}

export default withPermission
