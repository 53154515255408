// Check if a value is a valid Social Security Number (source: https://www.irs.gov/pub/irs-pdf/p1346.pdf page 335)
const validateSsn = (area, group, serial) => {
  let valid = false
  if (
    area >= 1 &&
    area <= 665 &&
    group >= 1 &&
    group <= 99 &&
    serial >= 1 &&
    serial <= 9999
  ) {
    valid = true
  } else if (
    area >= 667 &&
    area <= 899 &&
    group >= 1 &&
    group <= 99 &&
    serial >= 1 &&
    serial <= 9999
  ) {
    valid = true
  } else if (
    area >= 750 &&
    area <= 763 &&
    group >= 1 &&
    group <= 99 &&
    serial >= 1 &&
    serial <= 9999
  ) {
    valid = true
  } else if (
    area >= 764 &&
    area <= 899 &&
    group >= 1 &&
    group <= 99 &&
    serial >= 1 &&
    serial <= 9999
  ) {
    valid = true
  }

  return valid
}

// Check if a value is a valid Individual Taxpayer Identification Number (source: https://www.irs.gov/pub/irs-pdf/p4757.pdf page 5)
const validateItin = (area, group, serial) => {
  let valid = false
  if (
    area >= 900 &&
    area <= 999 &&
    group >= 50 &&
    group <= 65 &&
    serial >= 0 &&
    serial <= 9999
  ) {
    valid = true
  } else if (
    area >= 900 &&
    area <= 999 &&
    group >= 70 &&
    group <= 88 &&
    serial >= 1 &&
    serial <= 9999
  ) {
    valid = true
  } else if (
    area >= 900 &&
    area <= 999 &&
    group >= 90 &&
    group <= 92 &&
    serial >= 0 &&
    serial <= 9999
  ) {
    valid = true
  } else if (
    area >= 900 &&
    area <= 999 &&
    group >= 94 &&
    group <= 99 &&
    serial >= 0 &&
    serial <= 9999
  ) {
    valid = true
  }
  return valid
}

// Check if a value is a valid Adoption Taxpayer Identification Number (source: https://www.irs.gov/pub/irs-pdf/p1345.pdf page 10)
const validateAtin = (area, group, serial) => {
  return (
    area >= 900 && area <= 999 && group === 93 && serial >= 0 && serial <= 9999
  )
}

export default function validateTaxId(number) {
  const [area, group, serial] = number.split('-').map((part) => parseInt(part))

  return (
    validateSsn(area, group, serial) ||
    validateItin(area, group, serial) ||
    validateAtin(area, group, serial)
  )
}
