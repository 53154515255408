// Create a final row for the participants plan summary table (used in ARO participant file flow)
// this function is expected to be called with the result of the sumUpParticipantsSummaryData util
function createParticipantsSummaryOverviewRow(summaryOverview) {
  const {
    totalPlans,
    totalParticipants,
    traditionalIraPreTaxAmount,
    traditionalIraAfterTaxAmount,
    rothIraAmount,
  } = summaryOverview
  const pluralizedPlan = totalPlans === 1 ? 'Plan' : 'Plans'
  const pluralizedParticipant =
    totalParticipants === 1 ? 'Participant' : 'Participants'

  return {
    planName: `${totalPlans} ${pluralizedPlan}`,
    participants: `${totalParticipants} ${pluralizedParticipant}`,
    traditionalIraPreTaxAmount,
    traditionalIraAfterTaxAmount,
    rothIraAmount,
  }
}

export default createParticipantsSummaryOverviewRow
