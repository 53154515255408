import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Button } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function DownloadNotAvailableModal({ onClose }) {
  return (
    <Modal id="download-not-available-modal" onClose={onClose}>
      <h1>Download Not Available</h1>
      <p>
        We're sorry, but the PDF agreement is still being generated. Once you
        receive the confirmation email, downloading will be available.
      </p>
      <div className="modal-actions">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  )
}

DownloadNotAvailableModal.propTypes = exact(propTypes)
DownloadNotAvailableModal.defaultProps = defaultProps

export default React.memo(DownloadNotAvailableModal)
