import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { SubmitButton } from 'lp-components'
import { Input, Select, MaskedInput, ScrollErrorForm } from 'components'
import * as Yup from 'yup'
import { UserRole, sortedUserRoleOptions } from 'config'

const propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const userSchema = Yup.object().shape({
  user: Yup.object({
    firstName: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('required'),
    lastName: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('required'),
    email: Yup.string().required('required').email('invalid email address'),
    role: Yup.string()
      .required('required')
      .oneOf(sortedUserRoleOptions.map((userRole) => userRole.value)),
    phone: Yup.string()
      .required('required')
      .max(40, 'Must be 40 characters or less'),
  }),
})

function AddNewUserForm({ cancelHandler, handleSubmit }) {
  return (
    <Formik
      initialValues={{
        user: {
          firstName: '',
          lastName: '',
          email: '',
          role: UserRole.STANDARD,
          phone: '',
        },
      }}
      validationSchema={userSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <ScrollErrorForm>
          <div className="row">
            <div className="one-half column">
              <Input
                label="First Name"
                name="user[firstName]"
                type="text"
                placeholder="Enter first name"
              />
            </div>
            <div className="one-half column">
              <Input
                label="Last Name"
                name="user[lastName]"
                type="text"
                placeholder="Enter last name"
              />
            </div>
          </div>
          <div className="row">
            <div className="one-half column">
              <Select
                label="Role"
                name="user[role]"
                placeholder="Select role"
                options={sortedUserRoleOptions}
              />
            </div>
            <div className="one-half column">
              <MaskedInput
                name="user[phone]"
                labelComponent={({ id, name }) => (
                  <span>
                    <label htmlFor={id || name}>Phone</label>
                  </span>
                )}
                type="tel"
                placeholder="Enter phone"
                maskOptions={{
                  numericOnly: true,
                  blocks: [0, 3, 0, 3, 4],
                  delimiters: ['(', ')', ' ', '-'],
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="one-half column">
              <Input
                label="Email"
                name="user[email]"
                type="text"
                placeholder="Enter email"
              />
            </div>
          </div>
          <div className="form-actions">
            <SubmitButton submitting={isSubmitting}>Add User</SubmitButton>
            <button
              onClick={cancelHandler}
              className="button-text"
              type="button"
            >
              Cancel
            </button>
          </div>
        </ScrollErrorForm>
      )}
    </Formik>
  )
}

AddNewUserForm.propTypes = propTypes
AddNewUserForm.defaultProps = defaultProps

export default AddNewUserForm
