import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { isEmpty, reduce, omit, concat, forIn } from 'lodash'
import { Redirect, useHistory } from 'react-router-dom'
import { PlanConfirmationForm } from '../forms'
import { flashErrorMessage } from 'redux-flash'
import {
  TOAST_ERROR_CONTACT_SUPPORT,
  ServicesAgreementPlanType,
  ServicesAgreementPlanStatus,
  NO_SERVICES_AGREEMENT_OPTION,
  submissionMethodOptions,
  SubmissionMethod,
  ReasonForDiscard,
  Path,
} from 'config'
import { IneligiblePlanModal, AllParticipantsRemovedModal } from '../components'
import { IRA_AMOUNT, RecordTypes } from 'flatfile-config'
import {
  convertNumber,
  downloadDiscardedParticipants,
  getSelectInitialValue,
} from 'utils'

const propTypes = {
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  setPlanConfirmationAcknowledgementsForm: PropTypes.func.isRequired,
  planConfirmationAcknowledgementsForm: PropTypes.object,
  fetchCompletedServicesAgreements: PropTypes.func.isRequired,
  updatePlanAgreement: PropTypes.func.isRequired,
  participantsAgreementHash: PropTypes.object,
  completedServicesAgreements: PropTypes.arrayOf(
    Types.servicesAgreementSummary
  ),
  setDiscardedParticipants: PropTypes.func.isRequired,
  discardedParticipants: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  participantFileName: PropTypes.string,
}

const defaultProps = {
  planConfirmationAcknowledgementsForm: {},
  participantsAgreementHash: null,
  completedServicesAgreements: null,
  participantFileName: null,
}

const removeFromSubmissionOption = submissionMethodOptions.find(
  (option) => option.key === SubmissionMethod.REMOVE_FROM_SUBMISSION
)
const planTypes = Object.values(ServicesAgreementPlanType)
const planStatuses = Object.values(ServicesAgreementPlanStatus)

const getRolloverAmount = (participant) =>
  convertNumber(participant[IRA_AMOUNT.TRADITIONAL_PRE_TAX]) +
  convertNumber(participant[IRA_AMOUNT.TRADITIONAL_AFTER_TAX]) +
  convertNumber(participant[IRA_AMOUNT.ROTH])

const getTerminatingDefinedBenefitParticipants = (formValues) => {
  let terminatingDefinedBenefitParticipants = []
  forIn(formValues, ({ planType, planStatus, participants }) => {
    if (
      planType === ServicesAgreementPlanType.DEFINED_BENEFIT &&
      planStatus === ServicesAgreementPlanStatus.TERMINATING
    ) {
      terminatingDefinedBenefitParticipants.push(...participants)
    }
  })
  return terminatingDefinedBenefitParticipants
}

function allowableBenefitThresholdByDate() {
  const today = new Date() // Get the current date
  const januaryFirst2024 = new Date(2024, 0, 1) // Set a date object for January 1st, 2024
  // Compare the current date with January 1st, 2024
  if (today >= januaryFirst2024) {
    return 7000 // If it's after or on January 1st, 2024, return 7000
  } else {
    return 5000 // Otherwise, return 0
  }
}
const ALLOWABLE_BENEFIT_THRESHOLD = allowableBenefitThresholdByDate()
const getActiveBenefitAboveThresholdParticipants = (formValues) => {
  let activeBenefitAboveThresholdParticipants = []
  forIn(formValues, ({ planStatus, participants }) => {
    if (planStatus !== ServicesAgreementPlanStatus.ACTIVE) return

    const participantsAboveThreshold = participants.filter(
      (participant) =>
        getRolloverAmount(participant) > ALLOWABLE_BENEFIT_THRESHOLD
    )
    activeBenefitAboveThresholdParticipants.push(...participantsAboveThreshold)
  })
  return activeBenefitAboveThresholdParticipants
}

const ActiveBenefitExceedingThresholdParagraph =
  "This submission contains participants which have Active Defined Benefit or Active Defined Contribution plans with a present value of the participant's nonforfeitable accrued benefit in excess of $7,000. Please choose how you'd like to proceed. If you choose to remove the participants from this submission, you're still able to review and download the data prior to submission."

const ActiveBenefitTerminatingDefinedParagraph =
  "This submission contains participants with Terminating Defined Benefit plans. Please choose how you'd like to proceed. If you choose to remove the participants from this submission, you're still able to review and download the data prior to submission."

const TerminatingDefinedBenefitModal = (props) => (
  <IneligiblePlanModal {...props}>
    <div className="modal-header">
      <h1>Terminating Defined Benefit Plans</h1>
    </div>
  </IneligiblePlanModal>
)

const ActiveBenefitAboveThresholdModal = (props) => (
  <IneligiblePlanModal {...props}>
    <div className="modal-header">
      <h1>Active Plans Exceeding $7,000</h1>
    </div>
  </IneligiblePlanModal>
)

function PlanConfirmationView({
  participantsForSubmission,
  flashErrorMessageHandler,
  setPlanConfirmationAcknowledgementsForm,
  planConfirmationAcknowledgementsForm,
  fetchCompletedServicesAgreements,
  updatePlanAgreement,
  participantsAgreementHash,
  completedServicesAgreements,
  setDiscardedParticipants,
  discardedParticipants,
  participantFileName,
}) {
  const history = useHistory()
  const formRef = useRef()
  const [
    showActiveBenefitAboveThresholdModal,
    setShowActiveBenefitAboveThresholdModal,
  ] = useState(false)
  const [
    showTerminatingDefinedBenefitPlanModal,
    setShowTerminatingDefinedBenefitPlanModal,
  ] = useState(false)
  const [showAllParticipantsRemovedModal, setShowAllParticipantsRemovedModal] =
    useState(false)
  const activeBenefitAboveThresholdParticipants = useRef(null)
  const terminatedDefinedBenefitParticipants = useRef(null)
  // workaround for race condition between data in the redux store and form submission:
  const participantsToSubmit = useRef()
  participantsToSubmit.current = participantsForSubmission
  const participantsToDiscard = useRef()
  participantsToDiscard.current = discardedParticipants

  const plansInitialValues = reduce(
    participantsAgreementHash,
    (acc, { servicesAgreement: id, participants }) => {
      if (id === NO_SERVICES_AGREEMENT_OPTION.value) return acc
      const serviceAgreement = completedServicesAgreements.find(
        ({ serviceAgreementID }) => serviceAgreementID === id
      )
      // It is possible for the same services agreement to be stored under multiple keys in participantsAgreementHash
      // (if there were more than one version of the plan name value in the uploaded file, e.g., "Cyclades DB" vs "Cyclades DB Plan")
      if (acc[id]) {
        acc[id].participants = concat(acc[id].participants, participants)
        return acc
      }
      acc[id] = {
        planType: getSelectInitialValue(serviceAgreement.planType, planTypes),
        planStatus: getSelectInitialValue(
          serviceAgreement.planStatus,
          planStatuses
        ),
        planName: serviceAgreement.name,
        participants,
      }
      return acc
    },
    {}
  )

  const submitData = async (values) => {
    formRef.current.setSubmitting(true)
    try {
      let promises = []
      forIn(values, ({ planStatus, planType }, id) => {
        if (id !== 'confirm') {
          promises.push(
            updatePlanAgreement(id, {
              planStatus: planStatus,
              planType: planType,
            })
          )
        }
      })

      await Promise.allSettled(promises)
      await fetchCompletedServicesAgreements()
      setPlanConfirmationAcknowledgementsForm({ confirm: values.confirm })

      if (isEmpty(participantsToSubmit.current)) {
        formRef.current.setSubmitting(false)
        return setShowAllParticipantsRemovedModal(true)
      }
      history.push({
        pathname: '/automatic-rollovers/review-and-submit-participant-data',
        state: {
          search: history.location.state?.search,
        },
      })
    } catch (e) {
      formRef.current.setSubmitting(false)
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const valuesToSubmit = omit(values, 'confirm')
    activeBenefitAboveThresholdParticipants.current =
      getActiveBenefitAboveThresholdParticipants(valuesToSubmit)
    terminatedDefinedBenefitParticipants.current =
      getTerminatingDefinedBenefitParticipants(valuesToSubmit)

    if (activeBenefitAboveThresholdParticipants.current.length) {
      // set plan confirmation form.isSubmitting to false since the modal is being surfaced first
      setSubmitting(false)
      return setShowActiveBenefitAboveThresholdModal(true)
    } else {
      setDiscardedParticipants({
        participants: [],
        reasonForDiscard:
          ReasonForDiscard.ACTIVE_BENEFIT_ABOVE_ALLOWABLE_THRESHOLD,
      })
    }

    if (terminatedDefinedBenefitParticipants.current.length) {
      setSubmitting(false)
      return setShowTerminatingDefinedBenefitPlanModal(true)
    } else {
      setDiscardedParticipants({
        participants: [],
        reasonForDiscard: ReasonForDiscard.TERMINATING_DEFINED_BENEFIT,
      })
    }

    return submitData(values)
  }

  if (isEmpty(participantsAgreementHash)) {
    return <Redirect to="/automatic-rollovers/upload-file" />
  }

  return (
    <div className="card full-height upload-participant-file-view-container">
      <div className="form-section">
        <PlanConfirmationForm
          initialValues={{
            ...plansInitialValues,
            confirm: false,
            ...planConfirmationAcknowledgementsForm,
          }}
          onSubmit={handleSubmit}
          ref={formRef}
        />
      </div>
      {showActiveBenefitAboveThresholdModal && (
        <ActiveBenefitAboveThresholdModal
          planNames={activeBenefitAboveThresholdParticipants.current?.map(
            ({ planName }) => planName
          )}
          planListTitle="Plans Exceeding $7,000"
          disclosureParagraph={ActiveBenefitExceedingThresholdParagraph}
          onClose={() => setShowActiveBenefitAboveThresholdModal(false)}
          onContinue={({ submissionMethod }) => {
            const discardParticipants =
              submissionMethod === removeFromSubmissionOption.value
            setDiscardedParticipants({
              participants: discardParticipants
                ? activeBenefitAboveThresholdParticipants.current
                : [],
              reasonForDiscard:
                ReasonForDiscard.ACTIVE_BENEFIT_ABOVE_ALLOWABLE_THRESHOLD,
            })
            setShowActiveBenefitAboveThresholdModal(false)
            if (terminatedDefinedBenefitParticipants.current?.length) {
              return setShowTerminatingDefinedBenefitPlanModal(true)
            } else {
              setDiscardedParticipants({
                participants: [],
                reasonForDiscard: ReasonForDiscard.TERMINATING_DEFINED_BENEFIT,
              })
            }
            submitData(formRef.current.values)
          }}
        />
      )}
      {showTerminatingDefinedBenefitPlanModal && (
        <TerminatingDefinedBenefitModal
          planNames={terminatedDefinedBenefitParticipants.current?.map(
            ({ planName }) => planName
          )}
          planListTitle={'Terminating Plans'}
          disclosureParagraph={ActiveBenefitTerminatingDefinedParagraph}
          onClose={() => setShowTerminatingDefinedBenefitPlanModal(false)}
          onContinue={({ submissionMethod }) => {
            const discardParticipants =
              submissionMethod === removeFromSubmissionOption.value
            setDiscardedParticipants({
              participants: discardParticipants
                ? terminatedDefinedBenefitParticipants.current
                : [],
              reasonForDiscard: ReasonForDiscard.TERMINATING_DEFINED_BENEFIT,
            })
            setShowTerminatingDefinedBenefitPlanModal(false)
            submitData(formRef.current.values)
          }}
        />
      )}
      {showAllParticipantsRemovedModal && (
        <AllParticipantsRemovedModal
          onClose={() => setShowAllParticipantsRemovedModal(false)}
          onConfirm={() => {
            setShowAllParticipantsRemovedModal(false)
            history.push({
              pathname: Path.SERVICES_AGREEMENTS,
              state: {
                ignorePrompt: true,
                state: {
                  search: history.location.state?.search,
                },
              },
            })
          }}
          handleDownload={() =>
            downloadDiscardedParticipants({
              participants: participantsToDiscard.current,
              originalFileName: participantFileName,
              recordType: RecordTypes.ARO_PARTICIPANT,
            })
          }
        />
      )}
    </div>
  )
}

PlanConfirmationView.propTypes = propTypes
PlanConfirmationView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    participantsForSubmission: selectors.participantsForSubmission(state),
    planConfirmationAcknowledgementsForm:
      selectors.planConfirmationAcknowledgementsForm(state),
    participantsAgreementHash: selectors.participantsAgreementHash(state),
    completedServicesAgreements: selectors.completedServicesAgreements(state),
    discardedParticipants: selectors.discardedParticipants(state),
    participantFileName: selectors.participantFileName(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessageHandler: flashErrorMessage,
  setPlanConfirmationAcknowledgementsForm:
    actions.setPlanConfirmationAcknowledgementsForm,
  fetchCompletedServicesAgreements: apiActions.fetchCompletedServicesAgreements,
  updatePlanAgreement: apiActions.updatePlanAgreement,
  setDiscardedParticipants: actions.setDiscardedParticipants,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PlanConfirmationView
)
