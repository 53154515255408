import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Icon as UserIcon } from 'images/user-icon.svg'
import { Icon as LockIcon } from 'images/lock.svg'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  return (
    <div className="content-wrapper profile-layout">
      <aside>
        <ul className="card profile-nav">
          <li>
            <NavLink to="/profile/details" className="profile-links">
              <UserIcon aria-hidden="true" />
              Profile Details
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile/password" className="profile-links">
              <LockIcon aria-hidden="true" />
              Password
            </NavLink>
          </li>
        </ul>
      </aside>
      {children}
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

export default Layout
