import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Button } from 'lp-components'
import { AroActionItems } from 'config'
import { ExternalLink } from 'components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
}

const defaultProps = {}

function StartNewRolloverModal({ onClose, onContinue }) {
  return (
    <Modal id="start-new-rollover-modal" onClose={onClose}>
      <h1>{AroActionItems.INITIATE_ROLLOVER}</h1>
      <p>
        We've made a few changes to our rollover process. Please review the
        following items before you initiate a new rollover.
      </p>
      <ul>
        <li>
          A single rollover submission may include participants from several
          different retirement plans and/or plan sponsors.
        </li>
        <li>
          All rollovers will be required to have a completed Services Agreement
          on file for the corresponding retirement plan.
        </li>
        <li>
          All participant data spreadsheets must follow our updated participant
          data file template.{' '}
          <ExternalLink
            link={process.env.REACT_APP_ARO_EZ_WORKSHEET_TEMPLATE_URL}
            className="link-text"
          >
            Download Updated Spreadsheet
          </ExternalLink>
        </li>
      </ul>
      <div className="modal-actions">
        <Button onClick={onContinue}>Continue</Button>
        <Button className="button-cancel" onClick={onClose} variant="text">
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

StartNewRolloverModal.propTypes = exact(propTypes)
StartNewRolloverModal.defaultProps = defaultProps

export default React.memo(StartNewRolloverModal)
