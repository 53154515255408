import React from 'react'
import { ResourceCard } from '../components'
import { COMPANY_NAME } from 'config'

const propTypes = {}

const defaultProps = {}

const RESOURCES = [
  {
    videoId: 'nwmXTt9RUeo',
    header: 'Navigating the Retirement Services Platform',
    content:
      'In this video, you’ll learn how to log in and navigate our core features. This includes how to access your subscribed products, view and manage portal users, access your profile, reset your password and how to contact us.',
    length: '5:13',
    date: '01/05/2023',
  },
  {
    videoId: 'hagSq_aurxk',
    header: 'Accessing your Automatic Rollovers Product',
    content: `In this video, you’ll learn how to access your Automatic Rollovers product. Plus, we’ll show you where to view and manage your Services Agreements that are on file with ${COMPANY_NAME.short}.`,
    length: '3:51',
    date: '01/05/2023',
  },
  {
    videoId: 'erEzoXIj54Y',
    header: 'Initiating a Rollover',
    content:
      'In this video, you’ll learn how to use the Retirement Services Platform to initiate a rollover.',
    length: '7:38',
    date: '01/05/2023',
  },
  {
    videoId: 'kOqFvwhFwc4',
    header: 'Creating New Services Agreements',
    content:
      'In this video, you’ll learn how to use the Retirement Services Platform to create a new Services Agreement.',
    length: '4:52',
    date: '12/21/2022',
  },
]

function Resources() {
  return (
    <div className="resources">
      <div className="resources-header">
        <h1>Resources</h1>
        <p>
          You can use these resources to help you make the most of your{' '}
          {COMPANY_NAME.medium} retirement services.
        </p>
      </div>
      <div className="resources-container">
        {RESOURCES.map((resource) => (
          <ResourceCard key={resource.videoId} {...resource} />
        ))}
      </div>
    </div>
  )
}

Resources.propTypes = propTypes

Resources.defaultProps = defaultProps

export default Resources
