import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'
import { TimeoutModal, Tooltip, PlanEinTooltipContent } from 'components'
import { displayEin, displayAttribute } from 'utils'
import { UploadSignedPlanAgreementForm } from '../forms'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashErrorMessage } from 'redux-flash'
import * as apiActions from 'api-actions'
import * as globalActions from '../../actions'
import {
  TOAST_ERROR_CONTACT_SUPPORT,
  UPLOAD_AGREEMENT_SUCCESS_MESSAGE,
  INITIAL_INVESTMENT_TOOLTIP_CONTENT,
  COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT,
  SERVICES_AGREEMENT_UPLOAD_INSTRUCTIONS,
  VIRUS_FILE_ERROR_MESSAGE,
} from 'config'
import { Icon as InfoIcon } from 'images/info.svg'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  servicesAgreement: PropTypes.oneOfType([
    Types.servicesAgreementSummary,
    Types.servicesAgreementShow,
  ]).isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  submitServicesAgreementFile: PropTypes.func.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
}

const defaultProps = {}

function UploadPlanAgreementModal({
  onClose,
  servicesAgreement,
  onSubmitSuccess,
  flashErrorMessageHandler,
  submitServicesAgreementFile,
  showSuccessModal,
}) {
  const [hasTimedOut, setHasTimedOut] = useState(false)
  const handleSubmit = async (
    { signedAgreement },
    { setFieldError, setFieldValue }
  ) => {
    try {
      const formData = new FormData()
      formData.append('submitFile', signedAgreement, signedAgreement.name)
      formData.append(
        'submitData',
        JSON.stringify({
          serviceAgreementID: servicesAgreement.serviceAgreementID,
          planName: servicesAgreement.name,
          planEIN: servicesAgreement.planEIN,
          // to-do: add agreementTemplateID to formData once logic to conditionally render agreement type field is implemented
        })
      )
      await submitServicesAgreementFile({
        planAgreementId: servicesAgreement.serviceAgreementID,
        formData,
      })
      showSuccessModal({
        title: 'Request Received',
        message: UPLOAD_AGREEMENT_SUCCESS_MESSAGE,
      })
      onSubmitSuccess()
    } catch (e) {
      if (e.status === 400) {
        setFieldValue('signedAgreement', '', false)
        setFieldError('signedAgreement', VIRUS_FILE_ERROR_MESSAGE)
      } else if (e.status === 504) {
        setHasTimedOut(true)
      } else {
        flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
      }
    }
  }

  if (hasTimedOut) {
    return <TimeoutModal onClose={onClose} />
  }

  return (
    <Modal id="upload-services-agreement-modal" onClose={onClose}>
      <h1>Upload Signed Services Agreement</h1>
      <p>{SERVICES_AGREEMENT_UPLOAD_INSTRUCTIONS}</p>
      <dl className="plan-details-container">
        <div className="plan-details-column">
          <dt>
            Complete Legal Plan Name:
            <Tooltip
              content={COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT}
              ariaLabel="Complete legal plan name information"
            >
              <InfoIcon className="icon-info" />
            </Tooltip>
          </dt>
          <dd>{displayAttribute(servicesAgreement.name)}</dd>
        </div>
        <div className="plan-details-column">
          <dt>
            Employer Identification Number (EIN):
            <Tooltip
              content={<PlanEinTooltipContent />}
              ariaLabel="Plan EIN information"
            >
              <InfoIcon className="icon-info" />
            </Tooltip>
          </dt>
          <dd>{displayEin(servicesAgreement.planEIN)}</dd>
        </div>
        <div className="plan-details-column">
          <dt>
            Initial Investment:
            <Tooltip
              content={INITIAL_INVESTMENT_TOOLTIP_CONTENT}
              ariaLabel="Initial investment information"
            >
              <InfoIcon className="icon-info" />
            </Tooltip>
          </dt>
          <dd>{displayAttribute(servicesAgreement.portalAgreementType)}</dd>
        </div>
      </dl>
      <UploadSignedPlanAgreementForm
        handleSubmit={handleSubmit}
        handleCancel={onClose}
      />
    </Modal>
  )
}

function mapStateToProps(/* state */) {
  return {}
}

const mapDispatchToProps = {
  showSuccessModal: globalActions.showSuccessModal,
  flashErrorMessageHandler: flashErrorMessage,
  submitServicesAgreementFile: apiActions.submitServicesAgreementFile,
}

UploadPlanAgreementModal.propTypes = exact(propTypes)
UploadPlanAgreementModal.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadPlanAgreementModal
)
