import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { CategoryStatusBreadcrumbTab } from 'components'

const propTypes = {
  stages: PropTypes.arrayOf(Types.stage).isRequired,
  currentStageName: PropTypes.string.isRequired,
}

const defaultProps = {}

const Status = {
  COMPLETE: 'complete',
  CURRENT: 'current',
}

function getStatus(stageIndex, currentStageIndex) {
  if (stageIndex === currentStageIndex) return Status.CURRENT
  if (currentStageIndex > stageIndex) return Status.COMPLETE
}

function StatusTracker({ stages, currentStageName }) {
  const currentStageIndex = stages.findIndex(
    (stage) => stage.name === currentStageName
  )
  return (
    <div aria-label="progress" className="workflow-status-tracker-wrapper">
      <ol className="workflow-status-tracker">
        {stages.map((stage, idx) => {
          const status = getStatus(idx, currentStageIndex)
          return (
            <li key={stage.name}>
              <CategoryStatusBreadcrumbTab
                complete={status === Status.COMPLETE}
                current={status === Status.CURRENT}
                label={stage.label}
              />
            </li>
          )
        })}
      </ol>
    </div>
  )
}

StatusTracker.propTypes = exact(propTypes)
StatusTracker.defaultProps = defaultProps

export default React.memo(StatusTracker)
