import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FilterIcon } from 'components'

const propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}

const defaultProps = {}

function Filter({ isActive, toggleMenu }) {
  return (
    <div className="filter-container">
      <button className="filter-btn" type="button" onClick={() => toggleMenu()}>
        <FilterIcon isActive={isActive} />
        <span>Filter</span>
      </button>
    </div>
  )
}

Filter.propTypes = exact(propTypes)
Filter.defaultProps = defaultProps

export default React.memo(Filter)
