import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { SubmitButton } from 'lp-components'
import { Input, Select, MaskedInput, ScrollErrorForm } from 'components'
import * as Yup from 'yup'
import { UserStatus, sortedUserRoleOptions } from 'config'
import { filter } from 'lodash'
import { generateSelectOptionsFromObject, sortSelectOptions } from 'utils'

const propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

const userStatusOptions = generateSelectOptionsFromObject(UserStatus)

const userSchema = Yup.object().shape({
  user: Yup.object({
    firstName: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('required'),
    lastName: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('required'),
    phone: Yup.string()
      .required('required')
      .max(40, 'Must be 40 characters or less'),
    role: Yup.string()
      .required('required')
      .oneOf(sortedUserRoleOptions.map((userRole) => userRole.value)),
    status: Yup.string()
      .required('required')
      .oneOf(userStatusOptions.map((userStatus) => userStatus.value)),
  }),
})

const getSelectOptions = (optionsArray, initialValue) => {
  if (initialValue === UserStatus.INVITED)
    return filter(optionsArray, (o) => o.value !== UserStatus.ACTIVE)

  return filter(optionsArray, (o) => o.value !== UserStatus.INVITED)
}

function EditUserForm({ initialValues, cancelHandler, handleSubmit }) {
  const { status } = initialValues.user
  const filteredUserStatusOptions = getSelectOptions(userStatusOptions, status)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <ScrollErrorForm>
            <div className="row">
              <div className="one-half column">
                <Input
                  label="First Name"
                  name="user[firstName]"
                  type="text"
                  placeholder="Enter first name"
                />
              </div>
              <div className="one-half column">
                <Input
                  label="Last Name"
                  name="user[lastName]"
                  type="text"
                  placeholder="Enter last name"
                />
              </div>
            </div>
            <div className="row">
              <div className="one-half column">
                <Select
                  label="Role"
                  name="user[role]"
                  placeholder="Select role"
                  options={sortedUserRoleOptions}
                />
              </div>
              <div className="one-half column">
                <Select
                  label="Status"
                  name="user[status]"
                  placeholder="Select status"
                  options={sortSelectOptions(filteredUserStatusOptions)}
                />
              </div>
            </div>
            <div className="row">
              <div className="one-half column">
                <MaskedInput
                  name="user[phone]"
                  label={<>Phone</>}
                  type="tel"
                  placeholder="Enter phone"
                  maskOptions={{
                    numericOnly: true,
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ['(', ')', ' ', '-'],
                  }}
                />
              </div>
              <div className="one-half column">
                <Input label="Email" name="user[email]" type="text" readOnly />
              </div>
            </div>
            <div className="form-actions">
              <SubmitButton submitting={isSubmitting}>
                Save Changes
              </SubmitButton>
              <button
                onClick={cancelHandler}
                className="button-text"
                type="button"
              >
                Cancel
              </button>
            </div>
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

EditUserForm.propTypes = propTypes
EditUserForm.defaultProps = defaultProps

export default EditUserForm
