import React from 'react'
import PropTypes from 'prop-types'
import { hasInputError, LabeledField } from 'lp-components'
import Select from 'react-select'
import { withFormikAdapter } from 'utils'
import { has, flatten, isArray } from 'lodash'
import classNames from 'classnames'

const propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  maxOptions: PropTypes.number, // maximum # of options to display in the scrollable container
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  marginTop: PropTypes.string,
}

const defaultProps = {
  id: null,
  isMulti: false,
  maxOptions: 6,
  className: '',
  marginTop: '8px',
}

const DEFAULT_OPTION_HEIGHT = 32
const DEFAULT_MENU_PADDING_TOP = 4
const DEFAULT_MENU_PADDING_BOTTOM = 4
const DEFAULT_MENU_BORDER_RADIUS = 8
const DEFAULT_BORDER_COLOR = '#8B8B89'
const DEFAULT_PLACEHOLDER_COLOR = '#646461'
const DEFAULT_INPUT_COLOR = '#3D3D3A'

// Determine the maximum height of the expanded, scrollable dropdown list
// Note: this does not account for extra height added for grouped options
const calculateMaxHeight = (maxOptions) => {
  return (
    DEFAULT_MENU_PADDING_TOP +
    DEFAULT_MENU_PADDING_BOTTOM +
    DEFAULT_OPTION_HEIGHT * maxOptions +
    // add extra height to provide a visual cue on additional options below:
    DEFAULT_OPTION_HEIGHT / 4
  )
}

// react-select expects options have "label" and "value"
const serializeOptions = (optionArray) => {
  return optionArray.map((option) => {
    if (typeof option === 'object') {
      // grouped option:
      if (has(option, 'options')) {
        return {
          label: option.key ?? option.label,
          options: serializeOptions(option.options),
        }
      }
      if (has(option, 'key')) {
        return {
          label: option.key,
          value: option.value,
        }
      }
      return option
    }
    return {
      label: option,
      value: option,
    }
  })
}

const getValue = (options, selection) => {
  const allOptions = flatten(
    options.map((option) => {
      // if it's a grouped option, return just its options
      if (has(option, 'options')) return option.options
      return option
    })
  )

  // if the selection is an array, it is a multi select input
  if (isArray(selection)) {
    return allOptions.filter((option) => selection.includes(option.value))
  }
  return allOptions.find((option) => option.value === selection)
}

function SearchableSelect(props) {
  const {
    input: { name, onBlur, onChange, value },
    id,
    meta,
    options,
    maxOptions,
    isMulti,
    className, // eslint-disable-line no-unused-vars
    marginTop,
    ...rest
  } = props

  const selectOptions = serializeOptions(options)

  const onChangeHandler = (option, { action }) => {
    if (action === 'clear') return onChange(isMulti ? [] : '')

    if (isMulti) {
      const multiValues = option.map((item) => item.value)
      return onChange(multiValues)
    }
    return onChange(option.value)
  }

  const hasError = hasInputError(meta)

  return (
    <LabeledField
      id={id || name}
      {...props}
      className={classNames('searchable-select', props.className)}
    >
      <Select
        inputId={id || name}
        options={selectOptions}
        maxMenuHeight={calculateMaxHeight(maxOptions)}
        value={getValue(selectOptions, value)}
        onChange={onChangeHandler}
        isMulti={isMulti}
        onBlur={onBlur}
        aria-describedby={hasError ? `${name}-error` : null}
        {...rest}
        styles={{
          control: (provided, { isFocused }) => ({
            ...provided,
            borderRadius: DEFAULT_MENU_BORDER_RADIUS,
            borderColor: DEFAULT_BORDER_COLOR,
            marginTop: marginTop,
            border:
              !isFocused && hasError
                ? '1px solid #E32416'
                : '1px solid #8b8b89',
            backgroundColor: !isFocused && hasError ? '#FEF4F3' : 'white',
          }),
          menu: (provided) => ({
            ...provided,
            paddingTop: DEFAULT_MENU_PADDING_TOP,
            paddingBottom: DEFAULT_MENU_PADDING_BOTTOM,
            borderRadius: DEFAULT_MENU_BORDER_RADIUS,
          }),
          option: (provided) => ({
            ...provided,
            minHeight: DEFAULT_OPTION_HEIGHT,
            borderRadius: DEFAULT_MENU_BORDER_RADIUS,
            maxWidth: '100%',
            lineHeight: '1.2rem',
            overflowWrap: 'break-word',
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
          }),
          input: (provided) => ({
            ...provided,
            padding: '0',
            margin: '0',
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: DEFAULT_BORDER_COLOR,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: DEFAULT_BORDER_COLOR,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: DEFAULT_PLACEHOLDER_COLOR,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: DEFAULT_INPUT_COLOR,
          }),
        }}
      />
    </LabeledField>
  )
}

SearchableSelect.propTypes = propTypes
SearchableSelect.defaultProps = defaultProps

export default withFormikAdapter()(SearchableSelect)
