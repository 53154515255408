import React from 'react'
import exact from 'prop-types-exact'
import { Icon as AccountCircleIcon } from 'images/account-circle.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { clearSessionToken } from '../services/auth'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuLink,
  MenuItem,
} from '@reach/menu-button'
import { Link } from 'react-router-dom'

const propTypes = {}

function AccountDetails() {
  const { logout } = useAuth0()

  function handleLogout() {
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }

  return (
    <div className="account-details">
      <Menu>
        <MenuButton
          className="account-details-icon-btn"
          aria-label="view account options"
        >
          <AccountCircleIcon />
        </MenuButton>
        <MenuList className="account-details--dropdown">
          <MenuLink to="/profile/details" as={Link}>
            My Profile
          </MenuLink>
          <MenuItem onSelect={handleLogout}>Log Out</MenuItem>
        </MenuList>
      </Menu>
    </div>
  )
}

AccountDetails.propTypes = exact(propTypes)

export default AccountDetails
