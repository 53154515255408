import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { scrollToTop } from 'utils'
import { SortableTable } from 'lp-components'
import { Paginator, EmptyState } from 'components'
import { castArray, get, has, isEmpty } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  data: PropTypes.array,
  initialAscending: PropTypes.bool,
  initialColumn: PropTypes.string,
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number,
  parentScrollContainerSelector: PropTypes.string,
  className: PropTypes.string,
  noRowsFoundMessage: PropTypes.node,
  tableId: PropTypes.string.isRequired,
  disableSort: PropTypes.bool,
}

const defaultProps = {
  initialAscending: true,
  initialColumn: '',
  data: [],
  pageSize: 10,
  parentScrollContainerSelector: null,
  className: '',
  noRowsFoundMessage: null,
  disableSort: false,
}

// Get column info from children via props
function getColumnData(children = [], doDisable) {
  const childrenArray = castArray(children)
  return childrenArray
    .filter((child) => has(child, 'props'))
    .map(({ props }) => {
      // If sort is disabled, disable all columns
      const disabled = doDisable || props.disabled || false
      return { ...props, disabled }
    })
}

function PaginatedAndSortableTable({
  children,
  tableId,
  initialAscending,
  initialColumn,
  data,
  pageSize,
  parentScrollContainerSelector,
  className,
  noRowsFoundMessage,
  disableSort,
}) {
  const columns = getColumnData(children, disableSort)
  const initialColumnObject = columns.find((col) => col.name === initialColumn)
  const [currentPage, setCurrentPage] = useState(1)
  const [allData, setAllData] = useState(data)
  const [paginatedData, setPaginatedData] = useState()
  const [sortAscending, setSortAscending] = useState(initialAscending)
  const [sortPath, setSortPath] = useState(initialColumn)
  const [sortFunc, setSortFunc] = useState(() => initialColumnObject?.sortFunc)
  const [startRecordIndex, setStartRecordIndex] = useState(0)
  const [endRecordIndex, setEndRecordIndex] = useState(0)

  const numRecords = data.length
  const numPages = Math.ceil(numRecords / pageSize)

  const setCurrentPageData = (data) => {
    setPaginatedData(
      data.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    )
  }

  const handleSort = useCallback(
    ({ ascending, sortPath, sortFunc }) => {
      setSortAscending(ascending)
      setSortPath(sortPath)
      setSortFunc(sortFunc)

      const sortedData = data.sort((a, b) => {
        const formattedA = get(a, sortPath) ?? '-'
        const formattedB = get(b, sortPath) ?? '-'
        const sortDirectionFactor = ascending ? 1 : -1
        if (sortFunc)
          return sortFunc(formattedA, formattedB) * sortDirectionFactor
        return (
          formattedA.localeCompare(formattedB, 'en', {
            sensitivity: 'base',
          }) * sortDirectionFactor
        )
      })

      setAllData(sortedData)
      setCurrentPageData(sortedData)
    },
    [data]
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [numPages])

  // preserve last sortPath and sortDirection when underlying
  // data passed to component changes ie. table filter
  useEffect(() => {
    if (data)
      handleSort({
        ascending: sortAscending,
        sortPath,
        sortFunc,
      })
  }, [data])

  useEffect(() => {
    if (allData) setCurrentPageData(allData)
    scrollToTop(parentScrollContainerSelector)
    setStartRecordIndex((currentPage - 1) * pageSize + 1)
    setEndRecordIndex(
      currentPage === numPages ? numRecords : currentPage * pageSize
    )
  }, [currentPage])

  return (
    <div className={`table-container ${className}-container`}>
      <SortableTable
        data={paginatedData}
        initialColumn={initialColumn}
        onChange={handleSort}
        controlled={true}
        id={tableId}
        className={classnames(className, { 'has-paginator': numPages > 1 })}
        initialAscending={initialAscending}
        disableSort={disableSort}
      >
        {children}
      </SortableTable>
      {numPages > 0 && (
        <Paginator
          currentPage={currentPage}
          onChange={setCurrentPage}
          alwaysShow={false}
          max={numPages}
          numRecords={numRecords}
          startRecordIndex={startRecordIndex}
          endRecordIndex={endRecordIndex}
        />
      )}
      {isEmpty(paginatedData) && !!noRowsFoundMessage && (
        <EmptyState>{noRowsFoundMessage}</EmptyState>
      )}
    </div>
  )
}

PaginatedAndSortableTable.propTypes = exact(propTypes)
PaginatedAndSortableTable.defaultProps = defaultProps

export default React.memo(PaginatedAndSortableTable)
