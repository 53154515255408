import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { SearchTypeForm } from '../forms'
import * as Types from 'types'
import { SearchTypeID, Path } from 'config'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as productSelectors } from '../../products/reducer'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { ComponentFailure, Spinner } from 'components'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
  fetchSearchServices: PropTypes.func.isRequired,
  searchServices: PropTypes.arrayOf(Types.searchService),
  onContinue: PropTypes.func.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
  searchServices: null,
}

function SearchTypeView({
  newSearchFlowData,
  setNewSearchFlowData,
  fetchSearchServices,
  searchServices,
  onContinue,
}) {
  const history = useHistory()
  const [error, setError] = useState(false)
  const isUploadCompleted = !!newSearchFlowData?.recordsData

  useEffect(() => {
    async function getSearchServices() {
      try {
        await fetchSearchServices()
      } catch {
        setError(true)
      }
    }
    if (!searchServices) getSearchServices()
  }, [searchServices])

  const isLoading = !searchServices && !error

  if (isLoading)
    return (
      <div className="search-details card full-height">
        <Spinner />
      </div>
    )

  if (error) return <ComponentFailure />

  return (
    <div className="search-details card full-height">
      <div className="form-masthead">
        <h2>Select the search type you would like</h2>
      </div>
      <SearchTypeForm
        initialValues={{
          searchType:
            newSearchFlowData?.searchType ?? SearchTypeID.ADDRESS_SEARCH,
        }}
        isUploadCompleted={isUploadCompleted}
        onSubmit={({ searchType }) => {
          const selectedSearch = searchServices.find(
            ({ searchService }) => searchService === searchType
          )
          const shouldResetUpload =
            isUploadCompleted &&
            searchType === SearchTypeID.BENEFICIARY_SEARCH &&
            searchType !== newSearchFlowData?.searchType
          setNewSearchFlowData({
            ...newSearchFlowData,
            searchType,
            turnaroundTime: selectedSearch.turnaroundTime,
            recordsData: shouldResetUpload
              ? null
              : newSearchFlowData?.recordsData,
          })
          onContinue(searchType)
          history.push(Path.UPLOAD_FILE)
        }}
        searchServices={searchServices}
      />
    </div>
  )
}
SearchTypeView.propTypes = propTypes
SearchTypeView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
    searchServices: productSelectors.searchServices(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
  fetchSearchServices: apiActions.fetchSearchServices,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchTypeView
)
