import { COUNTRIES, COUNTRY_ALIAS_DICT } from 'config'

function findCountry(val) {
  const normalizedUserInput = val
    .toUpperCase()
    .replace(/\s+/g, ' ')
    .trim()

  return COUNTRIES.find(({ fullName, value }) => {
    if (value === normalizedUserInput) return true

    const normalizedName = fullName.toUpperCase()
    return (
      normalizedName === normalizedUserInput ||
      COUNTRY_ALIAS_DICT[normalizedUserInput] === value
    )
  })
}

export default findCountry
