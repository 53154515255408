import React from 'react'
import PropTypes from 'prop-types'
import {
  FlowActions,
  AddressFields,
  CompanyDetailsFields,
  ContactDetailsFields,
  CompanyDetails,
} from '../components'
import {
  Select,
  Input,
  MaskedInput,
  ScrollErrorForm,
  SearchableSelect,
  LabelWithTooltip,
  PlanEinTooltipContent,
  Notification,
  OptionalLabel,
  ConfirmationCheckbox,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  US_STATES,
  COUNTRIES,
  EIN_REGEX,
  PROVIDER_PLAN_ID_NUMBER_REGEX,
  sortedPlanTypeOptions,
  planTypeValues,
  sortedPlanStatusOptions,
  planStatusValues,
  ADD_PLAN_SPONSOR_OPTION,
  ADD_RECORDKEEPER_OPTION,
  ADD_TPA_OPTION,
  ADD_CONSULTANT_OPTION,
  COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT,
  US_COUNTRY_VALUE,
  CANADA_COUNTRY_VALUE,
  AudienceType,
  US_ZIP_CODE_REGEX,
  E_SIGNATURE_CONFIRMATION_TEXT,
  InvestmentType,
  CompanyType,
  valicFiveInvestmentTypes,
  lincolnInvestmentTypes,
} from 'config'
import { map, concat, get, isEmpty } from 'lodash'
import { mergeAll } from 'lodash/fp'
import {
  generateSelectOptions,
  sortSelectOptions,
  getEntityInitialValues,
} from 'utils'
import * as Types from 'types'
import * as Sentry from '@sentry/browser'
import { downloadStableValueFactSheet } from 'api'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  planSponsors: PropTypes.arrayOf(Types.audienceDetails).isRequired,
  recordkeepers: PropTypes.arrayOf(Types.audienceDetails),
  tpasAndConsultants: PropTypes.arrayOf(Types.audienceDetails),
  availableInitialInvestments: PropTypes.arrayOf(
    Types.availableInitialInvestment
  ).isRequired,
  initialValues: PropTypes.object,
  allowRecordkeeperSelection: PropTypes.bool.isRequired,
  allowTpaConsultantSelection: PropTypes.bool.isRequired,
  isPlanFiduciary: PropTypes.bool.isRequired,
  currentUser: Types.user.isRequired,
}

const defaultProps = {
  initialValues: {},
  recordkeepers: null,
  tpasAndConsultants: null,
}

const UNKNOWN_RECORDKEEPER_OPTION = {
  key: 'Self-Administered',
  value: 'unknown-recordkeeper',
}

const companyFields = {
  name: '',
  type: '',
  phone: '',
  website: '',
  billingStreet: '',
  billingCountry: US_COUNTRY_VALUE,
  billingState: '',
  billingPostalCode: '',
  billingCity: '',
}
const contactFields = {
  ID: '',
  firstName: '',
  lastName: '',
  title: '',
  phone: '',
  email: '',
  isSameAsCompanyAddress: true,
  mailingStreet: '',
  mailingCountry: US_COUNTRY_VALUE,
  mailingState: '',
  mailingPostalCode: '',
  mailingCity: '',
}

const baseInitialValues = {
  planDetails: {
    name: '',
    ein: '',
    type: '',
    status: '',
    planNumber: '',
    providerPlanIdNumber: '',
    initialInvestment: '',
  },
  signingMethod: '',
  planSponsor: '',
  planSponsorDetails: {
    company: {
      ...companyFields,
      type: AudienceType.PLAN_SPONSOR,
    },
    contact: { ...contactFields },
  },
  recordkeeper: '',
  recordkeeperDetails: {
    company: {
      ...companyFields,
      type: AudienceType.RECORD_KEEPER,
    },
    contact: { ...contactFields },
  },
  tpaConsultant: '',
  tpaConsultantDetails: {
    company: { ...companyFields }, // type is specified within SearchableSelect onChange
    contact: { ...contactFields },
  },
  confirm: false,
}

const US_STATE_OPTIONS = map(US_STATES, (state) => {
  const { value, fullName } = state
  return { key: fullName, value }
})
const US_STATE_VALUES = map(US_STATE_OPTIONS, 'value')
const COUNTRY_OPTIONS = map(COUNTRIES, (country) => {
  const { value, fullName } = country
  return { key: fullName, value }
})
const COUNTRY_VALUES = map(COUNTRY_OPTIONS, 'value')
const MISSING_INVESTMENT_VALUE = 'missing'

function getInvestmentNameForRecordkeeper(recordkeeperName, currentUser) {
  const audience = currentUser?.accountAudience
  const accountName = currentUser?.accountName
  if (audience === 'TPA') {
    if (accountName === CompanyType.PENSION) {
      return InvestmentType.VALIC_8
    } else if (valicFiveInvestmentTypes.includes(accountName)) {
      return InvestmentType.VALIC_5
    } else if (lincolnInvestmentTypes.includes(accountName)) {
      return InvestmentType.AMERITAS
    } else {
      switch (recordkeeperName) {
        case CompanyType.NATIONWIDE:
          return InvestmentType.NATIONWIDE
        case CompanyType.TRANSAMERICA:
          return InvestmentType.TRANSAMERICA
        case CompanyType.AMERITAS:
          return InvestmentType.AMERITAS
        case CompanyType.COREBRIDGE:
          return InvestmentType.VALIC_6
        default:
          return 'Cash Sweep'
      }
    }
  } else if (audience === 'Recordkeeper') {
    switch (accountName) {
      case CompanyType.NATIONWIDE:
        return InvestmentType.NATIONWIDE
      case CompanyType.TRANSAMERICA:
        return InvestmentType.TRANSAMERICA
      case CompanyType.AMERITAS:
        return InvestmentType.AMERITAS
      case CompanyType.COREBRIDGE:
        return InvestmentType.VALIC_6
      default:
        return 'Cash Sweep'
    }
  } else {
    switch (recordkeeperName) {
      case CompanyType.NATIONWIDE:
        return InvestmentType.NATIONWIDE
      case CompanyType.TRANSAMERICA:
        return InvestmentType.TRANSAMERICA
      case CompanyType.AMERITAS:
        return InvestmentType.AMERITAS
      case CompanyType.COREBRIDGE:
        return InvestmentType.VALIC_6
      default:
        return 'Cash Sweep'
    }
  }
}

function getInvestmentDisplayName({
  value,
  recordkeeper,
  availableInvestment,
  currentUser,
}) {
  if (value === MISSING_INVESTMENT_VALUE)
    return getInvestmentNameForRecordkeeper(
      recordkeeper?.company.name,
      currentUser
    )

  return availableInvestment?.templateName ?? value
}

function shouldShowContactAddressFields(audienceDetails) {
  if (isEmpty(audienceDetails)) return true
  return !audienceDetails.contact.isSameAsCompanyAddress
}

const GroupLabelForSelect = ({ label }) => (
  <div className="select-group-label">
    <span className="visually-hidden">{label}</span>
  </div>
)

function CompletePlanAgreementForm({
  handleSubmit,
  planSponsors,
  recordkeepers,
  tpasAndConsultants,
  availableInitialInvestments,
  initialValues,
  allowRecordkeeperSelection,
  allowTpaConsultantSelection,
  isPlanFiduciary,
  currentUser,
}) {
  const hasExistingPlanSponsors = !!planSponsors.length
  const planSponsorSelectOptions = generateSelectOptions(
    planSponsors.map((planSponsor) => planSponsor.company),
    'name',
    'ID'
  )
  const recordkeeperSelectOptions = generateSelectOptions(
    recordkeepers.map((recordkeeper) => recordkeeper.company),
    'name',
    'ID'
  )
  const tpaConsultantSelectOptions = generateSelectOptions(
    tpasAndConsultants.map((tpaConsultant) => tpaConsultant.company),
    'name',
    'ID'
  )
  const companyAddressFieldSchema = {
    billingStreet: Yup.string()
      .max(255, 'Must be 255 characters or less')
      .required('Required'),
    billingCountry: Yup.string()
      .oneOf(COUNTRY_VALUES, 'Invalid country code')
      .required('Required'),
    billingState: Yup.string()
      .when('billingCountry', {
        is: US_COUNTRY_VALUE,
        then: (schema) =>
          schema
            .oneOf(US_STATE_VALUES, 'Must be a valid state')
            .required('Required'),
      })
      .when('billingCountry', {
        is: CANADA_COUNTRY_VALUE,
        then: (schema) => schema.required('Required'),
      }),
    billingPostalCode: Yup.string()
      .required('Required')
      .when('billingCountry', {
        is: US_COUNTRY_VALUE,
        then: (schema) =>
          schema.matches(
            /^[0-9]{5}(?:-[0-9]{4})?$/,
            'US Zip Codes must have format: XXXXX or XXXXX-XXXX'
          ),
      }),
    billingCity: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
  }
  const contactAddressFieldSchema = {
    isSameAsCompanyAddress: Yup.bool(),
    mailingStreet: Yup.string().when('isSameAsCompanyAddress', {
      is: false,
      then: (schema) =>
        schema.max(255, 'Must be 255 characters or less').required('Required'),
    }),
    mailingCountry: Yup.string().when('isSameAsCompanyAddress', {
      is: false,
      then: (schema) =>
        schema
          .oneOf(COUNTRY_VALUES, 'Invalid country code')
          .required('Required'),
    }),
    mailingState: Yup.string().when('isSameAsCompanyAddress', {
      is: false,
      then: (schema) =>
        schema
          .when('mailingCountry', {
            is: US_COUNTRY_VALUE,
            then: (schema) =>
              schema
                .oneOf(US_STATE_VALUES, 'Must be a valid state')
                .required('Required'),
          })
          .when('mailingCountry', {
            is: CANADA_COUNTRY_VALUE,
            then: (schema) => schema.required('Required'),
          }),
    }),
    mailingPostalCode: Yup.string().when('isSameAsCompanyAddress', {
      is: false,
      then: (schema) =>
        schema.required('Required').when('mailingCountry', {
          is: US_COUNTRY_VALUE,
          then: (schema) =>
            schema.matches(
              US_ZIP_CODE_REGEX,
              'US Zip Codes must have format: XXXXX or XXXXX-XXXX'
            ),
        }),
    }),
    mailingCity: Yup.string().when('isSameAsCompanyAddress', {
      is: false,
      then: (schema) =>
        schema.max(50, 'Must be 50 characters or less').required('Required'),
    }),
  }
  const companyFieldsSchema = {
    name: Yup.string()
      .max(60, 'Must be 60 characters or less')
      .required('Required'),
    type: Yup.string().required('Required'),
    phone: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('Required'),
    website: Yup.string()
      .url('Invalid URL. Format must be: https://www.example.com')
      .max(80, 'Must be 80 characters or less'),
    ...companyAddressFieldSchema,
  }
  const contactFieldsSchema = {
    firstName: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('Required'),
    lastName: Yup.string()
      .max(60, 'Must be 60 characters or less')
      .required('Required'),
    title: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('Required'),
    phone: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('Required'),
    email: Yup.string()
      .email('Must be valid email format: example@email.com')
      .max(50, 'Must be 50 characters or less')
      .required('Required'),
    ...contactAddressFieldSchema,
  }
  const planSponsorDetailsSchema = {
    company: Yup.object(companyFieldsSchema),
    contact: Yup.object(contactFieldsSchema),
  }
  const recordkeeperDetailsSchema = {
    company: Yup.object(companyFieldsSchema),
    contact: Yup.object(contactFieldsSchema),
  }
  const tpaConsultantDetailsSchema = {
    company: Yup.object(companyFieldsSchema),
    contact: Yup.object(contactFieldsSchema),
  }
  const validationSchema = Yup.object().shape({
    planDetails: Yup.object({
      name: Yup.string()
        .max(80, 'Must be 80 characters or less')
        .required('Required'),
      ein: Yup.string()
        .length(10, 'Must be 9 numeric digits in format XX-XXXXXXX')
        .matches(RegExp(EIN_REGEX), 'Invalid EIN')
        .required('Required'),
      type: Yup.string()
        .oneOf(planTypeValues, 'Must be a valid plan type')
        .required('Required'),
      status: Yup.string()
        .oneOf(planStatusValues, 'Must be a valid plan status')
        .required('Required'),
      planNumber: Yup.string()
        .matches(RegExp(/^\d+$/), 'Must contain only digits')
        .length(3, 'Must be 3 digits')
        .test(
          // Plan number is required only when an existing
          // plan sponsor is selected, otherwise it's optional
          'field is not required',
          'Required',
          (value, { from }) => {
            const planSponsorValue = from[1].value.planSponsor
            return (
              value ||
              !planSponsorValue ||
              planSponsorValue === ADD_PLAN_SPONSOR_OPTION.value
            )
          }
        ),
      providerPlanIdNumber: Yup.string()
        .max(30, 'Must be 30 characters or less')
        .matches(
          RegExp(PROVIDER_PLAN_ID_NUMBER_REGEX),
          'Invalid characters used'
        ),
      initialInvestment: Yup.string().test(
        'has-permissions',
        'Investment missing', // This message isn't surfaced to the user to allow for custom markup in the notification children (if needed)
        (value) => value !== MISSING_INVESTMENT_VALUE
      ),
    }),
    planSponsor: Yup.lazy(() => {
      if (isPlanFiduciary) return Yup.string().nullable()
      return Yup.string()
        .oneOf(
          concat(planSponsorSelectOptions, ADD_PLAN_SPONSOR_OPTION).map(
            (option) => option.value
          ),
          'Must be a valid option'
        )
        .required('Required')
    }),
    planSponsorDetails: Yup.lazy(() => {
      if (isPlanFiduciary) return Yup.object().nullable()
      return Yup.object(planSponsorDetailsSchema)
    }),
    recordkeeper: Yup.lazy(() => {
      if (!allowRecordkeeperSelection) return Yup.string().nullable()
      return Yup.string()
        .oneOf(
          concat(
            recordkeeperSelectOptions,
            ADD_RECORDKEEPER_OPTION,
            UNKNOWN_RECORDKEEPER_OPTION
          ).map((option) => option.value),
          'Must be a valid option'
        )
        .required('Required')
    }),
    recordkeeperDetails: Yup.object().when('recordkeeper', {
      is: ADD_RECORDKEEPER_OPTION.value,
      then: () => Yup.object(recordkeeperDetailsSchema),
    }),
    tpaConsultant: Yup.lazy(() => {
      if (isPlanFiduciary) return Yup.string().nullable()
      return Yup.string().oneOf(
        concat(
          tpaConsultantSelectOptions,
          ADD_TPA_OPTION,
          ADD_CONSULTANT_OPTION
        ).map((option) => option.value),
        'Must be a valid option'
      )
    }),
    tpaConsultantDetails: Yup.object().when('tpaConsultant', {
      is: (value) =>
        value === ADD_TPA_OPTION.value || value === ADD_CONSULTANT_OPTION.value,
      then: () => Yup.object(tpaConsultantDetailsSchema),
    }),
    confirm: Yup.lazy(() => {
      if (isPlanFiduciary)
        return Yup.boolean().required('Required').oneOf([true], 'Required')
      return Yup.boolean().nullable()
    }),
  })

  return (
    <div>
      <Formik
        initialValues={mergeAll([
          baseInitialValues,
          hasExistingPlanSponsors
            ? {}
            : { planSponsor: ADD_PLAN_SPONSOR_OPTION.value },
          initialValues,
        ])}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
          const resetField = (name) => {
            const baseInitialValue = get(baseInitialValues, name)
            setFieldValue(name, baseInitialValue || '', false)
            setFieldError(name, '')
            setFieldTouched(name, false, false)
          }
          const setField = (name, value) => {
            setFieldValue(name, value, false)
            setFieldError(name, '')
            setFieldTouched(name, false, false)
          }

          const {
            planSponsor,
            planSponsorDetails,
            recordkeeper,
            recordkeeperDetails,
            tpaConsultant,
            tpaConsultantDetails,
          } = values
          const availableInvestment = availableInitialInvestments.find(
            ({ templateId }) =>
              templateId === values.planDetails.initialInvestment
          )
          const contentDocumentId = availableInvestment?.contentDocumentId

          const investmentDisplayName = getInvestmentDisplayName({
            value: values.planDetails.initialInvestment,
            recordkeeper: values.recordkeeperDetails,
            availableInvestment,
          })

          // Show form to add new plan sponsor or edit existing plan sponsor details
          const showPlanSponsorForm =
            planSponsor !== '' || !hasExistingPlanSponsors

          // Show form to add new recordkeeper; for existing rk, display company info as description list:
          const showRecordkeeperForm =
            allowRecordkeeperSelection &&
            recordkeeper === ADD_RECORDKEEPER_OPTION.value

          const showRecordkeeperCompanyInfo =
            allowRecordkeeperSelection &&
            recordkeeper !== '' &&
            recordkeeper !== UNKNOWN_RECORDKEEPER_OPTION.value &&
            !showRecordkeeperForm

          // Show form to add new tpa/consultant; for existing tpa/consultant, display company info as description list:
          const showTpaConsultantForm =
            allowTpaConsultantSelection &&
            (tpaConsultant === ADD_TPA_OPTION.value ||
              tpaConsultant === ADD_CONSULTANT_OPTION.value)
          const showTpaConsultantCompanyInfo =
            allowTpaConsultantSelection &&
            tpaConsultant !== '' &&
            !showTpaConsultantForm

          // Show contact address fields for plan sponsor/recordkeeper if Same As Company Address checkbox is unchecked
          const showPlanSponsorContactAddressFields =
            shouldShowContactAddressFields(planSponsorDetails)
          const showRecordkeeperContactAddressFields =
            shouldShowContactAddressFields(recordkeeperDetails)
          const showTpaConsultantContactAddressFields =
            shouldShowContactAddressFields(tpaConsultantDetails)

          const hasSelectedExistingPlanSponsor =
            !!values.planSponsor &&
            values.planSponsor !== ADD_PLAN_SPONSOR_OPTION.value
          const downloadFactSheet = async () => {
            await downloadStableValueFactSheet(
              contentDocumentId,
              investmentDisplayName
            )
          }
          return (
            <ScrollErrorForm>
              {/* Services Agreement Details */}
              <div className="aro-form-section">
                <div className="form-header">
                  <h3>Services Agreement Details</h3>
                </div>
                <div className="form-container">
                  <div className="form-column">
                    <Input
                      name="planDetails[name]"
                      label="Complete Legal Plan Name"
                      placeholder="Enter complete legal plan name"
                      labelComponent={LabelWithTooltip}
                      tooltipContent={COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT}
                      tooltipAriaLabel="Complete legal plan name information"
                    />
                  </div>
                  <div className="form-column">
                    <MaskedInput
                      name="planDetails[ein]"
                      label="Employer Identification Number (EIN)"
                      placeholder="Enter employer identification number"
                      maskOptions={{
                        numericOnly: true,
                        blocks: [2, 7],
                        delimiter: '-',
                      }}
                      labelComponent={LabelWithTooltip}
                      tooltipContent={<PlanEinTooltipContent />}
                      tooltipAriaLabel="Plan EIN information"
                    />
                  </div>
                  <div className="form-column">
                    <Select
                      name="planDetails[type]"
                      label="Plan Type"
                      placeholder="Select plan type"
                      options={sortedPlanTypeOptions}
                    />
                  </div>
                  <div className="form-column">
                    <Select
                      name="planDetails[status]"
                      label="Plan Status"
                      placeholder="Select plan status"
                      options={sortedPlanStatusOptions}
                    />
                  </div>
                  <div className="form-column">
                    <MaskedInput
                      name="planDetails[planNumber]"
                      label="Three-digit Plan Number (PN)"
                      required={hasSelectedExistingPlanSponsor}
                      placeholder="Enter three-digit plan number"
                      maskOptions={{
                        numericOnly: true,
                        blocks: [3],
                      }}
                      labelComponent={LabelWithTooltip}
                      tooltipContent="Three-digit Number utilized to identify the plan by the IRS and DOL."
                      tooltipAriaLabel="Three-digit Number information"
                    />
                  </div>
                  <div className="form-column">
                    <Input
                      name="planDetails[providerPlanIdNumber]"
                      label="Provider Plan ID Number"
                      placeholder="Enter provider plan ID number"
                      required={false}
                      labelComponent={LabelWithTooltip}
                      tooltipContent="Plan number created by Recordkeepers to identify the plan. This number may also be called the Contract ID/Number on some forms."
                      tooltipAriaLabel="Provider Plan ID Number information"
                    />
                  </div>
                </div>
              </div>

              {/* Plan Sponsor Details */}
              {!isPlanFiduciary && (
                <div className="aro-form-section">
                  <div className="form-header">
                    <h3>Plan Sponsor Details</h3>
                  </div>
                  <div className="form-container">
                    {hasExistingPlanSponsors && (
                      <div className="form-column">
                        <SearchableSelect
                          name="planSponsor"
                          label="Plan Sponsor"
                          placeholder="Select plan sponsor"
                          options={[
                            ADD_PLAN_SPONSOR_OPTION,
                            {
                              label: 'Existing Plan Sponsors',
                              options: sortSelectOptions(
                                planSponsorSelectOptions,
                                'key'
                              ),
                            },
                          ]}
                          onChange={(e) => {
                            if (
                              e.target.value === ADD_PLAN_SPONSOR_OPTION.value
                            )
                              return resetField('planSponsorDetails')

                            const { company, contact } = planSponsors.find(
                              (planSponsor) =>
                                planSponsor.company.ID === e.target.value
                            )
                            setField(
                              'planSponsorDetails',
                              getEntityInitialValues({ company, contact })
                            )
                          }}
                          formatGroupLabel={(data) => (
                            <GroupLabelForSelect label={data.label} />
                          )}
                        />
                      </div>
                    )}
                    {showPlanSponsorForm && (
                      <CompanyDetailsFields detailsType="planSponsorDetails" />
                    )}
                  </div>
                  {/* Plan Sponsor Contact Details */}
                  {showPlanSponsorForm && (
                    <div className="aro-form-section">
                      <div className="form-header">
                        <h3>Plan Sponsor Contact Details</h3>
                      </div>
                      <div className="form-container">
                        <ContactDetailsFields
                          baseInitialValues={baseInitialValues}
                          detailsType="planSponsorDetails"
                        />
                        {showPlanSponsorContactAddressFields && (
                          <AddressFields
                            detailsType="planSponsorDetails"
                            addressType="contact"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Recordkeeper Details */}
              {allowRecordkeeperSelection && (
                <div className="aro-form-section">
                  <div className="form-header">
                    <h3 className="text-title--medium">Recordkeeper Details</h3>
                  </div>
                  <div className="audience-info">
                    <div className="form-column">
                      <SearchableSelect
                        name="recordkeeper"
                        label="Recordkeeper"
                        labelComponent={undefined}
                        placeholder="Select recordkeeper"
                        options={[
                          ADD_RECORDKEEPER_OPTION,
                          {
                            label: 'Unknown Recordkeeper',
                            options: [UNKNOWN_RECORDKEEPER_OPTION],
                          },
                          {
                            label: 'Existing Recordkeepers',
                            options: sortSelectOptions(
                              recordkeeperSelectOptions,
                              'key'
                            ),
                          },
                        ]}
                        onChange={(e) => {
                          const recordkeeper = recordkeepers.find(
                            (recordkeeper) =>
                              recordkeeper.company.ID === e.target.value
                          )
                          const investmentName =
                            getInvestmentNameForRecordkeeper(
                              recordkeeper?.company.name,
                              currentUser
                            )

                          const investment = availableInitialInvestments.find(
                            ({ templateName }) =>
                              templateName === investmentName
                          )

                          if (investment) {
                            setField(
                              'planDetails.initialInvestment',
                              investment.templateId
                            )
                          } else {
                            Sentry.withScope((scope) => {
                              scope.setExtra(
                                'Recordkeeper',
                                recordkeeper?.company.name || e.target.value
                              )
                              scope.setExtra(
                                'Available Investments',
                                availableInitialInvestments.map(
                                  ({ templateName }) => templateName
                                )
                              )
                              Sentry.captureException(
                                new Error(
                                  `No investment option found for: ${investmentName}`
                                )
                              )
                            })

                            if (
                              currentUser?.accountName === CompanyType.PENSION
                            ) {
                              setFieldValue(
                                'planDetails.initialInvestment',
                                InvestmentType.VALIC_8,
                                true
                              )
                            } else if (
                              valicFiveInvestmentTypes.includes(
                                currentUser?.accountName
                              )
                            ) {
                              setFieldValue(
                                'planDetails.initialInvestment',
                                InvestmentType.VALIC_5,
                                true
                              )
                            } else if (
                              lincolnInvestmentTypes.includes(
                                currentUser?.accountName
                              )
                            ) {
                              setFieldValue(
                                'planDetails.initialInvestment',
                                InvestmentType.AMERITAS,
                                true
                              )
                            } else {
                              setFieldValue(
                                'planDetails.initialInvestment',
                                MISSING_INVESTMENT_VALUE,
                                false
                              )
                            }
                          }

                          if (recordkeeper) {
                            setField(
                              'recordkeeperDetails',
                              getEntityInitialValues({
                                company: recordkeeper.company,
                                contact: recordkeeper.contact,
                              })
                            )
                          } else {
                            resetField('recordkeeperDetails')
                          }
                        }}
                        formatGroupLabel={(data) => (
                          <GroupLabelForSelect label={data.label} />
                        )}
                      />
                    </div>
                    {investmentDisplayName && (
                      <div className="form-column">
                        <dl>
                          <dt>Initial Investment</dt>
                          <div className="investment-name">
                            <dd>{investmentDisplayName}</dd>
                            {!isEmpty(contentDocumentId) && (
                              <button
                                type="button"
                                className="button-text"
                                onClick={downloadFactSheet}
                              >
                                View stable value fact sheet.
                              </button>
                            )}
                          </div>
                        </dl>
                      </div>
                    )}
                  </div>
                  {showRecordkeeperCompanyInfo && (
                    <CompanyDetails
                      company={values.recordkeeperDetails.company}
                    />
                  )}
                  {showRecordkeeperForm && (
                    <>
                      <div className="aro-form-section">
                        <div className="form-container">
                          <CompanyDetailsFields detailsType="recordkeeperDetails" />
                        </div>
                      </div>
                      <div className="aro-form-section">
                        <div className="form-header">
                          <h3 className="text-title--medium">
                            Recordkeeper Contact Details
                          </h3>
                        </div>
                        <div className="form-container">
                          <ContactDetailsFields
                            baseInitialValues={baseInitialValues}
                            detailsType="recordkeeperDetails"
                          />
                          {showRecordkeeperContactAddressFields && (
                            <AddressFields
                              detailsType="recordkeeperDetails"
                              addressType="contact"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {/* TPA/Consultant Details */}
              {allowTpaConsultantSelection && (
                <div className="aro-form-section">
                  <div className="form-header">
                    <h3 className="text-title--medium">
                      Third Party Administrator/Consultant Details
                    </h3>
                  </div>
                  <div className="audience-info">
                    <div className="form-column">
                      <SearchableSelect
                        name="tpaConsultant"
                        label="Third Party Administrator/Consultant"
                        labelComponent={OptionalLabel}
                        placeholder="Select tpa/consultant"
                        options={[
                          ADD_TPA_OPTION,
                          ADD_CONSULTANT_OPTION,
                          {
                            label: 'Existing TPAs/Consultants',
                            options: sortSelectOptions(
                              tpaConsultantSelectOptions,
                              'key'
                            ),
                          },
                        ]}
                        isClearable={true}
                        onChange={(e) => {
                          const value = e.target.value
                          const addNewTpa = value === ADD_TPA_OPTION.value
                          const addNewConsultant =
                            value === ADD_CONSULTANT_OPTION.value

                          if (!value) return resetField('tpaConsultantDetails')

                          if (addNewTpa || addNewConsultant) {
                            const audienceType = addNewTpa
                              ? AudienceType.TPA
                              : AudienceType.CONSULTANT
                            resetField('tpaConsultantDetails')
                            return setField(
                              'tpaConsultantDetails.company.type',
                              audienceType
                            )
                          }

                          const tpaConsultant = tpasAndConsultants.find(
                            (tpaAndConsultant) =>
                              tpaAndConsultant.company.ID === e.target.value
                          )
                          if (tpaConsultant) {
                            setField(
                              'tpaConsultantDetails',
                              getEntityInitialValues({
                                company: tpaConsultant.company,
                                contact: tpaConsultant.contact,
                              })
                            )
                          } else {
                            resetField('tpaConsultantDetails')
                          }
                        }}
                        formatGroupLabel={(data) => (
                          <GroupLabelForSelect label={data.label} />
                        )}
                      />
                    </div>
                  </div>
                  {showTpaConsultantCompanyInfo && (
                    <CompanyDetails company={tpaConsultantDetails.company} />
                  )}
                  {showTpaConsultantForm && (
                    <>
                      <div className="aro-form-section">
                        <div className="form-container">
                          <CompanyDetailsFields detailsType="tpaConsultantDetails" />
                        </div>
                      </div>
                      <div className="aro-form-section">
                        <div className="form-header">
                          <h3 className="text-title--medium">
                            Third Party Administrator/Consultant Contact Details
                          </h3>
                        </div>
                        <div className="form-container">
                          <ContactDetailsFields
                            baseInitialValues={baseInitialValues}
                            detailsType="tpaConsultantDetails"
                          />
                          {showTpaConsultantContactAddressFields && (
                            <AddressFields
                              detailsType="tpaConsultantDetails"
                              addressType="contact"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {/* E-signature for Plan Sponsors */}
              {isPlanFiduciary && (
                <>
                  <div className="aro-form-section">
                    <div className="form-header">
                      <h3 className="text-title--medium">E-Signature</h3>
                    </div>
                    <div className="signature-details-container">
                      <dl>
                        <div>
                          <dt>First Name</dt>
                          <dd>{currentUser.firstName}</dd>
                        </div>
                        <div>
                          <dt>Last Name</dt>
                          <dd>{currentUser.lastName}</dd>
                        </div>
                        <div>
                          <dt>Title</dt>
                          <dd>{currentUser.title}</dd>
                        </div>
                      </dl>
                    </div>
                    <ConfirmationCheckbox
                      name="confirm"
                      label={E_SIGNATURE_CONFIRMATION_TEXT}
                    />
                  </div>
                </>
              )}

              <div role="alert">
                <ErrorMessage name="planDetails.initialInvestment">
                  {() => (
                    <Notification
                      className="notification--tight"
                      type={NotificationVariant.ERROR}
                    >
                      It looks like you don’t have permission to create a
                      Services Agreement with this Recordkeeper. Please contact
                      your relationship manager to update your permissions in
                      our system.
                    </Notification>
                  )}
                </ErrorMessage>
              </div>
              <FlowActions submitContent="Submit" />
            </ScrollErrorForm>
          )
        }}
      </Formik>
    </div>
  )
}

CompletePlanAgreementForm.propTypes = propTypes
CompletePlanAgreementForm.defaultProps = defaultProps

export default CompletePlanAgreementForm
