import React from 'react'
import { ScrollErrorForm, Input, MaskedInput } from 'components'
import { Formik } from 'formik'
import { FlowActions } from '../components'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}
const defaultProps = {}

const validationSchema = Yup.object({
  firstName: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(60, 'Must be 60 characters or less')
    .required('Required'),
  title: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .required('Required'),
  phone: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .required('Required'),
  email: Yup.string()
    .email('Must be valid email format: example@email.com')
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
})

function ServiceAgreementDetailsForm({ onSubmit, initialValues }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <ScrollErrorForm>
            <div className="form-section">
              <div className="form-header">
                <h3 className="is-marginless">Search Contact Information</h3>
                <p>
                  If there is someone at your company that will be primarily
                  submitting Search Orders besides you, please update this
                  information to reflect that.
                </p>
              </div>
            </div>
            <div className="contact-details-container">
              <div className="contact-details">
                <Input
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                />
                <Input name="title" label="Title" placeholder="Enter Title" />
                <MaskedInput
                  name="phone"
                  label="Company Phone"
                  placeholder="Enter Contact Phone"
                  maskOptions={{
                    numericOnly: true,
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ['(', ')', ' ', '-'],
                  }}
                />
                <Input
                  name="email"
                  label="Contact Email"
                  placeholder="Enter Contact Email"
                />
              </div>
            </div>
            <FlowActions preventBack submitContent="Next" />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

ServiceAgreementDetailsForm.propTypes = propTypes
ServiceAgreementDetailsForm.defaultProps = defaultProps

export default ServiceAgreementDetailsForm
