import { join, escapeRegExp, find, mapKeys, cloneDeep, isEmpty } from 'lodash'
import {
  COMPANY_NAME,
  SEARCH_ORDER_PROHIBITED_COMPANIES,
  ReasonForDiscard,
} from 'config'

// Feature flag for manual Flatfile entry
export function isManualInputEnabled() {
  return process.env.ENABLE_MANUAL_INPUT
}

export const RecordTypes = {
  SEARCH: 'search',
  ARO_PARTICIPANT: 'automaticRolloversParticipant',
}

export const RecordNames = {
  [RecordTypes.SEARCH]: 'Search',
  [RecordTypes.ARO_PARTICIPANT]: 'Participant',
}

export const IRA_AMOUNT = {
  TRADITIONAL_PRE_TAX: 'traditionalIraPreTaxAmount',
  TRADITIONAL_AFTER_TAX: 'traditionalIraAfterTaxAmount',
  ROTH: 'rothIraAmount',
}

const Labels = {
  RECORDKEEPER: 'Recordkeeper',
  THIRD_PARTY_ADMINISTRATOR: 'Third Party Administrator',
  PLAN_SPONSOR: 'Plan Sponsor',
  COMPLETE_LEGAL_PLAN_NAME: 'Complete Legal Plan Name',
  EMPLOYER_IDENTIFICATION_NUMBER: 'Employer Identification Number (EIN)',
  PLAN_EMPLOYER_IDENTIFICATION_NUMBER:
    'Plan Employer Identification Number (EIN)',
  THREE_DIGIT_PLAN_NUMBER: 'Three-digit Plan Number (PN)',
  PROVIDER_PLAN_ID_NUMBER: 'Provider Plan ID Number',
  FIRST_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'Zip Code',
  COUNTRY_CODE: 'Country Code',
  DATE_OF_BIRTH: 'Date of Birth',
  SSN: 'SSN',
  EMAIL_ADDRESS: 'Email Address',
  TRADITIONAL_IRA_PRE_TAX_AMOUNT: 'Traditional IRA Pre-Tax Amount',
  TRADITIONAL_IRA_AFTER_TAX_AMOUNT: 'Traditional IRA After-Tax Amount',
  ROTH_IRA_AMOUNT: 'Roth IRA Amount',
  PLAN_FIDUCIARY_NAME: 'Plan Fiduciary (Entity Name)',
  PARTICIPANT_SSN: 'Participant SSN',
  PARTICIPANT_FIRST_NAME: 'Participant First Name',
  PARTICIPANT_MIDDLE_NAME: 'Participant Middle Name',
  PARTICIPANT_LAST_NAME: 'Participant Last Name',
  PARTICIPANT_ADDRESS_1: 'Participant Address 1',
  PARTICIPANT_ADDRESS_2: 'Participant Address 2',
  PARTICIPANT_ADDRESS_3: 'Participant Address 3',
  PARTICIPANT_CITY: 'Participant City',
  PARTICIPANT_STATE: 'Participant State',
  PARTICIPANT_ZIP: 'Participant Zip',
  PARTICIPANT_DOB: 'Participant DOB',
  PARTICIPANT_DOD: 'Participant DOD',
  PHONE_NUMBER: 'Phone Number',
  WORK_FOR: 'Work For',
  REASON: 'Reason',
}

const getColumnSize = (name) => {
  if (name.length <= 18) return 1
  return name.length / 18
}

const createMaxCharValidator = (limit) => ({
  validate: 'regex_excludes',
  regex: `^.{${limit + 1},}$`,
  error: `Maximum length exceeded - must be ${limit} characters or less`,
})

const createNamePrefixValidator = () => ({
  validate: 'regex_excludes',
  regex: `^(${namePrefixAbbreviationRegex}|${namePrefixRegex})`,
  regexFlags: { ignoreCase: true },
  error: 'Do not include name prefixes',
})

const createNotApplicableValidator = () => ({
  validate: 'regex_excludes',
  regex: notApplicableRegex,
  regexFlags: { ignoreCase: true },
  error: 'Required',
})

const createSearchOrderProhibitedCompaniesValidator = () => ({
  validate: 'regex_excludes',
  regex: searchOrderProhibitedCompaniesRegex,
  regexFlags: { ignoreCase: true },
  error: ReasonForDiscard.PROHIBITED_COMPANY,
})

const ADDITIONAL_INVALID_SSN = ['123-45-6789', '111-11-1111', '333-33-3333']
const NAME_PREFIX_ABBREVIATIONS = ['Mr', 'Ms', 'Mrs']
const NAME_PREFIXES = ['Mister', 'Miss', 'Missus']
const NOT_APPLICABLE_VARIATIONS = ['Not Applicable', 'N/A', 'NA', 'N\\.A\\.']

// names cannot begin with a prefix (or be a prefix) but names like Mrittika, Msrah should pass the validation
const namePrefixAbbreviationRegex = NAME_PREFIX_ABBREVIATIONS.map(
  (prefix) => `${prefix}([ .,]|$)`
).join('|')
const namePrefixRegex = NAME_PREFIXES.map((prefix) => `${prefix}([ ]|$)`).join(
  '|'
)

const notApplicableRegex = NOT_APPLICABLE_VARIATIONS.map(
  (notApplicable) => `(^${notApplicable}$)`
).join('|')

const additionalInvalidSsnRegex = ADDITIONAL_INVALID_SSN.map(
  (invalidSsn) => `(^${invalidSsn}$)`
).join('|')

export const searchOrderProhibitedCompaniesRegex =
  SEARCH_ORDER_PROHIBITED_COMPANIES.map(
    (company) => `(^${escapeRegExp(company)}$)`
  ).join('|')

const AcceptableFileExtensions = {
  [RecordTypes.SEARCH]: ['.csv'],
  [RecordTypes.ARO_PARTICIPANT]: ['.csv', '.xls', '.xlsx'],
}
const UploadInstructions = {
  [RecordTypes.SEARCH]:
    'Add records for Search by either uploading a spreadsheet or manually filling in the table below. If uploading a spreadsheet, please note that only .csv files are accepted. After uploading your file, you will be prompted to match your data fields to our standard column headings. Then you will be able to review the data and make any necessary adjustments before submitting. The minimum required participant fields for all searches are Plan Fiduciary (Entity Name), Complete Legal Plan Name, First Name, Last Name, SSN or Address (Address 1, City, State, & Zip fields).',
  [RecordTypes.ARO_PARTICIPANT]: `Upload your Participant File and follow the steps in this flow to submit data to ${COMPANY_NAME['long']}. After uploading your file, you will be prompted to match your data fields to our standard column headings then you will be able to review the data and make any necessary adjustments before submitting. Please note that only .csv, .xls, and .xlsx files are accepted and the file cannot contain any merged cells.`,
}
const FlatfileFields = {
  [RecordTypes.SEARCH]: [
    {
      label: Labels.PLAN_FIDUCIARY_NAME,
      key: 'planSponsorName',
      validators: [
        { validate: 'required' },
        createSearchOrderProhibitedCompaniesValidator(),
      ],
      sizeHint: getColumnSize(Labels.PLAN_FIDUCIARY_NAME),
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.COMPLETE_LEGAL_PLAN_NAME),
    },
    {
      label: Labels.PLAN_EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^[0-9]{2}-[0-9]{7}$',
          error: 'Format must be: XX-XXXXXXX',
        },
        {
          validate: 'regex_matches',
          // valid EIN prefixes: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
          regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
          error: 'Invalid EIN',
        },
      ],
      sizeHint: getColumnSize(Labels.PLAN_EMPLOYER_IDENTIFICATION_NUMBER),
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',

      validators: [
        {
          validate: 'regex_excludes',
          regex: '^(\\D)',
          error: 'Invalid characters used',
        },
        {
          validate: 'regex_matches',
          regex: '^(\\d{3})$',
          error: 'Must be three digits',
        },
      ],
      sizeHint: getColumnSize(Labels.THREE_DIGIT_PLAN_NUMBER),
    },
    {
      label: Labels.PARTICIPANT_SSN,
      key: 'ssn',
      validators: [
        {
          validate: 'required_with_values',
          fieldValues: {
            address1: ['', 'N/A'],
            city: ['', 'N/A'],
            state: ['', 'N/A'],
            zip: ['', 'N/A'],
          },
          error: 'If no Address, required',
        },
        {
          validate: 'regex_excludes',
          regex: additionalInvalidSsnRegex,
          error: 'SSN is not valid',
        },
        {
          validate: 'regex_matches',
          regex: '^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$',
          error: 'Format must be XXX-XX-XXXX',
        },
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_SSN),
    },
    {
      label: Labels.PARTICIPANT_FIRST_NAME,
      key: 'firstName',
      validators: [
        {
          validate: 'required',
          error: 'Required',
        },
        createNamePrefixValidator(),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_FIRST_NAME),
    },
    {
      label: Labels.PARTICIPANT_MIDDLE_NAME,
      key: 'middleName',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_MIDDLE_NAME),
    },
    {
      label: Labels.PARTICIPANT_LAST_NAME,
      key: 'lastName',
      validators: [
        {
          validate: 'required',
          error: 'Required',
        },
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_LAST_NAME),
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_1,
      key: 'address1',
      validators: [
        {
          validate: 'required_with_values',
          fieldValues: { ssn: ['', 'N/A'] },
          error: 'If no SSN, required',
        },
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ADDRESS_1),
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_2,
      key: 'address2',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ADDRESS_2),
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_3,
      key: 'address3',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ADDRESS_3),
    },
    {
      label: Labels.PARTICIPANT_CITY,
      key: 'city',
      validators: [
        {
          validate: 'required_with_values',
          fieldValues: { ssn: ['', 'N/A'] },
          error: 'If no SSN, required',
        },
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_CITY),
    },
    {
      label: Labels.PARTICIPANT_STATE,
      key: 'state',
      validators: [
        {
          validate: 'required_with_values',
          fieldValues: { ssn: ['', 'N/A'] },
          error: 'If no SSN, required',
        },
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_STATE),
    },
    {
      label: Labels.PARTICIPANT_ZIP,
      key: 'zip',
      validators: [
        {
          validate: 'required_with_values',
          fieldValues: { ssn: ['', 'N/A'] },
          error: 'If no SSN, required',
        },
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ZIP),
    },
    {
      label: Labels.PARTICIPANT_DOB,
      key: 'dob',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_DOB),
    },
    {
      label: Labels.PARTICIPANT_DOD,
      key: 'dod',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_DOD),
    },
    {
      label: Labels.PHONE_NUMBER,
      key: 'phone',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
          error:
            'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
        },
      ],
      sizeHint: getColumnSize(Labels.PHONE_NUMBER),
    },
    {
      label: Labels.WORK_FOR,
      key: 'workFor',
      validators: [],
      sizeHint: getColumnSize(Labels.WORK_FOR),
    },
    {
      label: Labels.REASON,
      key: 'reason',
      validators: [],
      sizeHint: getColumnSize(Labels.REASON),
    },
  ],
  [RecordTypes.ARO_PARTICIPANT]: [
    {
      label: Labels.RECORDKEEPER,
      key: 'recordkeeper',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required. Enter your company name if self-administering.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Recordkeeper. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.RECORDKEEPER),
    },
    {
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      key: 'tpa',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error:
            'Please enter a valid Third Party Administrator. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.THIRD_PARTY_ADMINISTRATOR),
    },
    {
      label: Labels.PLAN_SPONSOR,
      key: 'planSponsor',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
        },
        createMaxCharValidator(60),
      ],
      sizeHint: getColumnSize(Labels.PLAN_SPONSOR),
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Name. No slashes allowed.',
        },
        createMaxCharValidator(80),
      ],
      sizeHint: getColumnSize(Labels.COMPLETE_LEGAL_PLAN_NAME),
    },
    {
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^[0-9]{2}-[0-9]{7}$',
          error: 'Format must be: XX-XXXXXXX',
        },
        {
          validate: 'regex_matches',
          // valid EIN prefixes: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
          regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
          error: 'Invalid EIN',
        },
      ],
      sizeHint: getColumnSize(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',
      validators: [
        {
          validate: 'regex_excludes',
          regex: '^(\\D)',
          error: 'Invalid characters used',
        },
        {
          validate: 'regex_matches',
          regex: '^(\\d{3})$',
          error: 'Must be three digits',
        },
      ],
      sizeHint: getColumnSize(Labels.THREE_DIGIT_PLAN_NUMBER),
    },
    {
      label: Labels.PROVIDER_PLAN_ID_NUMBER,
      key: 'planIdNumber',
      validators: [createMaxCharValidator(30)],
      sizeHint: getColumnSize(Labels.PROVIDER_PLAN_ID_NUMBER),
    },
    {
      label: Labels.FIRST_NAME,
      key: 'firstName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(40),
        createNamePrefixValidator(),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.FIRST_NAME),
    },
    {
      label: Labels.MIDDLE_NAME,
      key: 'middleName',
      validators: [createMaxCharValidator(20)],
      sizeHint: getColumnSize(Labels.MIDDLE_NAME),
    },
    {
      label: Labels.LAST_NAME,
      key: 'lastName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(60),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.LAST_NAME),
    },
    {
      label: Labels.ADDRESS_LINE_1,
      key: 'addressLine1',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(36),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_1),
    },
    {
      label: Labels.ADDRESS_LINE_2,
      key: 'addressLine2',
      validators: [createMaxCharValidator(36)],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_2),
    },
    {
      label: Labels.CITY,
      key: 'city',
      validators: [createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.CITY),
    },
    {
      label: Labels.STATE,
      key: 'state',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.STATE),
    },
    {
      label: Labels.ZIP_CODE,
      key: 'zipCode',
      validators: [],
      sizeHint: getColumnSize(Labels.ZIP_CODE),
    },
    {
      label: Labels.COUNTRY_CODE,
      key: 'countryCode',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.COUNTRY_CODE),
    },
    {
      label: Labels.DATE_OF_BIRTH,
      key: 'dob',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.DATE_OF_BIRTH),
    },
    {
      label: Labels.SSN,
      key: 'ssn',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: additionalInvalidSsnRegex,
          error: 'Given SSN is not valid',
        },
      ],
      sizeHint: getColumnSize(Labels.SSN),
    },
    {
      label: Labels.EMAIL_ADDRESS,
      key: 'emailAddress',
      validators: [createMaxCharValidator(50)],
      sizeHint: getColumnSize(Labels.EMAIL_ADDRESS),
    },
    {
      label: Labels.TRADITIONAL_IRA_PRE_TAX_AMOUNT,
      key: IRA_AMOUNT.TRADITIONAL_PRE_TAX,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [IRA_AMOUNT.TRADITIONAL_AFTER_TAX, IRA_AMOUNT.ROTH],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.TRADITIONAL_IRA_PRE_TAX_AMOUNT),
    },
    {
      label: Labels.TRADITIONAL_IRA_AFTER_TAX_AMOUNT,
      key: IRA_AMOUNT.TRADITIONAL_AFTER_TAX,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [IRA_AMOUNT.TRADITIONAL_PRE_TAX, IRA_AMOUNT.ROTH],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.TRADITIONAL_IRA_AFTER_TAX_AMOUNT),
    },
    {
      label: Labels.ROTH_IRA_AMOUNT,
      key: IRA_AMOUNT.ROTH,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [
            IRA_AMOUNT.TRADITIONAL_PRE_TAX,
            IRA_AMOUNT.TRADITIONAL_AFTER_TAX,
          ],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.ROTH_IRA_AMOUNT),
    },
  ],
}

const ExcelFilesAllowed = {
  [RecordTypes.SEARCH]: false,
  [RecordTypes.ARO_PARTICIPANT]: true,
}

const ManualInputDisabled = {
  [RecordTypes.SEARCH]: false,
  [RecordTypes.ARO_PARTICIPANT]: !isManualInputEnabled(),
}

// same color as $primary-base defined in inspriaColors.scss
const defaultThemeColor = '#4553A9'

const flatFileUploadModalTitle = {
  [RecordTypes.SEARCH]: `Update ${
    RecordNames[RecordTypes.SEARCH]
  } Participants`,
  [RecordTypes.ARO_PARTICIPANT]: `Upload ${
    RecordNames[RecordTypes.ARO_PARTICIPANT]
  } Records`,
}

// getFlatfileConfig takes in an object called validators. It's used for fields with
// conditional validations that's dependent on state or values outside of flatfile
export const getFlatfileConfig = (recordType, validators = {}) => {
  const fields = isEmpty(validators)
    ? FlatfileFields[recordType]
    : cloneDeep(FlatfileFields[recordType])

  mapKeys(validators, (value, key) => {
    const field = find(fields, ['key', key])
    field.validators = value
  })

  return {
    type: `${RecordNames[recordType]} Record`,
    title: flatFileUploadModalTitle[recordType],
    i18nOverrides: {
      en: {
        otherLocales: ['en-US', 'en-CA', 'en-GB'],
        overrides: {
          manual: '...manually enter data here',
          dropzone: {
            accepted: `Acceptable file types: ${join(
              AcceptableFileExtensions[recordType],
              ', '
            )}`,
            button: 'Upload data from file',
          },
          fileTypes: UploadInstructions[recordType],
          header2: 'Please review your data and correct any issues identified',
        },
      },
    },
    theme: {
      global: {
        successColor: defaultThemeColor,
      },
      progressBar: {
        complete: {
          color: defaultThemeColor,
        },
      },
      buttons: {
        primary: {
          backgroundColor: defaultThemeColor,
          border: `1px solid ${defaultThemeColor}`,
        },
        success: {
          backgroundColor: defaultThemeColor,
          border: `1px solid ${defaultThemeColor}`,
        },
        headerMatchYes: {
          color: 'white',
          backgroundColor: defaultThemeColor,
          border: `1px solid ${defaultThemeColor}`,
        },
        headerMatchNo: {
          color: defaultThemeColor,
          backgroundColor: 'transparent',
          border: `1px solid ${defaultThemeColor}`,
        },
      },
      headerMatch: {
        content: {
          color: defaultThemeColor,
        },
      },
      columnMatch: {
        content: {
          color: defaultThemeColor,
        },
      },
    },
    fields,
    managed: ExcelFilesAllowed[recordType],
    disableManualInput: ManualInputDisabled[recordType],
  }
}
