import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { ScrollErrorForm, RadioGroup } from 'components'
import { FlowActions, DuplicateServicesAgreementModal } from '../components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { find } from 'lodash'
import { formatEin } from 'utils'
import { MANUAL_ERISAPEDIA_OPTION } from 'config'

const propTypes = {
  initialValues: PropTypes.shape({
    option: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(Types.erisapediaResult).isRequired,
  onSubmit: PropTypes.func.isRequired,
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary),
}

const defaultProps = {
  servicesAgreements: null,
}

const getServicesAgreementsWithSameEin = (servicesAgreements, ein) => {
  const formattedEin = formatEin(ein)
  return servicesAgreements.filter(({ planEIN }) => {
    if (!planEIN) return false
    return formatEin(planEIN) === formattedEin
  })
}

function HiddenLegend() {
  return <legend className="visually-hidden"></legend>
}

// Option label for a result from erisapedia searches
function CompanyOptionLabel({ htmlFor, option }) {
  return (
    <label htmlFor={htmlFor}>
      <div className="option-name-container">
        <p className="option-name">{option.key}</p>
      </div>
      <div className="option-info-container">
        <dl>
          <div className="complete-legal-plan-name">
            <dt>Complete Legal Plan Name</dt>
            <dd className="description">{option.planName ?? ''}</dd>
          </div>
          <div className="employer-identification-number">
            <dt>Employer Identification Number (EIN)</dt>
            <dd>{option.planEIN ?? ''}</dd>
          </div>
          <div className="plan-sponsor-full-name">
            <dt>Plan Sponsor Full Name</dt>
            <dd>{option.planSponsorCompany.name ?? ''}</dd>
          </div>
          {option.recordkeeper && (
            <div className="recordkeeper">
              <dt>Recordkeeper</dt>
              <dd>{option.recordkeeper}</dd>
            </div>
          )}
        </dl>
      </div>
    </label>
  )
}

// Option label for manual entry
function ManualEntryLabel({
  htmlFor,
  option,
  showAdditionalInformation = true, // only show if options only has one option (manual)
}) {
  return (
    <label htmlFor={htmlFor}>
      <div className="option-name-container">
        <p className="option-name">{option.key}</p>
        <p className="option-description">
          {showAdditionalInformation
            ? 'I would like to use this information to pre-fill the form in Step 3.'
            : 'I would like to enter the agreement details on my own in Step 3.'}
        </p>
      </div>
      {showAdditionalInformation && (
        <div className="option-info-container">
          <dl>
            <div className="complete-legal-plan-name">
              <dt>Complete Legal Plan Name</dt>
              <dd>{option.planName}</dd>
            </div>
            <div className="employer-identification-number">
              <dt>Employer Identification Number (EIN)</dt>
              <dd>{option.planEIN}</dd>
            </div>
          </dl>
        </div>
      )}
    </label>
  )
}

const getLabelComponent = ({ htmlFor, option, showAdditionalInformation }) => {
  const isManualOptionValue = option.value === MANUAL_ERISAPEDIA_OPTION
  if (isManualOptionValue)
    return (
      <ManualEntryLabel
        htmlFor={htmlFor}
        option={option}
        showAdditionalInformation={showAdditionalInformation}
      />
    )
  return <CompanyOptionLabel htmlFor={htmlFor} option={option} />
}

function SelectCompanyInformationForm({
  initialValues,
  options,
  onSubmit,
  servicesAgreements,
}) {
  const [
    showDuplicateServicesAgreementModal,
    setShowDuplicateServicesAgreementModal,
  ] = useState(false)
  const [sameEinServicesAgreements, setSameEinServicesAgreements] =
    useState(null)

  const validationSchema = Yup.object().shape({
    option: Yup.string()
      .oneOf(options.map((option) => option.value))
      .required('Required'),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        const selectedOption = find(
          options,
          ({ value }) => value === formValues.option
        )
        const serviceAgreementsWithSameEin = getServicesAgreementsWithSameEin(
          servicesAgreements,
          selectedOption.planEIN
        )
        if (serviceAgreementsWithSameEin.length) {
          setSubmitting(false)
          setSameEinServicesAgreements(serviceAgreementsWithSameEin)
          setShowDuplicateServicesAgreementModal(true)
          return
        }
        return onSubmit(formValues)
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <ScrollErrorForm>
            <div className="form-container">
              <div className="form-column full-row">
                <RadioGroup
                  className="card-radio-group company-option-field"
                  name="option"
                  options={options}
                  radioInputProps={{
                    labelComponent: ({ htmlFor, option }) => {
                      const hasManualOptionOnly = options.length === 1
                      return getLabelComponent({
                        htmlFor,
                        option,
                        showAdditionalInformation: hasManualOptionOnly,
                      })
                    },
                  }}
                  labelComponent={HiddenLegend}
                />
              </div>
            </div>
            <FlowActions />
            {showDuplicateServicesAgreementModal && (
              <DuplicateServicesAgreementModal
                onClose={() => setShowDuplicateServicesAgreementModal(false)}
                handleBack={() => setShowDuplicateServicesAgreementModal(false)}
                isSubmitting={isSubmitting}
                handleSubmit={() => {
                  onSubmit(values)
                }}
                submitContent="Continue"
                agreements={sameEinServicesAgreements}
              />
            )}
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

SelectCompanyInformationForm.propTypes = propTypes
SelectCompanyInformationForm.defaultProps = defaultProps

export default React.memo(SelectCompanyInformationForm)
