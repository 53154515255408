import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel } from 'lp-components'

const propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
}

const defaultProps = {
  id: '',
  label: '',
}

function HiddenLabel(props) {
  return <InputLabel {...props} className="visually-hidden" />
}

HiddenLabel.propTypes = propTypes
HiddenLabel.defaultProps = defaultProps

export default React.memo(HiddenLabel)
