import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useClickAway } from 'react-use'
import { CheckboxGroup, ScrollErrorForm } from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { SubmitButton } from 'lp-components'
import { RolloverAccountStatus } from 'config'

// Convert enums from config to list as options for form inputs
const participantStatusOptions = Object.values(RolloverAccountStatus)
const invalidErrorMessage = `Invalid value(s). Must be one of the following: ${participantStatusOptions.join(
  ', '
)}`

const propTypes = {
  filters: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

const defaultProps = {}

const FakeLegend = ({ label }) => <label>{label}</label>

function ParticipantsFilterDialog({
  filters,
  handleSubmit,
  clearAllFilters,
  handleClose,
}) {
  const ref = useRef(null)

  useClickAway(ref, (e) => {
    const targetEl = e.target
    if (!document.body.contains(targetEl)) return
    handleClose()
  })

  const filterSchema = Yup.object().shape({
    status: Yup.array().test(
      'Valid agreement status',
      invalidErrorMessage,
      (val) => {
        return val.every((el) => participantStatusOptions.includes(el))
      }
    ),
  })

  return (
    <div className="filter-formik-container" ref={ref}>
      <Formik
        initialValues={filters}
        validationSchema={filterSchema}
        onSubmit={(values) => {
          handleSubmit(values)
          handleClose()
        }}
      >
        {({ isSubmitting }) => (
          <ScrollErrorForm>
            <div className="participants-filter filter-dialog-container">
              <div className="filter-row">
                <CheckboxGroup
                  name="status"
                  label="Status"
                  labelComponent={FakeLegend}
                  options={participantStatusOptions}
                />
              </div>
              <div className="filter-row filter-actions-container">
                <div className="filter-actions">
                  <button
                    type="button"
                    id="filter-btn-clear"
                    className="button-text"
                    onClick={() => {
                      clearAllFilters()
                      handleClose()
                    }}
                  >
                    Clear all filters
                  </button>
                </div>
                <div className="filter-actions">
                  <button
                    type="button"
                    id="filter-btn-cancel"
                    className="button-text"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <SubmitButton submitting={isSubmitting}>
                    Apply Filters
                  </SubmitButton>
                </div>
              </div>
            </div>
          </ScrollErrorForm>
        )}
      </Formik>
    </div>
  )
}

ParticipantsFilterDialog.propTypes = propTypes
ParticipantsFilterDialog.defaultProps = defaultProps

export default React.memo(ParticipantsFilterDialog)
