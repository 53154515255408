import { throttle, noop } from 'lodash'
import { getTimerStartDate, setTimerStartDate } from 'local-storage'

const TICK_INTERVAL = 1000
const DEFAULT_TIMEOUT = 900000 // 15 minutes

function setIdleTimer({
  onIdle = noop,
  timeout = DEFAULT_TIMEOUT,
  resume = false,
}) {
  function startTimer(persistTime = false) {
    persistTime ? tick() : setTimerStartDate(new Date())
    if (!window.idleInterval) {
      addEventListeners()
      window.idleInterval = setInterval(tick, TICK_INTERVAL)
    }
  }

  function stopTimer() {
    clearInterval(window.idleInterval)
    window.idleInterval = false
    removeEventListeners()
  }

  function restartTimer() {
    stopTimer()
    startTimer()
  }

  function tick() {
    const timerStartDate = getTimerStartDate()
    const timeElapsed = Math.ceil(
      new Date().getTime() - timerStartDate.getTime()
    )
    if (timeElapsed > timeout) {
      stopTimer()
      return onIdle()
    }
  }

  // Throttle timer reset to 1 call/sec
  const handleEvent = throttle(restartTimer, 1000)

  function addEventListeners() {
    document.addEventListener('keydown', handleEvent)
    document.addEventListener('mousemove', handleEvent)
    document.addEventListener('touchstart', handleEvent)
    document.addEventListener('scroll', handleEvent)
  }

  function removeEventListeners() {
    document.removeEventListener('keydown', handleEvent)
    document.removeEventListener('mousemove', handleEvent)
    document.removeEventListener('touchstart', handleEvent)
    document.removeEventListener('scroll', handleEvent)
  }

  startTimer(resume)
}

export default setIdleTimer
