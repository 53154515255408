import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, Switch, useHistory } from 'react-router-dom'
import FullLayout from './FullLayout'
import ErrorPage from './ErrorPage'
import UnauthorizedProfilePage from './UnauthorizedProfilePage'
import { Routes as HomeRoutes } from './home'
import { Routes as ProfileRoutes } from './profile'
import { Routes as ProductsRoutes } from './products'
import { Routes as CompanyRoutes } from './company'
import { Routes as NewSearchRoutes } from './new-search-flow'
import { Routes as AutomaticRolloversRoutes } from './automatic-rollovers'
import { Routes as BenefitDistributionsRoutes } from './benefit-distributions'
import { Routes as ResourcesRoutes } from './resources'
import AuthorizedLayout from './AuthorizedLayout'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as routerSelectors from 'connected-react-router'
import { selectors as globalSelectors } from './reducer'
import { Auth0Provider } from '@auth0/auth0-react'
import { ProductType } from 'config'

const propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  hasAccessToSearchServices: PropTypes.bool,
  hasAccessToAutomaticRollovers: PropTypes.bool,
  hasAccessToBenefitDistributions: PropTypes.bool,
  hasSearchServiceAgreement: PropTypes.bool.isRequired,
}

const defaultProps = {
  hasAccessToSearchServices: false,
  hasAccessToAutomaticRollovers: false,
  hasAccessToBenefitDistributions: false,
}

function Routes({
  isAdmin,
  hasAccessToSearchServices,
  hasAccessToAutomaticRollovers,
  hasAccessToBenefitDistributions,
  hasSearchServiceAgreement,
}) {
  const history = useHistory()

  const onRedirectCallback = (appState) => {
    history.push(appState?.redirectBackTo || window.location.pathname)
  }

  return (
    <Switch>
      <Route path="/error" component={ErrorPage} />
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <Route
          path="/unauthorized-profile"
          component={UnauthorizedProfilePage}
        />
        <AuthorizedLayout>
          <Switch>
            {hasAccessToSearchServices && (
              <Route
                path="/search"
                render={(routeProps) => (
                  <NewSearchRoutes
                    {...routeProps}
                    hasSearchServiceAgreement={hasSearchServiceAgreement}
                  />
                )}
              />
            )}
            {hasAccessToAutomaticRollovers && (
              <Route
                path="/automatic-rollovers"
                component={AutomaticRolloversRoutes}
              />
            )}
            {hasAccessToBenefitDistributions && (
              <Route
                path="/benefit-distributions"
                component={BenefitDistributionsRoutes}
              />
            )}
            <FullLayout>
              <Switch>
                <Route path="/home" component={HomeRoutes} />
                <Route path="/profile" component={ProfileRoutes} />
                {isAdmin && <Route path="/company" component={CompanyRoutes} />}
                <Route path="/resources" component={ResourcesRoutes} />
                <Route
                  path="/products"
                  render={(routeProps) => (
                    <ProductsRoutes
                      {...routeProps}
                      hasAccessToSearchServices={hasAccessToSearchServices}
                      hasAccessToAutomaticRollovers={
                        hasAccessToAutomaticRollovers
                      }
                      hasAccessToBenefitDistributions={
                        hasAccessToBenefitDistributions
                      }
                    />
                  )}
                />
                <Redirect path="*" to="/home" />
              </Switch>
            </FullLayout>
          </Switch>
        </AuthorizedLayout>
      </Auth0Provider>
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pathname: routerSelectors.getLocation(state).pathname,
    isAdmin: globalSelectors.isAdmin(state),
    hasAccessToSearchServices: globalSelectors.hasAccessToProduct(
      state,
      ProductType.SEARCH_SERVICES
    ),
    hasAccessToAutomaticRollovers: globalSelectors.hasAccessToProduct(
      state,
      ProductType.AUTOMATIC_ROLLOVERS
    ),
    hasAccessToBenefitDistributions: globalSelectors.hasAccessToProduct(
      state,
      ProductType.BENEFIT_DISTRIBUTIONS
    ),
    hasSearchServiceAgreement: globalSelectors.hasSearchServiceAgreement(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Routes)
