import { isEqual } from 'lodash'

// compares the address properties of a company object & contact object
// specifically for planSponsors and recordkeeper shapes
function isAddressEqual({ company, contact }) {
  return (
    isEqual(company.billingStreet, contact.mailingStreet) &&
    isEqual(company.billingCity, contact.mailingCity) &&
    isEqual(company.billingState, contact.mailingState) &&
    isEqual(company.billingPostalCode, contact.mailingPostalCode) &&
    isEqual(company.billingCountry, contact.mailingCountry)
  )
}

export default isAddressEqual
