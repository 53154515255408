import { reduce } from 'lodash'

// Converts an object from config to an array for options within a select input
function generateSelectOptionsFromObject(configObject) {
  return reduce(
    configObject,
    (result, value) => {
      result.push({ key: value, value })
      return result
    },
    []
  )
}

export default generateSelectOptionsFromObject
