// Rather than referencing the entire utils folder, please import individual files
// in order to avoid a circular dependency between config & util files
import generateSelectOptionsFromObject from 'utils/generateSelectOptionsFromObject'
import sortSelectOptions from 'utils/sortSelectOptions'
import { map } from 'lodash'

// App-wide config (routes, env vars) goes here.

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export const {
  REACT_APP_IDLE_TIMEOUT_DURATION,
  REACT_APP_IDLE_TIMEOUT_WARNING_DURATION,
} = process.env

export const SupportContactInformation = {
  email: 'RSP_CS_Support@inspirafinancial.com',
  phone: '(833) 923-5220',
}

export const FLASH_SUCCESS_TIMEOUT = 5000
export const FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT = 10000
export const TOAST_ERROR_CONTACT_SUPPORT = `It looks like something went wrong. If the issue continues, please contact support at <a href="mailto:${SupportContactInformation.email}" class="support-email" target="_blank" rel="noopener noreferrer">${SupportContactInformation.email}</a> or call ${SupportContactInformation.phone}.`
export const TOAST_ERROR_CONTACT_SUPPORT_EMAIL = `It looks like something went wrong. If the issue continues, please contact support at <a href="mailto:${SupportContactInformation.email}" class="support-email" target="_blank" rel="noopener noreferrer">${SupportContactInformation.email}</a>.`
export const VIRUS_FILE_ERROR_MESSAGE = `It looks like the file you uploaded is possibly corrupt or using a file format that's not supported. Please try re-uploading.`
export const TOAST_ERROR_CREDIT_CARD = `It looks like something went wrong with processing your credit card. Please review your credit card information. If the issue continues, please contact support at RSP_CS_Support@inspirafinancial.com`
export const TOAST_ERROR_SEARCH_ORDER_PROGRESS = `It looks like your order is In Progress, but something went wrong. If the issue continues, please contact support at RSP_CS_Support@inspirafinancial.com.`

export const COMPANY_NAME = {
  short: 'Inspira',
  medium: 'Inspira Financial',
  long: 'Inspira Financial',
  first: 'Inspira',
  full: 'Inspira Financial Trust, LLC',
}

export const AgreementStatus = {
  ACTIVE: 'Active',
  ACCEPTED: 'Accepted',
  NEGATIVE_CONSENT: 'Negative Consent',
  SIGNED: 'Signed',
  PENDING: 'Pending',
  PENDING_DOCUSIGN_SIGNATURE: 'Pending Docusign Signature',
  IN_REVIEW: 'In Review',
  CANCELED: 'Canceled',
  MISSING: 'Missing',
}

export const AVAILABLE_STATUSES = [
  AgreementStatus.ACTIVE,
  AgreementStatus.ACCEPTED,
  AgreementStatus.NEGATIVE_CONSENT,
  AgreementStatus.SIGNED,
]

export const SearchOrderStatus = {
  IN_PROGRESS: 'In Progress',
  RESULTS_EXPIRED: 'Results Expired',
  FAILED: 'Failed',
  RESULTS_AVAILABLE: 'Results Available',
}

export const SearchOrderTooltip = {
  [SearchOrderStatus.IN_PROGRESS]:
    'Your Search Order is currently in progress. We will notify you by email when the results are ready to be downloaded. Please contact Support if you have any questions regarding the status of your Search Order.',
  [SearchOrderStatus.RESULTS_EXPIRED]:
    'Results for your Search Order are no longer available to download.',
  [SearchOrderStatus.FAILED]:
    'Your Search Order failed to process; please contact Support.',
  [SearchOrderStatus.RESULTS_AVAILABLE]:
    'Your Search Order is complete and results are available to download for 30 days.',
}

export const ProductType = {
  AUTOMATIC_ROLLOVERS: 'Automatic Rollovers',
  BENEFIT_DISTRIBUTIONS: 'Benefit Distributions',
  SEARCH_SERVICES: 'Search Services',
  UNCASHED_CHECKS: 'Uncashed Checks',
}

export const ORDERED_PRODUCTS = [
  ProductType.AUTOMATIC_ROLLOVERS,
  ProductType.UNCASHED_CHECKS,
  ProductType.SEARCH_SERVICES,
  ProductType.BENEFIT_DISTRIBUTIONS,
]

// list of products that are always enabled, overriding service agreement conditions
export const ALWAYS_ENABLED_PRODUCTS = [
  ProductType.AUTOMATIC_ROLLOVERS,
  ProductType.SEARCH_SERVICES,
  ProductType.BENEFIT_DISTRIBUTIONS,
]

export const AgreementType = {
  ANNUITY: 'Annuity',
  ARP: 'ARP',
  ARP_PGF: 'ARP PGF',
  ARP_SAF: 'ARP SAF',
  BENEFIT_DISTRIBUTION: 'Benefit Distribution',
  CUSTODIAL: 'Custodial',
  CUSTODIAL_WIND_UP: 'Custodial - Wind Up',
  DIRECT_ROLLOVER: 'Direct Rollover',
  EMERGENCY_SAVINGS_ACCOUNT: 'Emergency Savings Account',
  LNFI: 'LNFI (Lincoln Financial)',
  NOTIFICATION: 'Notification',
  QTA: 'QTA',
  RESIGN_AND_APPOINT: 'Resign and Appoint',
  SEARCH: 'Search',
  SIMPLE_IRA_PLAN: 'SIMPLE IRA Plan',
  TRS: 'TRS',
  UNCASHED_CHECKS: 'Un-Cashed Checks',
  VALG: 'VALG (Valic)',
  WPS_COLLABORATION: 'WPS Collaboration',
  WPS_EMERGENCY_SAVINGS: 'WPS Emergency Savings',
  WPS_PAYROLL_DEDUCTED: 'WPS Payroll Deducted',
  WPS_SEP_IRA_PLAN: 'WPS SEP IRA Plan',
  WPS_SIMPLE_IRA_PLAN: 'WPS SIMPLE IRA Plan',
  NATW: 'NATW (Nationwide)',
}

export const ServiceAgreementMapping = {
  [AgreementType.ANNUITY]: null,
  [AgreementType.ARP]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.ARP_PGF]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.ARP_SAF]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.CUSTODIAL]: null,
  [AgreementType.CUSTODIAL_WIND_UP]: null,
  [AgreementType.DIRECT_ROLLOVER]: null,
  [AgreementType.LNFI]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.NOTIFICATION]: null,
  [AgreementType.QTA]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.RESIGN_AND_APPOINT]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.SEARCH]: ProductType.SEARCH_SERVICES,
  [AgreementType.TRS]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.VALG]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.NATW]: ProductType.AUTOMATIC_ROLLOVERS,
}

export const SearchTypeID = {
  ADDRESS_SEARCH: 'Address Search',
  CONFIRMED_SEARCH: 'Confirmed Search',
  BENEFICIARY_SEARCH: 'Beneficiary Search',
  FIDUCIARY_SEARCH: 'Fiduciary Search',
  ADVANCED_DEATH_AUDIT: 'Advanced Death Audit',
  RELATIVE_SEARCH: 'Relative Search',
}

export const US_STATES = [
  { value: 'AK', label: 'AK', fullName: 'Alaska' },
  { value: 'AL', label: 'AL', fullName: 'Alabama' },
  { value: 'AR', label: 'AR', fullName: 'Arkansas' },
  // { value: 'AB', label: 'AB', fullName: '' },
  { value: 'AZ', label: 'AZ', fullName: 'Arizona' },
  // { value: 'BC', label: 'BC', fullName: '' },
  { value: 'CA', label: 'CA', fullName: 'California' },
  { value: 'CO', label: 'CO', fullName: 'Colorado' },
  { value: 'CT', label: 'CT', fullName: 'Connecticut' },
  { value: 'DC', label: 'DC', fullName: 'District of Columbia' },
  { value: 'DE', label: 'DE', fullName: 'Delaware' },
  { value: 'FL', label: 'FL', fullName: 'Florida' },
  { value: 'GA', label: 'GA', fullName: 'Georgia' },
  // { value: 'GU', label: 'GU', fullName: 'Guam' },
  { value: 'HI', label: 'HI', fullName: 'Hawaii' },
  { value: 'ID', label: 'ID', fullName: 'Idaho' },
  { value: 'IL', label: 'IL', fullName: 'Illinois' },
  { value: 'IN', label: 'IN', fullName: 'Indiana' },
  { value: 'IA', label: 'IA', fullName: 'Iowa' },
  { value: 'KS', label: 'KS', fullName: 'Kansas' },
  { value: 'KY', label: 'KY', fullName: 'Kentucky' },
  { value: 'LA', label: 'LA', fullName: 'Louisiana' },
  { value: 'ME', label: 'ME', fullName: 'Maine' },
  // { value: 'MB', label: 'MB', fullName: '' },
  { value: 'MD', label: 'MD', fullName: 'Maryland' },
  { value: 'MA', label: 'MA', fullName: 'Massachusetts' },
  { value: 'MI', label: 'MI', fullName: 'Michigan' },
  { value: 'AA', label: 'AA', fullName: 'Armed Forces Americas' },
  { value: 'AE', label: 'AE', fullName: 'Armed Forces Europe' },
  { value: 'AP', label: 'AP', fullName: 'Armed Forces Pacific' },
  { value: 'MN', label: 'MN', fullName: 'Minnesota' },
  { value: 'MS', label: 'MS', fullName: 'Mississippi' },
  { value: 'MO', label: 'MO', fullName: 'Missouri' },
  { value: 'MT', label: 'MT', fullName: 'Montana' },
  { value: 'NE', label: 'NE', fullName: 'Nebraska' },
  { value: 'NV', label: 'NV', fullName: 'Nevada' },
  // { value: 'NB', label: 'NB', fullName: '' },
  { value: 'NH', label: 'NH', fullName: 'New Hampshire' },
  { value: 'NJ', label: 'NJ', fullName: 'New Jersey' },
  { value: 'NM', label: 'NM', fullName: 'New Mexico' },
  { value: 'NY', label: 'NY', fullName: 'New York' },
  // { value: 'NL', label: 'NL', fullName: '' },
  { value: 'NC', label: 'NC', fullName: 'North Carolina' },
  { value: 'ND', label: 'ND', fullName: 'North Dakota' },
  // { value: 'NT', label: 'NT', fullName: '' },
  // { value: 'NS', label: 'NS', fullName: '' },
  // { value: 'NU', label: 'NU', fullName: '' },
  { value: 'OH', label: 'OH', fullName: 'Ohio' },
  { value: 'OK', label: 'OK', fullName: 'Oklahoma' },
  // { value: 'ON', label: 'ON', fullName: '' },
  { value: 'OR', label: 'OR', fullName: 'Oregon' },
  { value: 'PA', label: 'PA', fullName: 'Pennsylvania' },
  // { value: 'PE', label: 'PE', fullName: '' },
  { value: 'PR', label: 'PR', fullName: 'Puerto Rico' },
  // { value: 'QC', label: 'QC', fullName: '' },
  { value: 'RI', label: 'RI', fullName: 'Rhode Island' },
  // { value: 'SK', label: 'SK', fullName: '' },
  { value: 'SC', label: 'SC', fullName: 'South Carolina' },
  { value: 'SD', label: 'SD', fullName: 'South Dakota' },
  { value: 'TN', label: 'TN', fullName: 'Tennessee' },
  { value: 'TX', label: 'TX', fullName: 'Texas' },
  { value: 'UT', label: 'UT', fullName: 'Utah' },
  { value: 'VT', label: 'VT', fullName: 'Vermont' },
  // { value: 'VI', label: 'VI', fullName: 'Virgin Islands' },
  { value: 'VA', label: 'VA', fullName: 'Virginia' },
  { value: 'WA', label: 'WA', fullName: 'Washington' },
  { value: 'WV', label: 'WV', fullName: 'West Virginia' },
  { value: 'WI', label: 'WI', fullName: 'Wisconsin' },
  { value: 'WY', label: 'WY', fullName: 'Wyoming' },
  // { value: 'YT', label: 'YT', fullName: '' },
]

export const US_COUNTRY_VALUE = 'US'
export const CANADA_COUNTRY_VALUE = 'CA'

export const COUNTRIES = [
  { value: 'AF', fullName: 'Afghanistan' },
  { value: 'AX', fullName: 'Aland Islands' },
  { value: 'AL', fullName: 'Albania' },
  { value: 'DZ', fullName: 'Algeria' },
  { value: 'AS', fullName: 'American Samoa' },
  { value: 'AO', fullName: 'Angola' },
  { value: 'AI', fullName: 'Anguilla' },
  { value: 'AQ', fullName: 'Antarctica' },
  { value: 'AG', fullName: 'Antigua & Barbuda' },
  { value: 'AR', fullName: 'Argentina' },
  { value: 'AM', fullName: 'Armenia' },
  { value: 'AW', fullName: 'Aruba' },
  { value: 'AU', fullName: 'Australia' },
  { value: 'AT', fullName: 'Austria' },
  { value: 'AZ', fullName: 'Azerbaidjan' },
  { value: 'WI', fullName: 'BRITISH WEST INDIES' },
  { value: 'BF', fullName: 'Bahamas' },
  { value: 'BH', fullName: 'Bahrain' },
  { value: 'BD', fullName: 'Bangladesh' },
  { value: 'BB', fullName: 'Barbados' },
  { value: 'BY', fullName: 'Belarus' },
  { value: 'BE', fullName: 'Belgium' },
  { value: 'BZ', fullName: 'Belize' },
  { value: 'BJ', fullName: 'Benin' },
  { value: 'BM', fullName: 'Bermuda' },
  { value: 'BT', fullName: 'Bhutan' },
  { value: 'BO', fullName: 'Bolivia' },
  { value: 'BA', fullName: 'Bosnia & Herzegovina' },
  { value: 'BW', fullName: 'Botswana' },
  { value: 'BV', fullName: 'Bouvet Island' },
  { value: 'BR', fullName: 'Brazil' },
  { value: 'IO', fullName: 'British Indian Ocean Territory' },
  { value: 'BN', fullName: 'Brunei' },
  { value: 'BG', fullName: 'Bulgaria' },
  { value: 'BU', fullName: 'Burma' },
  { value: 'BI', fullName: 'Burundi' },
  { value: 'KH', fullName: 'Cambodia' },
  { value: 'CM', fullName: 'Cameroon' },
  { value: CANADA_COUNTRY_VALUE, fullName: 'Canada' },
  { value: 'CV', fullName: 'Cape Verde' },
  { value: 'KY', fullName: 'Cayman Islands' },
  { value: 'CF', fullName: 'Central African Republic' },
  { value: 'CL', fullName: 'Chile' },
  { value: 'CN', fullName: 'China' },
  { value: 'CX', fullName: 'Christmas Island' },
  { value: 'CO', fullName: 'Colombia' },
  { value: 'KM', fullName: 'Comoros' },
  { value: 'CG', fullName: 'Congo' },
  { value: 'CD', fullName: 'Congo, Democratic Republic' },
  { value: 'CK', fullName: 'Cook Islands' },
  { value: 'CR', fullName: 'Costa Rica' },
  { value: 'HR', fullName: 'Croatia' },
  { value: 'CU', fullName: 'Cuba' },
  { value: 'CC', fullName: 'Curacao' },
  { value: 'CY', fullName: 'Cyprus' },
  { value: 'CZ', fullName: 'Czech Republic' },
  { value: 'CS', fullName: 'Czechoslovakia' },
  { value: 'DK', fullName: 'Denmark' },
  { value: 'DJ', fullName: 'Djibouti' },
  { value: 'DM', fullName: 'Dominica' },
  { value: 'DO', fullName: 'Dominican Republic' },
  { value: 'ER', fullName: 'Ecuador' },
  { value: 'EG', fullName: 'Egypt' },
  { value: 'SV', fullName: 'El Salvador' },
  { value: 'GQ', fullName: 'Equatorial Guinea' },
  { value: 'Ee', fullName: 'Estonia' },
  { value: 'ET', fullName: 'Ethiopia' },
  { value: 'EU', fullName: 'Euro' },
  { value: 'EC', fullName: 'European Currency Unit' },
  { value: 'XE', fullName: 'European Institutions' },
  { value: 'FK', fullName: 'Falkland Islands' },
  { value: 'FO', fullName: 'Faroe Islands' },
  { value: 'FJ', fullName: 'Fiji' },
  { value: 'FL', fullName: 'Finland' },
  { value: 'FR', fullName: 'France' },
  { value: 'GF', fullName: 'French Guiana' },
  { value: 'PF', fullName: 'French Polynesia' },
  { value: 'TF', fullName: 'French Southern Territories' },
  { value: 'GA', fullName: 'Gabon' },
  { value: 'GM', fullName: 'Gambia' },
  { value: 'GE', fullName: 'Georgia' },
  { value: 'DE', fullName: 'Germany' },
  { value: 'GH', fullName: 'Ghana' },
  { value: 'GI', fullName: 'Gibraltar' },
  { value: 'GB', fullName: 'Great Britian' },
  { value: 'GR', fullName: 'Greece' },
  { value: 'GL', fullName: 'Greenland' },
  { value: 'GD', fullName: 'Grenada' },
  { value: 'GP', fullName: 'Guadeloupe (French)' },
  { value: 'GU', fullName: 'Guam' },
  { value: 'GT', fullName: 'Guatemala' },
  { value: 'GG', fullName: 'Guernsey' },
  { value: 'GN', fullName: 'Guinea' },
  { value: 'GW', fullName: 'Guinea-Bissau' },
  { value: 'GY', fullName: 'Guyana' },
  { value: 'HT', fullName: 'Haiti' },
  { value: 'HM', fullName: 'Heard Island & McDonald Island' },
  { value: 'NL', fullName: 'Holland' },
  { value: 'VA', fullName: 'Holy See (Vatican)' },
  { value: 'HN', fullName: 'Honduras' },
  { value: 'HK', fullName: 'Hong Kong' },
  { value: 'HU', fullName: 'Hungary' },
  { value: 'IS', fullName: 'Iceland' },
  { value: 'IN', fullName: 'India' },
  { value: 'ID', fullName: 'Indonesia' },
  { value: 'IR', fullName: 'Iran' },
  { value: 'IQ', fullName: 'Iraq' },
  { value: 'IE', fullName: 'Ireland' },
  { value: 'IM', fullName: 'Isle of Man' },
  { value: 'IL', fullName: 'Israel' },
  { value: 'IT', fullName: 'Italy' },
  { value: 'CI', fullName: 'Ivory Coast' },
  { value: 'JM', fullName: 'Jamaica' },
  { value: 'JP', fullName: 'Japan' },
  { value: 'JE', fullName: 'Jersey C.I.' },
  { value: 'JO', fullName: 'Jordan' },
  { value: 'KZ', fullName: 'Kazakhstan' },
  { value: 'KE', fullName: 'Kenya' },
  { value: 'KI', fullName: 'Kiribati' },
  { value: 'KR', fullName: 'Korea' },
  { value: 'KW', fullName: 'Kuwait' },
  { value: 'KG', fullName: 'Kyrgyzstan' },
  { value: 'LA', fullName: 'Laos' },
  { value: 'LV', fullName: 'Latvia' },
  { value: 'LB', fullName: 'Lebanon' },
  { value: 'LS', fullName: 'Lesotho' },
  { value: 'LR', fullName: 'Liberia' },
  { value: 'LI', fullName: 'Liechtenstein' },
  { value: 'LT', fullName: 'Lithuania' },
  { value: 'LU', fullName: 'Luxembourg' },
  { value: 'LY', fullName: 'Lybia' },
  { value: 'MO', fullName: 'Macao' },
  { value: 'MK', fullName: 'Macedonia' },
  { value: 'MG', fullName: 'Madagascar' },
  { value: 'MW', fullName: 'Malawi' },
  { value: 'MY', fullName: 'Malaysia' },
  { value: 'MV', fullName: 'Maldives' },
  { value: 'ML', fullName: 'Mali' },
  { value: 'MT', fullName: 'Maltax' },
  { value: 'MH', fullName: 'Marshall Islands' },
  { value: 'MQ', fullName: 'Martinique' },
  { value: 'MR', fullName: 'Mauritania' },
  { value: 'MU', fullName: 'Mauritius' },
  { value: 'YT', fullName: 'Mayotte' },
  { value: 'MX', fullName: 'Mexico' },
  { value: 'FM', fullName: 'Micronesia' },
  { value: 'MD', fullName: 'Moldova' },
  { value: 'MC', fullName: 'Monaco' },
  { value: 'MN', fullName: 'Mongolia' },
  { value: 'ME', fullName: 'Montenegro' },
  { value: 'MS', fullName: 'Montserrat' },
  { value: 'MA', fullName: 'Morocco' },
  { value: 'MZ', fullName: 'Mozambique' },
  { value: 'YY', fullName: 'Multinational' },
  { value: 'MM', fullName: 'Myanmar (Burma)' },
  { value: 'NA', fullName: 'Namibia' },
  { value: 'XO', fullName: 'Nato, Shape' },
  { value: 'NR', fullName: 'Nauru' },
  { value: 'NP', fullName: 'Nepal' },
  { value: 'NV', fullName: 'Netherlands' },
  { value: 'AN', fullName: 'Netherlands Antilles' },
  { value: 'NC', fullName: 'New Caledonia' },
  { value: 'NZ', fullName: 'New Zealand' },
  { value: 'NI', fullName: 'Nicaragua' },
  { value: 'NE', fullName: 'Niger' },
  { value: 'NG', fullName: 'Nigeria' },
  { value: 'NU', fullName: 'Niue' },
  { value: 'NF', fullName: 'Norfolk Island' },
  { value: 'KP', fullName: 'North Korea' },
  { value: 'MP', fullName: 'Northern Mariana Islands' },
  { value: 'NO', fullName: 'Norway' },
  { value: 'OM', fullName: 'Oman' },
  { value: 'OT', fullName: 'Other' },
  { value: 'YD', fullName: 'P.D.R. Of The Yemen' },
  { value: 'PK', fullName: 'Pakistan' },
  { value: 'PW', fullName: 'Palau' },
  { value: 'PS', fullName: 'Palestinian Territory' },
  { value: 'PA', fullName: 'Panama' },
  { value: 'PG', fullName: 'Papua New Guinea' },
  { value: 'PY', fullName: 'Paraguay' },
  { value: 'PE', fullName: 'Peru' },
  { value: 'PH', fullName: 'Philippines' },
  { value: 'PN', fullName: 'Pitcairn' },
  { value: 'PL', fullName: 'Poland' },
  { value: 'PT', fullName: 'Portugal' },
  { value: 'PR', fullName: 'Puerto Rico' },
  { value: 'QA', fullName: 'Qatar' },
  { value: 'RE', fullName: 'Reunion' },
  { value: 'RH', fullName: 'Rhodesia' },
  { value: 'RO', fullName: 'Rumainia' },
  { value: 'RU', fullName: 'Russian Federation' },
  { value: 'RW', fullName: 'Rwanda' },
  { value: 'BL', fullName: 'Saint Barthelemy' },
  { value: 'SH', fullName: 'Saint Helena' },
  { value: 'KN', fullName: 'Saint Kitts & Nevis' },
  { value: 'LC', fullName: 'Saint Lucia' },
  { value: 'MF', fullName: 'Saint Martin' },
  { value: 'PM', fullName: 'Saint Pierre & Miquelon' },
  { value: 'VC', fullName: 'Saint Vincent & The Grenadines' },
  { value: 'WS', fullName: 'Samoa (see Western Samoa)' },
  { value: 'SM', fullName: 'San Marino' },
  { value: 'ST', fullName: 'Sao Tome & Principe' },
  { value: 'SA', fullName: 'Saudi Arabia' },
  { value: 'SN', fullName: 'Senegal' },
  { value: 'RS', fullName: 'Serbia' },
  { value: 'SC', fullName: 'Seychelles' },
  { value: 'SL', fullName: 'Sierra Leone' },
  { value: 'SG', fullName: 'Singapore' },
  { value: 'SK', fullName: 'Slovakia' },
  { value: 'SI', fullName: 'Slovenia' },
  { value: 'SB', fullName: 'Solomon Islands' },
  { value: 'SO', fullName: 'Somalia' },
  { value: 'ZA', fullName: 'South Africa' },
  { value: 'GS', fullName: 'South Georgia & Sandwich Ils.' },
  { value: 'ES', fullName: 'Spain' },
  { value: 'LK', fullName: 'Sri Lanka' },
  { value: 'SD', fullName: 'Sudan' },
  { value: 'XS', fullName: 'Supranational' },
  { value: 'SR', fullName: 'Surinam' },
  { value: 'SJ', fullName: 'Svalbard & Jan Mayen' },
  { value: 'SZ', fullName: 'Swaziland' },
  { value: 'SW', fullName: 'Sweden' },
  { value: 'CH', fullName: 'Switzerland' },
  { value: 'SY', fullName: 'Syria' },
  { value: 'TW', fullName: 'Taiwan' },
  { value: 'TJ', fullName: 'Tajikstan' },
  { value: 'TZ', fullName: 'Tanzania' },
  { value: 'TD', fullName: 'Tchad' },
  { value: 'TH', fullName: 'Thailand' },
  { value: 'TL', fullName: 'Timor-Leste' },
  { value: 'TG', fullName: 'Togo' },
  { value: 'TK', fullName: 'Tokelau' },
  { value: 'TO', fullName: 'Tonga' },
  { value: 'TT', fullName: 'Trinidad and Tobago' },
  { value: 'TN', fullName: 'Tunisia' },
  { value: 'TR', fullName: 'Turkey' },
  { value: 'TM', fullName: 'Turkmenistan' },
  { value: 'TC', fullName: 'Turks & Caicos Islands' },
  { value: 'TV', fullName: 'Tuvalu' },
  { value: 'VI', fullName: 'US VIRGIN ISLANDS' },
  { value: 'UG', fullName: 'Uganda' },
  { value: 'UA', fullName: 'Ukraine' },
  { value: 'AE', fullName: 'United Arab Emirates' },
  { value: 'UK', fullName: 'United Kingdom' },
  { value: US_COUNTRY_VALUE, fullName: 'United States' },
  { value: 'HV', fullName: 'Upper Volta' },
  { value: 'UY', fullName: 'Uruguay' },
  { value: 'UZ', fullName: 'Uzbekistan' },
  { value: 'VU', fullName: 'Vanuatu' },
  { value: 'VE', fullName: 'Venezuela' },
  { value: 'VN', fullName: 'Vietnam' },
  { value: 'VG', fullName: 'Virgin Islands, British' },
  { value: 'WF', fullName: 'Wallis & Futuna' },
  { value: 'EH', fullName: 'Western Sahara' },
  { value: 'YE', fullName: 'Yemen' },
  { value: 'YU', fullName: 'Yugoslavia' },
  { value: 'ZR', fullName: 'Zaire' },
  { value: 'ZM', fullName: 'Zambia' },
  { value: 'ZW', fullName: 'Zimbabwe' },
]

export const COUNTRY_ALIAS_DICT = {
  GUADELOUPE: 'GP',
  'HOLY SEE': 'VA',
  VATICAN: 'VA',
  SAMOA: 'WS',
  'WESTERN SAMOA': 'WS',
  USA: US_COUNTRY_VALUE,
  'UNITED STATES OF AMERICA': US_COUNTRY_VALUE,
  UAE: 'AE',
}

export const SearchOrderOptions = {
  [SearchTypeID.ADDRESS_SEARCH]: { estimatedTime: 'Same Day' },
  [SearchTypeID.CONFIRMED_SEARCH]: { estimatedTime: 'X to Y business days' },
  [SearchTypeID.VERIFIED_DEATH_AUDIT]: {
    estimatedTime: 'X to Y business days',
  },
}

// User persona values expected from fetching the userProfiles API
export const AccountAudience = {
  PLAN_FIDUCIARY: 'Plan Fiduciary',
  TPA: 'TPA',
  RECORDKEEPER: 'Recordkeeper',
}

export const CompanyType = {
  NATIONWIDE: 'Nationwide Retirement Plans',
  TRANSAMERICA: 'Transamerica Retirement Management',
  AMERITAS: 'Ameritas Retirement Plans',
  PENSION: 'Pension, Inc.',
  COREBRIDGE: 'Corebridge Financial formerly AIG Retirement Services',
  // VALIC_5 COMPANIES
  AMERICAN: 'American Pension Benefits',
  CORPORATE_1: 'Corporate Financial Service, LLC',
  CORPORATE_2: 'Corporate Financial Services, LLC',
  FM_INTERNATIONAL: 'FM International Services, Ltd',
  FMI_RETIREMENT: 'FMi Retirement Services',
  HILEMAN: 'Hileman Associates',
  HOLZKNECHT: 'HOLZKNECHT UMALI INC',
  HUNNEX: 'Hunnex & Shoemaker',
  METRO_1: 'Metro Benefits Inc',
  METRO_2: 'Metro Benefits, Inc.',
  PA_ADMINISTRATIVE: 'P&A Administrative Services, Inc.',
  RPS: 'Retirement Plan Solutions (RPS)',
  RPS_PA: 'Retirement Plan Solutions - PA',
  WALKER: 'Walker Benefits, Inc./Baker Tilly',
  // LINCOLN COMPANIES
  ASSURED: 'Assured SKCG, Inc.',
  BARCLAY: 'Barclay Planning Group, LLC',
  DANZIGER: 'Danziger & Markhoff LLP',
  DYNAMIC: 'Dynamic Pension Services, Inc',
  FRINGE_BENEFITS: 'Fringe Benefits Design of Minnesota, Inc',
  FUTURE_BENEFITS: 'Future Benefits of America, LLC',
  INVISOR: 'Invisor Solutions LLC',
  ISOLVED: 'iSolved Network',
  LEADING: 'Leading Retirement Solutions',
  MORGAN_STANLEY: 'Morgan Stanley Wealth Management, LLC',
  RIVERSEDGE: 'RiversEdge Advanced Retirement Solutions, LLC',
  SCHNEIDER: 'Schneider Downs Retirement Solutions',
  PAYROLL_COMPANY: 'The Payroll Company is now Isolved',
  USI_CONSULTING: 'USI Consulting Group',
  WIPFLI: 'Wipfli, LLP',
  WORK_PLAN_RETIRE: 'Work Plan Retire',
}

export const InvestmentType = {
  NATIONWIDE: 'Nationwide Plus IRA Fixed Account',
  TRANSAMERICA: 'Transamerica Stable Value Accumulation Option',
  AMERITAS: 'Lincoln Fixed Account',
  VALIC_8: 'VALIC Fixed-Interest Option 922169248',
  VALIC_6: 'VALIC Fixed-Interest Option 922169586',
  VALIC_5: 'VALIC Fixed-Interest Option 922169305',
  DEFAULT: 'Cash Sweep',
}

export const valicFiveInvestmentTypes = [
  'American Pension Benefits',
  'Corporate Financial Service, LLC',
  'Corporate Financial Services, LLC',
  'FM International Services, Ltd',
  'FMi Retirement Services',
  'Hileman Associates',
  'HOLZKNECHT UMALI INC',
  'Hunnex & Shoemaker',
  'Metro Benefits Inc',
  'Metro Benefits, Inc.',
  'P&A Administrative Services, Inc.',
  'Retirement Plan Solutions (RPS)',
  'Retirement Plan Solutions - PA',
  'Walker Benefits, Inc./Baker Tilly',
  'FM International Services, Ltd.',
]

export const lincolnInvestmentTypes = [
  'Assured SKCG, Inc.',
  'Barclay Planning Group, LLC',
  'Danziger & Markhoff LLP',
  'Dynamic Pension Services, Inc',
  'Fringe Benefits Design of Minnesota, Inc',
  'Future Benefits of America, LLC',
  'Invisor Solutions LLC',
  'iSolved Network',
  'Leading Retirement Solutions',
  'Morgan Stanley Wealth Management, LLC',
  'RiversEdge Advanced Retirement Solutions, LLC',
  'Schneider Downs Retirement Solutions',
  'The Payroll Company is now Isolved',
  'USI Consulting Group',
  'Wipfli, LLP',
  'Work Plan Retire',
]

export const RetirementServicesPortalProfile = {
  ADMIN: 'Admin',
  STANDARD: 'Standard',
}

export const SigningMethod = {
  // values used in the form:
  ELECTRONIC_SIGNATURE: 'Electronic Signature',
  WET_SIGNATURE: 'Wet Signature',
  // values from BE:
  ELECTRONIC: 'Electronic',
  GENERATE_DOCUMENT: 'Generate Document',
  E_SIGN_RSP: 'E-sign RSP',
}

export const signingMethodOptions = [
  {
    key: SigningMethod.ELECTRONIC_SIGNATURE,
    value: 'electronicSignature',
    description: `The Services Agreement document will be electronically signed (via DocuSign®). The electronic Services Agreement document will be automatically submitted to ${COMPANY_NAME['medium']} once the document has been eSigned.`,
  },
  {
    key: SigningMethod.WET_SIGNATURE,
    value: 'wetSignature',
    description: `Start the new agreement process by completing this form and we'll generate a Services Agreement PDF that you can download, sign, and upload when completed.`,
  },
  {
    key: SigningMethod.E_SIGN_RSP,
    value: 'eSignRSP',
    description: null,
  },
]

export const SubmissionMethod = {
  PROCEED_WITH_SUBMISSION: 'Proceed with submission',
  REMOVE_FROM_SUBMISSION: 'Remove from submission',
}

export const submissionMethodOptions = [
  {
    key: SubmissionMethod.PROCEED_WITH_SUBMISSION,
    value: 'proceedWithSubmission',
  },
  {
    key: SubmissionMethod.REMOVE_FROM_SUBMISSION,
    value: 'removeFromSubmission',
  },
]

export const Path = {
  SEARCH_TYPE: '/search/search-type',
  UPLOAD_FILE: '/search/upload-file',
  UPLOAD_SUMMARY: '/search/upload-summary',
  PERMISSIBLE_USE: '/search/permissible-use',
  CONFIRM_ORDER: '/search/confirm-submission',
  SERVICES_AGREEMENTS: '/products/automatic-rollovers/services-agreements',
  BD_SERVICES_AGREEMENTS: '/products/benefit-distributions/history',
  SEARCH_HISTORY: '/products/search/history',
  ADD_NEW_SERVICES_AGREEMENT: '/automatic-rollovers/add-new-services-agreement',
  SELECT_COMPANY_INFORMATION: '/automatic-rollovers/select-your-information',
  ADD_NEW_SEARCH_SERVICES_AGREEMENT: '/search/add-new-services-agreement',
  SERVICE_AGREEMENT_DETAILS: '/search/service-agreement-details',
  ADD_PAYMENT_DETAILS: '/profile/payment-details',
  CONFIRMED_SEARCH: '/search/mailing-preferences',
  FIDUCIARY_SEARCH: '/search/final-letter',
}

// Valid EIN prefixes
// https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
export const EIN_REGEX =
  /^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])-?\d{7}$/

export const PROVIDER_PLAN_ID_NUMBER_REGEX = /^[0-9a-zA-Z-]{0,30}$/
export const US_ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/

export const ServicesAgreementStatus = {
  PENDING_SIGNATURES: 'Pending Signatures',
  IN_REVIEW: 'In Review',
  COMPLETED: 'Completed',
}

export const ServicesAgreementTooltip = {
  [ServicesAgreementStatus.PENDING_SIGNATURES]: 'Agreement not signed',
  [ServicesAgreementStatus.IN_REVIEW]: 'Signed agreement under review',
  [ServicesAgreementStatus.COMPLETED]: 'Agreement review completed',
}

export const UPLOAD_AGREEMENT_SUCCESS_MESSAGE =
  'Success! Your Plan Agreement has been received and will be reviewed within 1-2 business days.'

// These values are what's expected from the API
export const UserRole = {
  ADMIN: 'Admin',
  STANDARD: 'Standard',
}
export const ADMIN_ROLE_OPTION = {
  key: 'Administrator', // FE to convert 'Admin' to 'Administrator' for visual purposes (key only)
  value: UserRole.ADMIN, // Still use 'Admin' as the select option's value for form submission
}
export const userRoleOptions = [
  ADMIN_ROLE_OPTION,
  {
    key: UserRole.STANDARD,
    value: UserRole.STANDARD,
  },
]
export const sortedUserRoleOptions = sortSelectOptions(userRoleOptions, 'key')

export const UserStatus = {
  INVITED: 'Invited',
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
}

// flow stages

export const ParticipantFileUploadStage = {
  participantFileUpload: 'participant-file-upload',
  planMapping: 'plan-mapping',
  reviewAndSubmit: 'review-and-submit',
}

export const CreateNewServicesAgreementStage = {
  ADD_NEW_SERVICES_AGREEMENT: 'add-new-services-agreement',
  SELECT_COMPANY_INFORMATION: 'select-company-information',
  AGREEMENT_DETAILS_AND_SUBMIT: 'agreement-details-and-submit',
}
export const CreateNewSearchServicesAgreementStage = {
  ADD_NEW_SEARCH_SERVICES_AGREEMENT: 'add-new-services-agreement',
  SUBMIT_SEARCH_AGREEMENT: 'review-and-submit',
}

export const NewSearchStage = {
  searchSelection: 'search-selection',
  uploadFile: 'upload-file',
  submissionDetails: 'submission-details',
  finalLetter: 'final-letter',
  mailingPreferences: 'mailing-preferences',
  confirmSubmission: 'confirm-submission',
}

export const SearchServicesNewServicesAgreement = {
  serviceAgreementDetails: 'service-agreement-details',
  addNewServicesAgreement: 'add-new-services-agreement',
}

export const ServicesAgreementPlanType = {
  DEFINED_BENEFIT: 'Defined Benefit',
  DEFINED_CONTRIBUTION: 'Defined Contribution',
}
const planTypeOptions = generateSelectOptionsFromObject(
  ServicesAgreementPlanType
)
export const sortedPlanTypeOptions = sortSelectOptions(planTypeOptions, 'key')
export const planTypeValues = map(planTypeOptions, 'value')

export const ServicesAgreementPlanStatus = {
  ACTIVE: 'Active',
  TERMINATING: 'Terminating',
}
const planStatusOptions = generateSelectOptionsFromObject(
  ServicesAgreementPlanStatus
)
export const sortedPlanStatusOptions = sortSelectOptions(
  planStatusOptions,
  'key'
)
export const planStatusValues = map(planStatusOptions, 'value')

export const StandardRadioGroupOptions = [
  {
    key: 'Yes',
    value: 'true',
  },
  {
    key: 'No',
    value: 'false',
  },
]

// submissionType enum (headers) for the services agreement submission api endpoints
// the following diagram provides a visual on which type to use depending on the use case
// https://share.getcloudapp.com/ApuXB80z
export const ServicesAgreementSubmissionType = {
  NEW_AGREEMENT: 'newAgreement',
  EXISTING_AGREEMENT: 'existingAgreement',
  GENERATE_DOCUMENT: 'generateDocument',
  GENERATE_DOCUMENT_EXISTING: 'generateDocumentExisting',
  SEARCH_SERVICES_AGREEMENT: 'searchServicesAgreement',
}

export const ADD_PLAN_SPONSOR_OPTION = {
  key: 'Add New Plan Sponsor',
  value: 'add-plan-sponsor',
}

export const ADD_RECORDKEEPER_OPTION = {
  key: 'Add New Recordkeeper',
  value: 'add-recordkeeper',
}

export const ADD_TPA_OPTION = {
  key: 'Add New TPA',
  value: 'add-tpa',
}

export const ADD_CONSULTANT_OPTION = {
  key: 'Add New Consultant',
  value: 'add-consultant',
}

export const NO_SERVICES_AGREEMENT_OPTION = {
  label: 'No Services Agreement Found',
  value: 'no-services-agreement',
}

// Values that MS expects for contacts submitted
// e.g. contacts submitted through the submit plan agreement
export const AudienceType = {
  PLAN_SPONSOR: 'Plan Sponsor',
  RECORD_KEEPER: 'Record Keeper',
  TPA: 'TPA',
  CONSULTANT: 'Consultant',
}

export const INITIAL_INVESTMENT_TOOLTIP_CONTENT =
  'Initial investment of IRA (FDIC-insured cash sweep program or stable value fund).'
export const COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT =
  'Complete Legal Plan name is the official name of the plan that this Services Agreement is being submitted for.'

export const SERVICES_AGREEMENT_UPLOAD_INSTRUCTIONS = `Modifications and/or changes to Services Agreement cannot be submitted through the platform and will result in delays in processing your submission. Please reach out to your ${COMPANY_NAME['medium']} relationship manager if you have any questions.`

export const PERMISSIBLE_USE_TOOLTIP_CONTENT =
  'If your request includes more than one Plan and the permissible uses are not the same for each plan, you must submit a separate search request for each plan.'
// JS code snippet (executable script) to implement GetFeedback form to the app.
// Generated and managed by MTCs marketing team
export const GET_FEEDBACK_SCRIPT = `/*{literal}<![CDATA[*/window.lightningjs||function(c){function g(b,d){d&&(d+=(/\\?/.test(d)?"&":"?")+"lv=1");c[b]||function(){var i=window,h=document,j=b,g=h.location.protocol,l="load",k=0;(function(){function b(){a.P(l);a.w=1;c[j]("_load")}c[j]=function(){function m(){m.id=e;return c[j].apply(m,arguments)}var b,e=++k;b=this&&this!=i?this.id||0:0;(a.s=a.s||[]).push([e,b,arguments]);m.then=function(b,c,h){var d=a.fh[e]=a.fh[e]||[],j=a.eh[e]=a.eh[e]||[],f=a.ph[e]=a.ph[e]||[];b&&d.push(b);c&&j.push(c);h&&f.push(h);return m};return m};var a=c[j]._={};a.fh={};a.eh={};a.ph={};a.l=d?d.replace(/^\\/\\//,(g=="https:"?g:"http:")+"//"):d;a.p={0:+new Date};a.P=function(b){a.p[b]=new Date-a.p[0]};a.w&&b();i.addEventListener?i.addEventListener(l,b,!1):i.attachEvent("on"+l,b);var q=function(){function b(){return["<head></head><",c,' onload="var d=',n,";d.getElementsByTagName('head')[0].",d,"(d.",g,"('script')).",i,"='",a.l,"'\\"></",c,">"].join("")}var c="body",e=h[c];if(!e)return setTimeout(q,100);a.P(1);var d="appendChild",g="createElement",i="src",k=h[g]("div"),l=k[d](h[g]("div")),f=h[g]("iframe"),n="document",p;k.style.display="none";e.insertBefore(k,e.firstChild).id=o+"-"+j;f.frameBorder="0";f.id=o+"-frame-"+j;/MSIE[ ]+6/.test(navigator.userAgent)&&(f[i]="javascript:false");f.allowTransparency="true";l[d](f);try{f.contentWindow[n].open()}catch(s){a.domain=h.domain,p="javascript:var d="+n+".open();d.domain='"+h.domain+"';",f[i]=p+"void(0);"}try{var r=f.contentWindow[n];r.write(b());r.close()}catch(t) {
f[i]=p+'d.write("'+b().replace(/"/g,String.fromCharCode(92)+'"')+'");d.close();'}a.P(2)};
a.l&&setTimeout(q,0)})()}();c[b].lv="1";return c[b]}var o="lightningjs",k=window[o]=g(o);k.require=g;k.modules=c}({}); if(!navigator.userAgent.match(/Android|BlackBerry|BB10|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {window.usabilla_live = lightningjs.require("usabilla_live", "//w.usabilla.com/7c8b986a9f88.js"); } else {window.usabilla_live = lightningjs.require("usabilla_live", "//w.usabilla.com/57664cd6c9ff.js"); }/*]]>{/literal}*/`

export function isGetFeedbackEnabled() {
  return process.env.ENABLE_GET_FEEDBACK
}

export function isFullStoryEnabled() {
  return process.env.ENABLE_FULLSTORY
}

export function isSearchServicesEnabled() {
  return process.env.ENABLE_SEARCH_SERVICES
}

export function isBenefitDistributionsEnabled() {
  return process.env.ENABLE_BENEFIT_DISTRIBUTIONS
}

export function isSearchPaymentEnabled() {
  return isSearchServicesEnabled() && process.env.ENABLE_SEARCH_PAYMENT
}

export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID

export const AroActionItems = {
  INITIATE_ROLLOVER: 'Initiate Rollover',
  CREATE_NEW_SERVICES_AGREEMENT: 'Create New Services Agreement',
  UPLOAD_SIGNED_AGREEMENT: 'Upload Signed Agreement',
  VIEW_FUNDING_INSTRUCTIONS: 'View Funding Instructions',
  DOWNLOAD_AGREEMENT: 'Download Agreement',
  RESEND_DOCUSIGN_EMAIL: 'Resend DocuSign email',
}
export const SearchActionItems = {
  CREATE_NEW_SEARCH_SERVICES_AGREEMENT: 'Create New Services Agreement',
  START_NEW_SEARCH: 'Start New Search',
  PROVIDE_PAYMENT_DETAILS: 'Payment Details',
}

export const WorkFlowTitles = {
  SEARCH_SERVICES: 'Start New Search',
  INITIATE_ROLLOVER: AroActionItems.INITIATE_ROLLOVER,
  CREATE_NEW_SERVICES_AGREEMENT:
    AroActionItems['CREATE_NEW_SERVICES_AGREEMENT'],
  CREATE_NEW_SEARCH_SERVICES_AGREEMENT: 'Create New Services Agreement',
}

export const ReasonForDiscard = {
  INVALID_DATA: 'Data Validation Failure',
  MISSING_AGREEMENT: 'Missing Services Agreement',
  TERMINATING_DEFINED_BENEFIT: 'Terminating Defined Benefit Plan',
  ACTIVE_BENEFIT_ABOVE_ALLOWABLE_THRESHOLD:
    'Active Plan with Benefit Above $7,000',
  PROHIBITED_COMPANY: 'Unable to perform search - contact your RM',
}

export const RolloverAccountStatus = {
  ROLLOVER_COMPLETE: 'Rollover Complete',
  READY_FOR_FUNDING: 'Ready for Funding',
  PROCESSING: 'Processing',
}
export const DistributionAccountStatus = {
  ROLLOVER_COMPLETE: 'Funding Received',
  READY_FOR_FUNDING: 'Ready for Funding',
  PROCESSING: 'Processing',
}

export const ServicesAgreementParticipantsTooltip = {
  [RolloverAccountStatus.ROLLOVER_COMPLETE]: `This participant's account has been opened and funded.`,
  [RolloverAccountStatus.READY_FOR_FUNDING]: `This participant's account is opened and awaiting funding.`,
  [RolloverAccountStatus.PROCESSING]: `This participant's information has been received, and our team is working to open the participant's account.`,
}

export const RolloverAccountType = {
  CUSTODY: 'Custody',
  IRA: 'IRA',
}

export const NewAgreementSuccessMessages = {
  ELECTRONIC_SIGNATURE:
    'Success! Your Plan Agreement is being generated and will be reviewed within 1-2 business days of being e-signed.',
  WET_SIGNATURE:
    'Success! Your Plan Agreement is being generated. You will receive a pre-populated PDF Agreement via email shortly.',
  ENTERPRISE_RECORD_KEEPER: `Your agreement was successfully submitted! Please note that the Recordkeeper you have chosen will submit the participant data directly to ${COMPANY_NAME.first} following your rollover distribution requests to the Recordkeeper. You should not submit any individual participant data in RSP for this agreement, thank you!`,
}

export const YOUTUBE_BASE_URL = 'https://www.youtube.com/watch?v='

export const MANUAL_ERISAPEDIA_OPTION = 'manual'

export const SEARCH_SERVICES_AGREEMENT_SUCCESS_MESSAGE =
  'Your Service Agreement is being generated. You will receive a copy via email, then you will be ready to submit Search Orders.'

export const SEARCH_ORDER_SUCCESS_MESSAGE =
  'We received your search order submission.'

export const SEARCH_ORDER_WITH_PAYMENT_SUCCESS_MESSAGE =
  'Success! We received your search order submission. Payment information has been saved to your account.'

export const SEARCH_ORDER_PROHIBITED_COMPANIES = [
  'AAmva',
  'Acxiom',
  'Advo',
  'AECSoft',
  'Allant Group',
  'Alliance Data Systems',
  'Alliance Group Holdings',
  'Allied Interstate',
  'American Business Lists',
  'American List Counsel',
  'Anchor Computer',
  'Araxid',
  'Carfax',
  'Carproof',
  'Cavalry',
  'CDK Global',
  'CollectionIndustry.com',
  'Convergent',
  'Corelogic',
  'Cortera',
  'Cox Automotive',
  'Credit Control',
  'Credit Reform',
  'Credit Report SA',
  'CreditRiskMonitor.com',
  'Creditsafe',
  'Database 101',
  'DealerTrack',
  'Dominion',
  'Dunn and Bradstreet',
  'Encore',
  'Epsilon',
  'Equifax',
  'Exostar',
  'Experian',
  'Factiva',
  'Fair Isaac',
  'First Advantage',
  'First Data',
  'GC Services',
  'Green Dot',
  'HarteHanks',
  'HIS',
  'ID Analytics',
  'Idology',
  'InfoGROUP',
  'Innovis',
  'Insurance Services Office',
  'Intellaegis',
  'IQOR',
  'Jigsaw Data',
  'KnowledgeBase Marketing',
  'Leadsource',
  'LexisNexis',
  'LSSI',
  'Media 1',
  'Merkle',
  'National Association of Credit Management',
  'National Recovery',
  'Neustar',
  'NMVTIS',
  'Northland',
  'Phoenix Financial',
  'Pioneer',
  'Polk City Directories',
  'Portfolio Recovery',
  'R.L. Polk',
  'Receivable Management Services',
  'Reynolds Company',
  'Reynolds & Reynolds',
  'Reynolds Company (Reynolds & Reynolds)',
  'Riskwise',
  'RNN',
  'RSA',
  'Rust Consulting',
  'Southwest Credit Systems',
  'Target Base',
  'Targusinfo',
  'Thomson Reuters',
  'TransUnion',
  'TransUnion Risk and Alternative Data Solutions',
  'Verid',
  'Verisk Analytics',
  'Volt Delta',
  'West Publishing Group',
]

export const CREDIT_CARD_OPTION = {
  key: 'Credit Card',
  value: 'Credit Card',
}

export const SAVED_PAYMENT_METHOD_SUCCESS_MESSAGE =
  'Success! Payment information has been saved in your profile.'

export const paymentTypeOptions = [CREDIT_CARD_OPTION]

export const E_SIGNATURE_CONFIRMATION_TEXT = `On behalf of myself and any party for which I am signing, I agree that by checking the box, I consent to utilizing electronic signatures for the Services Agreement and any related documents, and, on behalf of myself and any party for which I am signing, I consent and agree that any agreement, consent or disclosure with ${COMPANY_NAME.medium} for which I apply my name electrically, including by typing my name or applying my electronic signature, is intended to be my legal and valid signature and to bind any party for which I am signing to the terms of such agreement, consent or disclosure. I further confirm that I am authorized to sign for and on behalf of any party for which I am signing.`

export const LEGAL_DISCLAIMERS = {
  footer: `${COMPANY_NAME.full} and its affiliates perform the duties of a directed custodian and/or an administrator of consumer directed benefits and, as such, do not provide due diligence to third parties on prospective investments, platforms, sponsors, or service providers, and do not offer or sell investments or provide investment, tax, or legal advice.`,
  trademark: `${COMPANY_NAME.short} and ${COMPANY_NAME.long} are trademarks of ${COMPANY_NAME.full}.`,
}
