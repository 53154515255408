import React, { useState } from 'react'
import { Modal } from 'lp-components'
import { EditUserForm } from '../forms'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { flashSuccessMessage, flashErrorMessage } from 'redux-flash'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  TOAST_ERROR_CONTACT_SUPPORT,
  FLASH_SUCCESS_TIMEOUT,
  userRoleOptions,
  UserStatus,
} from 'config'
import { Icon as PencilIcon } from 'images/pencil-outline-green.svg'
import * as apiActions from 'api-actions'
import * as Types from 'types'
import { getSelectInitialValue } from 'utils'

const propTypes = {
  data: Types.companyUser.isRequired,
  flashSuccessMessageHandler: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
}

const defaultProps = {}

const userStatusValues = Object.values(UserStatus)
function EditUser({
  data: { contactID, firstName, lastName, status, role, phone, email },
  flashSuccessMessageHandler,
  flashErrorMessageHandler,
  editUser,
  onSubmitSuccess,
}) {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      <td className="company-user-actions">
        <button
          type="button"
          className="user-actions-btn"
          aria-label={`edit details for user ${contactID}`}
          onClick={() => {
            setOpenModal(true)
          }}
        >
          <PencilIcon aria-hidden="true" />
        </button>
      </td>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <div className="user-form">
            <h2>Edit User</h2>
            <hr />
            <EditUserForm
              initialValues={{
                user: {
                  firstName,
                  lastName,
                  role: getSelectInitialValue(role, userRoleOptions),
                  status: getSelectInitialValue(status, userStatusValues),
                  phone: phone || '',
                  email,
                },
              }}
              handleSubmit={async (values) => {
                const { firstName, lastName, role, phone, status } = values.user
                try {
                  await editUser(contactID, {
                    firstName,
                    lastName,
                    role,
                    phone,
                    status,
                  })
                  flashSuccessMessageHandler(
                    'User changes have successfully been saved.',
                    {
                      timeout: FLASH_SUCCESS_TIMEOUT,
                    }
                  )
                  setOpenModal(false)
                  onSubmitSuccess()
                } catch (e) {
                  flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
                }
              }}
              cancelHandler={() => setOpenModal(false)}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

EditUser.propTypes = exact(propTypes)
EditUser.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {}
}

const mapDispatchToProps = {
  flashSuccessMessageHandler: flashSuccessMessage,
  flashErrorMessageHandler: flashErrorMessage,
  editUser: apiActions.editUser,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditUser)
