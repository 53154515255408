import { isBefore, isAfter, isSameDay } from 'date-fns'
import { parseDateFromString } from 'utils'

// Determines if a given date is within range of the start/end date
function isDateInRange({ date, startOfDateRange, endOfDateRange }) {
  const parsedDate = parseDateFromString(date)
  const parsedStartOfDateRange = parseDateFromString(startOfDateRange)
  const parsedEndOfDateRange = parseDateFromString(endOfDateRange)

  return (
    isSameDay(parsedDate, parsedStartOfDateRange) ||
    isSameDay(parsedDate, parsedEndOfDateRange) ||
    (isAfter(parsedDate, parsedStartOfDateRange) &&
      isBefore(parsedDate, parsedEndOfDateRange))
  )
}

export default isDateInRange
