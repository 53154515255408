import React from 'react'
import exact from 'prop-types-exact'
import { SupportContactInformation } from 'config'
import { ExternalLink } from 'components'

const propTypes = {}
const defaultProps = {}

function SupportEmailLink() {
  return (
    <ExternalLink
      id="support-email"
      link={`mailto:${SupportContactInformation.email}`}
    >
      {SupportContactInformation.email}
    </ExternalLink>
  )
}

SupportEmailLink.propTypes = exact(propTypes)
SupportEmailLink.defaultProps = defaultProps

export default React.memo(SupportEmailLink)
