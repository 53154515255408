import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors, selectors as productSelectors } from '../reducer'
import { useHistory, Link } from 'react-router-dom'
import * as apiActions from 'api-actions'
import {
  NoTableResults,
  FullPageSpinner,
  ComponentFailure,
  ActionMenu,
} from 'components'
import { SearchOrdersTable } from '../components'
import { filter, includes, noop } from 'lodash'
import { isBefore, isAfter, isSameDay } from 'date-fns'
import { parseDateFromString } from 'utils'
import {
  Path,
  SearchActionItems,
  isSearchPaymentEnabled,
  AgreementType,
  AgreementStatus,
  TOAST_ERROR_CONTACT_SUPPORT,
} from 'config'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'
import { downloadSearchServiceAgreementFile } from 'api'
import { Icon as DownloadIcon } from 'images/download.svg'
import * as actions from '../actions'
import { flashErrorMessage } from 'redux-flash'

const INITIAL_FILTERS = {
  status: [],
  searchType: [],
  planFiduciary: [],
  startSubmittedDate: '',
  endSubmittedDate: '',
}

const propTypes = {
  fetchSearches: PropTypes.func.isRequired,
  searches: PropTypes.arrayOf(Types.searches),
  planFiduciaries: PropTypes.arrayOf(PropTypes.string),
  hasSearchAgreement: PropTypes.bool.isRequired,
  serviceAgreements: PropTypes.arrayOf(Types.searchServiceAgreement).isRequired,
  searchShouldTriggerAllServicesAgreementsFetch: PropTypes.bool.isRequired,
  setSearchShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
  fetchServicesAgreements: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  fetchSearchServices: PropTypes.func.isRequired,
  searchServices: PropTypes.arrayOf(Types.searchService),
  shouldTriggerSearchesFetch: PropTypes.bool.isRequired,
  setShouldTriggerSearchesFetch: PropTypes.func.isRequired,
}

const defaultProps = {
  searches: null,
  planFiduciaries: null,
  searchServices: null,
}

function SearchOrdersHeader({ children }) {
  return (
    <div className="header-bar">
      <div className="header-bar-content" id="search-title">
        <h1 className="page-title">Search Services</h1>
        {children}
      </div>
    </div>
  )
}

function TableComponentFailure() {
  return (
    <>
      <SearchOrdersHeader></SearchOrdersHeader>
      <ComponentFailure />
    </>
  )
}

function useServiceAgreementDownload(agreementId, serviceAgreementName) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const handleDownload = async () => {
    try {
      setIsLoading(true)
      await downloadSearchServiceAgreementFile(
        agreementId,
        serviceAgreementName
      )
    } catch (e) {
      setError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  return { download: agreementId ? handleDownload : noop, isLoading, error }
}

function SearchHistory({
  fetchSearches,
  searches,
  planFiduciaries,
  hasSearchAgreement,
  serviceAgreements,
  searchShouldTriggerAllServicesAgreementsFetch,
  setSearchShouldTriggerAllServicesAgreementsFetch,
  fetchServicesAgreements,
  flashErrorMessageHandler,
  searchServices,
  fetchSearchServices,
  shouldTriggerSearchesFetch,
  setShouldTriggerSearchesFetch,
}) {
  const [filters] = useState(INITIAL_FILTERS)
  const [error, setError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    window.appEventData.pop()
    var appEventData = window.appEventData || []
    appEventData.push({
      event: 'Page Load Completed',
    })
  }, [])

  const searchServiceAgreement = serviceAgreements.find(
    (agreement) =>
      agreement.agreementType === AgreementType.SEARCH &&
      agreement.agreementStatus === AgreementStatus.ACTIVE
  )

  const {
    download,
    isLoading: isDownloading,
    error: downloadError,
  } = useServiceAgreementDownload(
    searchServiceAgreement?.serviceAgreementsID,
    searchServiceAgreement?.name
  )

  const filteredSearches = useMemo(() => {
    if (!searches) return

    const filteredSearchResults = filter(searches, (searchOrder) => {
      const submittedOn = parseDateFromString(searchOrder.submittedOn)
      return (
        (filters.status.length
          ? includes(filters.status, searchOrder.status)
          : true) &&
        (filters.searchType.length
          ? includes(filters.searchType, searchOrder.searchTypeID)
          : true) &&
        (filters.planFiduciary.length
          ? includes(filters.planFiduciary, searchOrder.planFiduciary)
          : true) &&
        (filters.startSubmittedDate
          ? isAfter(submittedOn, filters.startSubmittedDate) ||
            isSameDay(submittedOn, filters.startSubmittedDate)
          : true) &&
        (filters.endSubmittedDate
          ? isBefore(submittedOn, filters.endSubmittedDate) ||
            isSameDay(submittedOn, filters.endSubmittedDate)
          : true)
      )
    })
    return filteredSearchResults
  }, [filters, searches])

  useEffect(() => {
    async function getSearches() {
      try {
        await fetchSearches()
      } catch {
        setError(true)
      }
    }
    if (shouldTriggerSearchesFetch) {
      getSearches()
      setShouldTriggerSearchesFetch(false)
    }
  }, [shouldTriggerSearchesFetch])

  useEffect(() => {
    async function getSearchServices() {
      try {
        await fetchSearchServices()
      } catch {
        setError(true)
      }
    }
    if (!searchServices) getSearchServices()
  }, [searchServices])

  useEffect(() => {
    async function getServicesAgreements() {
      try {
        await fetchServicesAgreements()
        setSearchShouldTriggerAllServicesAgreementsFetch(false)
      } catch {
        setError(true)
      }
    }

    if (searchShouldTriggerAllServicesAgreementsFetch) getServicesAgreements()
  }, [
    searchShouldTriggerAllServicesAgreementsFetch,
    fetchServicesAgreements,
    setError,
    setSearchShouldTriggerAllServicesAgreementsFetch,
  ])

  useEffect(() => {
    if (downloadError) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }, [downloadError, flashErrorMessageHandler])

  const isLoading = (!searches && !error) || shouldTriggerSearchesFetch

  if (isLoading) return <FullPageSpinner />
  if (error) return <TableComponentFailure />

  const hasSearchOrders = !!searches.length

  const actionItems = [
    !hasSearchAgreement && {
      handleSelect: () => history.push(Path.SERVICE_AGREEMENT_DETAILS),
      text: SearchActionItems.CREATE_NEW_SEARCH_SERVICES_AGREEMENT,
    },
    hasSearchAgreement && {
      handleSelect: () => history.push(Path.SEARCH_TYPE),
      text: SearchActionItems.START_NEW_SEARCH,
    },
    isSearchPaymentEnabled() && {
      handleSelect: () => history.push(Path.ADD_PAYMENT_DETAILS),
      text: SearchActionItems.PROVIDE_PAYMENT_DETAILS,
    },
  ].filter(Boolean)

  function getNoRowsFoundMessage(hasSearchAgreement, hasSearchOrders) {
    if (!hasSearchAgreement) {
      return 'It looks like you don’t have any search results yet. To get started please Create a New Services Agreement.'
    } else if (hasSearchOrders) {
      return 'It looks like you don’t have any search results within the selected filter(s). Try adjusting your filter to find what you’re looking for.'
    } else {
      return 'It looks like you don’t have any search results yet. To get started please select “Start New Search”.'
    }
  }

  return (
    <div className="content-wrapper search-history">
      <SearchOrdersHeader>
        <div className="search-orders-actions">
          {hasSearchAgreement && (
            <button
              type="button"
              onClick={isDownloading ? noop : download}
              className="button-text"
            >
              <DownloadIcon aria-hidden="true" />
              Download Agreement
            </button>
          )}
          <ActionMenu
            items={actionItems}
            ariaLabel="View search services action items"
          />
        </div>
      </SearchOrdersHeader>
      <SearchOrdersTable
        tableId="search-orders-table"
        searches={filteredSearches}
        planFiduciaries={planFiduciaries}
        searchServices={searchServices}
        noRowsFoundMessage={
          <div>
            <NoTableResults
              title="No results found."
              details={getNoRowsFoundMessage(
                hasSearchAgreement,
                hasSearchOrders
              )}
            >
              {!hasSearchAgreement && (
                <Link
                  to={Path.SERVICE_AGREEMENT_DETAILS}
                  className="button-primary"
                >
                  Create New Service Agreement
                </Link>
              )}
              {hasSearchAgreement && !hasSearchOrders && (
                <Link to={Path.SEARCH_TYPE} className="button-primary">
                  Start New Search
                </Link>
              )}
            </NoTableResults>
          </div>
        }
      />

      {isDownloading && <FullPageSpinner showBackground={true} />}
    </div>
  )
}

SearchHistory.propTypes = propTypes
SearchHistory.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    searches: selectors.searches(state),
    planFiduciaries: selectors.planFiduciaries(state),
    hasSearchAgreement: globalSelectors.hasSearchServiceAgreement(state),
    serviceAgreements: globalSelectors.serviceAgreements(state),
    searchShouldTriggerAllServicesAgreementsFetch:
      selectors.searchShouldTriggerAllServicesAgreementsFetch(state),
    searchServices: productSelectors.searchServices(state),
    shouldTriggerSearchesFetch: selectors.shouldTriggerSearchesFetch(state),
  }
}

const mapDispatchToProps = {
  fetchSearches: apiActions.fetchSearches,
  fetchServicesAgreements: apiActions.fetchServiceAgreements,
  setSearchShouldTriggerAllServicesAgreementsFetch:
    actions.setSearchShouldTriggerAllServicesAgreementsFetch,
  flashErrorMessageHandler: flashErrorMessage,
  fetchSearchServices: apiActions.fetchSearchServices,
  setShouldTriggerSearchesFetch: actions.setShouldTriggerSearchesFetch,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchHistory
)
