import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import {
  reducer as productsReducer,
  reducerKey as productsReducerKey,
} from './products'
import {
  reducer as newSearchFlowReducer,
  reducerKey as newSearchFlowReducerKey,
} from './new-search-flow'
import {
  reducer as companyReducer,
  reducerKey as companyReducerKey,
} from './company'
import {
  reducer as automaticRolloversReducer,
  reducerKey as automaticRolloversReducerKey,
} from './automatic-rollovers'
import {
  reducer as profileReducer,
  reducerKey as profileReducerKey,
} from './profile'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { createSelector } from 'reselect'
import * as actions from './actions'
import {
  RetirementServicesPortalProfile,
  AgreementStatus,
  ServiceAgreementMapping,
  ALWAYS_ENABLED_PRODUCTS,
  ORDERED_PRODUCTS,
  AVAILABLE_STATUSES,
  ProductType,
  isSearchServicesEnabled,
  isBenefitDistributionsEnabled,
  AgreementType,
} from 'config'
import { sortBy, difference } from 'lodash'

const reducerKey = 'root'
const slice = 'root.global'

const initialState = {
  isAuthenticated: false,
  supportModalShown: false,
  showIdleModal: false,
  successModalMessage: null,
}

const globalReducer = handleActions(
  {
    [actions.setSupportModalShown]: setState('supportModalShown'),
    [actions.setShowIdleModal]: setState('showIdleModal'),
    [actions.showSuccessModal]: setState('successModalMessage'),
    [actions.hideSuccessModal]: unsetState('successModalMessage'),
    [apiActions.fetchCompanyOwner]: setOnSuccess('companyOwner'),
    [apiActions.fetchUserProfile]: setOnSuccess(
      'currentUser',
      ({ payload: { data } }) => {
        return {
          ...data,
          permissions: {
            [data.accountAudience]: true,
          },
        }
      }
    ),
    [apiActions.fetchServiceAgreements]: setOnSuccess('serviceAgreements'),
  },
  initialState
)

const rootReducer = combineReducers({
  [productsReducerKey]: productsReducer,
  [newSearchFlowReducerKey]: newSearchFlowReducer,
  [companyReducerKey]: companyReducer,
  [automaticRolloversReducerKey]: automaticRolloversReducer,
  [profileReducerKey]: profileReducer,
  global: globalReducer,
})

const select = selectorForSlice(slice)

const selectors = {
  isAuthenticated: select('isAuthenticated'),
  companyOwner: select('companyOwner'),
  currentUser: select('currentUser'),
  supportModalShown: select('supportModalShown'),
  showIdleModal: select('showIdleModal'),
  serviceAgreements: select('serviceAgreements'),
  successModalMessage: select('successModalMessage'),
}

selectors.accountID = createSelector([selectors.currentUser], (currentUser) => {
  return currentUser?.accountID
})

selectors.permissions = createSelector(
  [selectors.currentUser],
  (currentUser) => {
    if (!currentUser) return {}
    return currentUser.permissions
  }
)

selectors.isAdmin = createSelector([selectors.currentUser], (currentUser) => {
  return (
    currentUser?.retirementServicesPortalProfile ===
    RetirementServicesPortalProfile.ADMIN
  )
})

// an array of (non-overlapping) products/services the user has subscribed to
// e.g., [ { type: 'Search Services', status: 'Active' } ]  -- plus optional attributes from service agreement
selectors.orderedSubscribedProducts = createSelector(
  [selectors.serviceAgreements],
  function (serviceAgreements) {
    if (!serviceAgreements) return

    // manually create an array of product objects for always enabled products
    const alwaysEnabledProducts = ALWAYS_ENABLED_PRODUCTS.map(
      (productType) => ({
        productType,
        agreementStatus: AgreementStatus.ACTIVE,
      })
    )
    const allServiceAgreements = alwaysEnabledProducts.concat(serviceAgreements)

    const products = allServiceAgreements.reduce(
      (products, serviceAgreement) => {
        const productType =
          serviceAgreement.productType ||
          ServiceAgreementMapping[serviceAgreement.agreementType]
        // it's possible for an agreement type to not have a matching product (e.g., Direct Rollover)
        if (!productType) return products
        if (
          productType === ProductType.SEARCH_SERVICES &&
          !isSearchServicesEnabled()
        )
          return products
        if (
          productType === ProductType.BENEFIT_DISTRIBUTIONS &&
          !isBenefitDistributionsEnabled()
        )
          return products
        // do not add the same product more than once:
        if (products.find((product) => product.type === productType))
          return products

        const product = {
          type: productType,
          status: serviceAgreement.agreementStatus,
          agreementId: serviceAgreement.serviceAgreementsID,
          signerName: serviceAgreement.customerSignedName,
        }
        return [...products, product]
      },
      []
    )

    return sortBy(products, (product) => ORDERED_PRODUCTS.indexOf(product.type))
  }
)

// a list of product types the user is not subscribed to (i.e., no service agreement)
// note: unlike orderedSubscribedProducts, this is an array of strings: e.g., [ "Uncashed Checks" ]
selectors.orderedNonSubscribedProductTypes = createSelector(
  [selectors.orderedSubscribedProducts],
  function (subscribedProducts) {
    return difference(
      ORDERED_PRODUCTS,
      subscribedProducts.map((product) => product.type)
    )
  }
)

// a user has access to a product if 1) there is a service agreement (= subscription) and
// 2) the agreement status is one of the following: 'Active', 'Accepted', 'Negative Consent', 'Signed'
selectors.hasAccessToProduct = createSelector(
  [selectors.orderedSubscribedProducts, (_, productType) => productType],
  (subscribedProducts, productType) => {
    if (!subscribedProducts) return

    const product = subscribedProducts.find(
      (product) => product.type === productType
    )
    if (!product) return false

    return AVAILABLE_STATUSES.includes(product.status)
  }
)

selectors.hasSearchServiceAgreement = createSelector(
  [selectors.serviceAgreements],
  (serviceAgreements) => {
    if (serviceAgreements) {
      return serviceAgreements.some(
        (agreement) =>
          agreement.agreementType === AgreementType.SEARCH &&
          agreement.agreementStatus === AgreementStatus.ACTIVE
      )
    }
    return false
  }
)

function reducer(state, action) {
  if (action.type === actions.resetState.toString()) {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

export { reducer, selectors, reducerKey }
