import { forIn } from 'lodash'

// Remove specified attributes from payload which could be an array (of objects) or an object-- including nested objects
function removeAttributes(payload, attributes) {
  if (payload instanceof Array) {
    return payload.map((el) => removeAttributes(el, attributes))
  }
  if (payload instanceof Object) {
    let newObject = {}
    forIn(payload, (val, key) => {
      if (!attributes.includes(key))
        newObject[key] = removeAttributes(val, attributes)
    })
    return newObject
  }
  return payload
}

export default removeAttributes
