import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { setOnSuccess } from 'lp-redux-api'

const reducerKey = 'profile'
const slice = 'root.profile'

const initialState = {
  shouldTriggerPaymentInfoFetch: true,
}

const reducer = handleActions(
  {
    [apiActions.saveCompletePaymentDetails]: setState(
      'completePaymentDetails',
      (action, _, form) => ({ ...form, ...action.payload })
    ),
    [apiActions.fetchPaymentMethod]: setOnSuccess('paymentMethod'),
    [apiActions.deletePaymentMethod]: setOnSuccess('paymentMethod', () => ({})),
    [actions.setShouldTriggerPaymentInfoFetch]: setState(
      'shouldTriggerPaymentInfoFetch'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  shouldTriggerPaymentInfoFetch: select('shouldTriggerPaymentInfoFetch'),
  completePaymentDetails: select('completePaymentDetails'),
  paymentMethod: select('paymentMethod'),
}

export { reducer, selectors, reducerKey }
