import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, useFormikContext } from 'formik'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

/*
 * This relies on each input being wrapped in a container with the class of
 * "error" applied when the field is invalid. This markup is added automatically
 * by all lp-component inputs. In order for this to work for custom inputs, the
 * class must manually be added _or_ the custom input can be wrapped in
 * LabeledField from lp-components, which will handle this logic automatically.
 *
 * Unfortunately, we have to rely on this markup for several reasons:
 * - The order of an object's keys are not guaranteed in JavaScript
 * - The order of the errors object keys from formik is based on the Yup schema
 * - The order of the touched object keys from formik is based on initial values
 *
 * Alternatively, we could query for all inputs using querySelectorAll (which
 * guarantees order) and find the earliest match with an id that exists in the
 * errors object. This would require additional effort however to flatten
 * the errors object to account for both nested _and_ array input ids (e.g.,
 * user.firstName or users[2].firstName). This approach would require
 * consistency in how we reference nested names (i.e., brackets or dot notation),
 * which ultimately is no better than requiring consistency in adding a class
 * name on the wrapping component. With the latter approach, the user will at
 * least get visual feedback that something is missing (e.g., no red border) and
 * may be prompted to add the class without even knowing that it is required
 * to support this scroll functionality.
 */
function useScrollToError() {
  const formRef = useRef()
  const {
    submitCount,
    isValid,
    isSubmitting,
    isValidating,
  } = useFormikContext()

  useEffect(() => {
    if (isValid || isValidating) return

    const container = formRef.current.querySelector('.error')

    if (container) {
      const element = container.querySelector('input,select,textarea')

      container.scrollIntoView({ behavior: 'smooth' })
      if (element) element.focus({ preventScroll: true })
    } else {
      // If for whatever reason no input has the expected wrapper, bail out
      // and just scroll to the top of the form for the user to review
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isSubmitting, submitCount])

  return formRef
}

function ScrollErrorForm({ children, ...rest }) {
  const ref = useScrollToError()
  return (
    <Form ref={ref} {...rest}>
      {children}
    </Form>
  )
}

ScrollErrorForm.propTypes = propTypes
ScrollErrorForm.defaultProps = defaultProps

export default React.memo(ScrollErrorForm)
