import React from 'react'
import { COMPANY_NAME } from 'config'

function SearchServicesLanguageOfAgreement() {
  return (
    <>
      <p>
        This Participant Search Services Agreement (“Agreement”) is between{' '}
        {COMPANY_NAME.full}, an Illinois limited liability company (“
        {COMPANY_NAME.medium}”), and the undersigned, in its capacity as (i)
        plan fiduciary (“Plan Fiduciary”) which is the Plan Sponsor or the Plan
        Administrator (as that term is defined in Section 3(16) of the Employee
        Retirement Income Security Act of 1974, as amended (“ERISA”), or similar
        state law in the case of a plan not subject to ERISA), or (ii)
        recordkeeper or third party administrator (“Plan Service Provider”) in
        its capacity as authorized agent of a Plan Fiduciary and, in the case of
        both (i) and (ii) on behalf of a retirement plan (“Plan”). Each of Plan
        Fiduciary and Plan Service Provider may be referred to as “Plan Agent”.
        By executing this Agreement, Plan Agent agrees to the terms and
        conditions herein on its behalf and on behalf of each Plan for which it
        requests Search Services (as defined below) pursuant to this Agreement.
        Each of {COMPANY_NAME.medium} and the Plan Agent may be referred to as a
        party or collectively, the parties.
      </p>
      <p>
        All references in this Agreement to “we,” “us” and “our” refer to{' '}
        {COMPANY_NAME.medium}, and all references in this Agreement to “you” or
        “your” refer to the Plan Agent signing this Agreement for itself and on
        behalf of a Plan. The term Plan refers to each Plan for which Plan Agent
        acts as Plan Fiduciary or Plan Service Provider and for which services
        are performed pursuant to this Agreement.
      </p>
      <p>
        <b>
          1. Purpose / Search Services / Restricted Data License Grant /
          Restrictions on Use.
        </b>{' '}
        You have selected {COMPANY_NAME.medium} and we have agreed to provide
        services to the Plan related to searching for Plan participants
        (“Participants”), within the continental United States, Alaska, Hawaii
        and the United States territories (“Search Services” or Services”). For
        clarity, the definition of “Services” includes all data delivered to
        Plan Agent in connection with this Agreement (including but not limited
        to data that is Third-Party Data (each as defined below)). We use one or
        more third-party data sources and compilers (“Data Vendor”) to provide
        Search Services, and we reserve the right to change, from time to time,
        the Data Vendor(s) we use to provide such Services. You understand that
        the information provided through the Services is provided by Data Vendor
        and includes data from third-party data providers, including but not
        limited to government and commercial data sources (“Third-Party Data”).
        Our ability to license Third-Party Data provided by Data Vendor is
        subject to the restricted license granted to us, and other restrictions
        required of us, by such Data Vendor, which must be agreed to by Plan
        Agent on its behalf and on behalf of any Plan to which Plan Agent
        provides such Third-Party Data. {COMPANY_NAME.medium} grants to Plan
        Agent a non-exclusive, nontransferable, restricted license to use the
        Services and any information provided through the Services, solely for
        Plan’s own internal business purposes subject to applicable Flow Down
        Obligations (defined below in Section 6) and Restrictions on Use (as
        defined below) pursuant to this Agreement (“Restricted Data License
        Grant”).
      </p>
      <p>
        If you are a Plan Service Provider, you may disclose information
        provided through the Services to the Plan, provided you require the
        applicable Plan complies with the Restricted Data License Grant and uses
        the information provided through the Services solely for Plan’s internal
        business purposes. The Plan Agent shall be responsible and liable for
        each applicable Plan’s compliance with this Agreement and use of the
        information disclosed through the Services, including but not limited
        to, compliance with the Restricted Data License Grant.
      </p>
      <p>
        You shall have conducted background checks on all of your employees, and
        shall have required each respective Plan Agent to have conducted
        background checks on all of its employees, in each case, who access the
        Services on behalf of a Plan.
      </p>
      <p>
        You order Services from {COMPANY_NAME.medium} by submitting an Order
        Form under this Agreement. Services available pursuant to this Agreement
        include, without limitation, address retrieval service and /or any other
        Search Service available, described and selected by you on an Order
        Form, from time to time. Each Order Form is hereby incorporated by
        reference into this Agreement and all references to this Agreement shall
        be deemed to include all Order Forms.
      </p>
      <p>
        The availability of Third-Party Data may change from time to time and we
        reserve the right at any time to modify or discontinue, temporarily or
        permanently, the Search Services (or any part thereof), and terminate
        the Restricted Data License Grant, with or without notice.
      </p>
      <p>
        You hereby grant us permission to process or disclose Confidential
        Information of the Plan (as defined below) to our affiliates and vendors
        to the extent necessary to process such data or to perform or improve
        the Services; provided that we shall be liable for any breaches of such
        Confidential Information by such affiliates or vendors as if such
        affiliates and vendors were subject to this Agreement.
      </p>
      <p>
        You hereby grant us a license to use Confidential Information of the
        Plan for internal, statistical, and analytical purposes.
      </p>
      <p>
        You hereby grant us a worldwide, perpetual, irrevocable, royalty free
        license to use and incorporate into the Services any suggestion,
        enhancement request, recommendation, correction or other feedback you or
        your users provide relating to the Services. We shall not be required to
        compensate you for any such suggestion, enhancement request,
        recommendation, correction or other feedback.
      </p>
      <p>
        You acknowledge and agree that there are certain entities and/or persons
        for which we are unable to provide Services. Should an Order Form be
        submitted for an entity that we determine, in our sole discretion, we
        are unable to provide Services for, you shall be notified that the Order
        Form will not be processed.
      </p>
      <p>You represent and warrant that (“Restrictions on Use”):</p>
      <p>
        i. You have the right to disclose the data disclosed by you to{' '}
        {COMPANY_NAME.medium} in connection with the Services, and you have the
        right to receive the data disclosed by {COMPANY_NAME.medium} to you
        pursuant to this Agreement.
      </p>
      <p>
        ii. All of your and Plan’s use of the Services shall be for only
        legitimate business purposes relating to the Plan’s business and as
        otherwise governed by this Agreement.
      </p>
      <p>
        iii. You and the Plan shall not use the Services for marketing purposes
        or resell or broker the Services to any third party and shall not use,
        and shall prevent your and its users from using, the Services for
        personal (non-business) purposes.
      </p>
      <p>
        iv. You and the Plan shall not use the Services to provide data
        processing services to third-parties or evaluate the data of or for
        third parties, without {COMPANY_NAME.medium}’s prior written approval.
      </p>
      <p>
        v. You and the Plan shall not access the Services from Internet Protocol
        addresses located outside of the United States and its territories
        without {COMPANY_NAME.medium}’s prior written approval.
      </p>
      <p>
        vi. You and the Plan shall not use the Services to create a competing
        product.
      </p>
      <p>
        vii. You and the Plan shall not use the data you or it receives under
        this Agreement for a Prohibited Medical Segment Use, and you and the
        Plan shall not directly or indirectly resell the data received under
        this Agreement to any Person for a Prohibited Medical Segment Use. For
        purposes of this subsection vii.: “Person” means an individual,
        corporation, partnership, joint venture, limited liability company,
        governmental authority, unincorporated organization, trust, association
        or other entity; and “Prohibited Medical Segment Use” means (A) the use
        of the data by a biopharma, life sciences or biotech firm (“Bio Firms”)
        to perform or provide research, data, analysis or related support for
        the development, commercialization, marketing, approval or reimbursement
        of pharmaceutical and medical devices and (B) the direct or indirect
        sale by data aggregators of research datasets to Bio Firms which will be
        used to perform research, analysis or related support for the
        development, commercialization, marketing, approval or reimbursement of
        pharmaceutical and medical devices. The restrictions in this paragraph
        shall survive termination of this Agreement.
      </p>

      <p>
        <b>2. Your Responsibilities.</b> You will direct us to search for
        Participants on behalf of a Plan. You will make any such direction
        through an individual authorized to act for the Plan Agent. The
        direction will include:
      </p>
      <p>
        (a) the selection of Search Service(s) you are requesting on the Order
        Form and certification regarding your and the Plan’s permissible use of
        the Services for legitimate fraud prevention or legitimate Plan purposes
        only, relating to the Plan, and as otherwise governed by applicable law,
        governmental rule, regulation or fiduciary duty for such purposes; and{' '}
      </p>
      <p>
        (b) information requested by us necessary to conduct the selected
        search(es) requested for each Participant, which may include, without
        limitation, the Participant’s (i) full name, (ii) full date of birth,
        (iii) current address on the books and records of the Plan, (iv)
        complete United States Social Security Number, and (v) any information
        reasonably requested by us to assist us in conducting the Search
        Service(s) requested, provided that such information is available to
        you. (“Participant Information”).{' '}
      </p>
      <p>
        You will upload the Participant Information to us through our online
        portal, by electronic data file as provided in Section 8 of this
        Agreement. You specifically acknowledge that we use third-party Data
        Vendors to provide Search Services and by using the Services agree and
        consent to the sharing of Participant Information with Data Vendor in
        order to enable Data Vendor activities related to the provision of
        Services. By uploading Participant Information into our online portal,
        or providing it to us in any other manner we may specify, you represent
        and warrant that you have obtained all required consents, approvals,
        permits and authorizations required for engagement of{' '}
        {COMPANY_NAME.medium} to perform the Services on behalf of a Plan, to
        enter into this Agreement on your behalf and on behalf of the Plan, and
        to share a Plan’s Participant Information with us and Data Vendor from
        time to time.{' '}
      </p>
      <p>
        We may perform reasonable audits of Plan Agent’s compliance with this
        Agreement by providing prior written notice, and Plan Agent shall
        reasonably cooperate with such audit. Such audits shall occur no more
        than once per year, unless otherwise more frequently required pursuant
        to a contract between us and a data provider or as required by a
        regulator or government entity with jurisdiction over us or the data in
        the Services.
      </p>

      <p>
        <b>3. Our Responsibilities.</b> Upon receipt of your Order Form and
        Participant Information, which serve as evidence of your direction to us
        to perform Search Service(s), we will conduct the requested Search
        Service(s) for each identified Participant based upon the Participant
        Information provided. We have no obligation to verify the accuracy of
        the Participant Information provided by you. We will search for each
        Participant across multiple databases through the Data Vendor(s) and
        report the results of each search we conduct (“Report”). While the Order
        Form generally outlines the length of time a search may take, the time
        frame in which you receive Report(s) depends on numerous factors,
        including, but not limited to, the size of the electronic data file
        submitted and the number of Participants included, and no specific
        turnaround time for a search is warranted or guaranteed. Any Report
        provided by us is for Plan use exclusively and may not be shared or
        disseminated outside of the Plan or otherwise used for the benefit of a
        third party. Reports will not be updated once delivered to you.
      </p>
      <p>
        We will not store or maintain your electronic data file(s), Participant
        Information or Reports (collectively, “Plan Data”) on your behalf once
        Reports are available to you via our online portal, and any Plan Data
        submitted to us related to a specific search may be deleted 30 days
        after the relevant Report is available. Plan Data cannot be recovered
        once deleted from the Services. You acknowledge that you are solely
        responsible for the maintenance of Plan Data and Report(s) after
        deletion from the Services, and we shall not be liable for any loss or
        destruction of Plan Data as provided herein.
      </p>
      <p>
        You agree that {COMPANY_NAME.medium} is performing only ministerial
        services at your direction on behalf of you and the Plans under this
        Agreement. You represent that the ministerial services to be rendered by{' '}
        {COMPANY_NAME.medium} under this Agreement are consistent with the
        procedures and terms of the Plans. Accordingly, you, on behalf of
        yourself and the Plans, further agree that {COMPANY_NAME.medium} is not
        intended to be a fiduciary, nor is it intended that{' '}
        {COMPANY_NAME.medium} have the requisite authority or control, to
        perform any services or functions which could cause{' '}
        {COMPANY_NAME.medium} to become a fiduciary of any retirement plan, as
        defined in ERISA or any other applicable law. Furthermore, you, on
        behalf of yourself and the Plans, agree that {COMPANY_NAME.medium} shall
        not be obligated to perform any services or to take any actions which,
        in {COMPANY_NAME.medium}’s reasonable judgment, would cause{' '}
        {COMPANY_NAME.medium} to be treated as a fiduciary under ERISA or any
        other applicable law, nor shall you request that {COMPANY_NAME.medium}{' '}
        perform such actions.
      </p>
      <p>
        You and the Plan shall comply with, and cause your and its employees who
        access the Services to comply with, laws and regulations applicable to
        the Services in all respects. Each party is solely responsible for its
        own compliance obligations. {COMPANY_NAME.medium} expressly disclaims
        that the Services are, and no Services shall be deemed to be regulatory,
        compliance, legal, retirement fund/plan, ERISA or tax advice, counsel or
        opinion.
      </p>

      <p>
        <b>4. Fees.</b> You agree to pay us fees for Services performed pursuant
        to the fees reflected on Exhibit A hereto. Fees shall be invoiced or, at
        our option, paid online via credit card at the time Services are
        ordered. Invoices are due no later than 30 days after the date of the
        invoice. We may amend the Service fees from time to time by providing
        notice to you. You agree that your continued use of all or part of the
        Services will be subject to your payment of any applicable fees.
      </p>

      <p>
        <b>5. Representations and Warranties.</b>
        <br />
        (a) You hereby represent and warrant, for yourself and on behalf of the
        Plan, as follows:
        <br />
        &nbsp;&nbsp;(i) This Agreement has been duly authorized, executed and
        delivered by you and constitutes a valid and binding agreement on behalf
        you and of each Plan.
        <br />
        &nbsp;&nbsp;(ii) You have full power and authority from the Plan to
        enter into this Agreement and share the Plan’s Participant Information
        with us and any Data Vendor and to receive Report(s).
        <br />
        &nbsp;&nbsp;(iii) Participant Information provided is the most recent
        information available to you and the Plan.
        <br />
        &nbsp;&nbsp;(iv) All requests for and use of Search Services will be for
        legitimate fraud prevention or legitimate Plan purposes only, relating
        to the Plan, and as otherwise governed by applicable law, governmental
        rule, regulation or fiduciary duty for such purposes.
        <br />
        &nbsp;&nbsp;(v) You and the Plan will comply with all laws, regulations
        and rules which govern the use of Search Services, and the Reports and
        information provided through the Services.
        <br />
        &nbsp;&nbsp;(vi) You and the Plan have systems, facilities and
        procedures in place to safeguard the confidentiality, security and
        appropriate use of Reports and information provided through the
        Services.
        <br />
        &nbsp;&nbsp;(vii) You will be solely responsible for your and the Plan’s
        access to, transmission, storage and use of all Plan Data.
        <br />
        &nbsp;&nbsp;(viii) You have relied on your own legal counsel and/or
        other tax/employee benefit professionals for advice in taking actions
        under the Plan, taking actions to search for Participants and in
        executing this Agreement. You have independently concluded that the
        arrangement for Services described in this Agreement satisfies
        applicable laws and you have not relied on us for any such advice, and
        we have not provided any recommendation or legal advice to you in
        connection with the Services provided pursuant to this Agreement.
      </p>
      <p>
        (b) We represent and warrant as follows:
        <br />
        &nbsp;&nbsp;(i) This Agreement has been duly authorized, executed and
        delivered by us and constitutes our valid and binding agreement.
        <br />
        &nbsp;&nbsp;(ii) We will comply with all laws, regulations and rules
        which govern our provision of Search Services and the Reports and
        information provided through the Services.
        <br />
        &nbsp;&nbsp;(iii) We have authority from each relevant Data Vendor to
        provide the Search Services and Reports generated thereunder to you.
        <br />
      </p>

      <p>
        <b>6. Data Vendor Obligations.</b>
      </p>
      <p>
        (a) Flow Down Requirements: You understand that {COMPANY_NAME.medium}{' '}
        obtains Third-Party Data from Data Vendors that requires{' '}
        {COMPANY_NAME.medium} to flow-down to the Plan Agents and the Plan
        certain obligations, which are available from the Data Vendor at
        https://www.pbinfo.com/third-party-data-terms/ and
        https://www.pbinfo.com/dmf-data-terms, as each may be amended from time
        to time (the “Flow Down Obligations”). The Flow Down Obligations (as
        amended from time to time) are hereby incorporated by reference into
        this Agreement to the extent applicable to the Services.
        <br />
        (b) Plan Credentials: You acknowledge and understand that we will only
        allow access to the Services if your credentials can be verified
        according to our credentialing procedures. You represent and warrant
        that the information provided (i) on an Order Form or (ii) during the
        credentialing process or in connection with the purchase of Services
        under an Order Form (“Credentialing”) is accurate and complete in all
        material respects. {COMPANY_NAME.medium} may be required to disclose
        Plan’s and Plan Agent’s information related to Credentialing to any Data
        Vendor, and you hereby consent to such disclosure.
        <br />
        Plan Agent shall notify {COMPANY_NAME.medium} immediately of any changes
        to the information on an Order Form or provided in connection with
        Credentialing, and {COMPANY_NAME.medium} may terminate this Agreement if
        such changes are material as reasonably determined by{' '}
        {COMPANY_NAME.medium}. Plan Agent is required to promptly notify{' '}
        {COMPANY_NAME.medium} of a change in ownership of its or Plan’s company,
        any change in the name of its or Plan’s company, and/or any change in
        the physical address of its or Plan’s company. <br />
        (c) Permissible Use / Certifications: Search Services may use and
        Reports may contain or display non-public personal information,
        personally identifiable information, or protected health information
        including that as defined in and regulated by the Gramm-Leach-Bliley Act
        (15 U. S.C. § 6801, et seq.) and related state laws (collectively, the
        “GLBA”), or any other applicable privacy law or regulation (collectively
        “NPPI”), that is governed by applicable federal and state laws and
        regulations (collectively, “Data Laws”). You certify that Plan has
        permissible purposes with respect to NPPI, under the Data Laws, to use
        and/or obtain such information. <br />
        You acknowledge and agree that you may be required to certify
        permissible use of NPPI data when Services begin and agree to recertify,
        in writing, your permissible uses upon request.
        <br />
        By receipt of the Services, you and Plan agree to, and shall comply
        with, changes to the Flow Down Obligations, as posted on the websites
        set forth in Section 6a or as otherwise disclosed to you from time to
        time, changes in pricing and changes to other provisions of this
        Agreement as Data Vendor or we shall make from time to time
        (collectively “Updates”) upon notice to you via e-mail, by posting on
        our online portal, through the Services, online “click wrap” amendments,
        facsimile, mail, invoice announcements, or other written notification.
      </p>

      <p>
        <b>7. Confidentiality.</b> Each party, including, as applicable, the
        Plan (the ”Receiving Party”) understands that the other party (the
        ”Disclosing Party”) has disclosed or may disclose non-public or
        proprietary information relating to this Agreement, the Plan or the
        Search Services provided pursuant to this Agreement, and includes
        without limitation, the electronic data file, Participant Information
        and Reports generated by the Service, all which may contain NPPI
        (collectively referred to as ”Confidential Information”). The Receiving
        Party agrees: (i) to take reasonable precautions to protect such
        Confidential Information; and (ii) not to use or divulge to any third
        person any such Confidential Information, without the prior written
        permission of the Disclosing Party, or as otherwise provided herein or
        by law, except for contractors or agents as required to carry out their
        responsibilities with regard to Search Services provided under this
        Agreement and who have agreed in writing to terms protecting such
        Confidential Information. The parties specifically agree that they will
        not use any NPPI about the Disclosing Party’s agents, customers,
        clients, participants and/or the authorized agents of such customers,
        clients and participants in any manner prohibited by Data Laws, and
        agree to comply with all provisions of Data Laws applicable to Receiving
        Party in the performance of its obligations under this Agreement.
      </p>
      <p>
        Confidential Information does not include information that (a) is or
        becomes generally available to the public; (b) was in its possession or
        known prior to receipt; (c) was rightfully disclosed to it by a third
        party; (d) was independently developed without use of or reference to
        any Confidential Information of the Disclosing Party; or (e) is required
        to be disclosed by law, court order or subpoena, provided that where not
        prohibited by applicable law or court order, the Receiving Party shall
        give immediate notice of such requested disclosure to the Disclosing
        Party and afford the Disclosing Party the opportunity, at its own
        expense, to challenge such subpoena or court order and/or to seek an
        appropriate protective order. You authorize us to release all records
        and information regarding Plan use of Search Services upon receipt of
        any request, audit or exam by the Department of Labor (“DOL”), without
        the need for additional authorization from the relevant Plan or a
        subpoena or court order from the DOL. We will notify you of any DOL
        request for information or documents regarding the Plan’s use of Search
        Services prior to complying with any such request.{' '}
      </p>
      <p>
        The parties agree to keep each other’s Confidential Information
        confidential after this Agreement ends for as long as the Confidential
        Information remains as such. If the Confidential Information is a trade
        secret or other intellectual property, the parties agree to protect it
        as long as it remains legally protected intellectual property. If the
        Confidential Information is NPPI, as defined in applicable laws and
        regulations, the parties agree that their confidentiality obligations
        will not expire.
      </p>
      <p>
        Each party shall (a) implement and maintain appropriate and commercially
        reasonable safeguards to protect against the unauthorized access to or
        use of the Disclosing Party’s Confidential Information (a “Security
        Breach”); (b) promptly, in no event later than 48 hours, notify the
        Disclosing Party of any Security Breach; and (c) take all appropriate
        action to remedy the Security Breach. {COMPANY_NAME.medium} has
        implemented and will maintain an information security program that
        includes security measures it deems appropriate, including, without
        limitation, technical, physical, administrative and organizational
        controls, designed to maintain the confidentiality, security and
        integrity of confidential information, including Participant
        Information, and that are designed to be materially consistent with the
        cybersecurity recommendations released by the DOL on April 14, 2021, as
        may be amended or updated from time to time.
      </p>
      <p>
        Each party acknowledges that the information entered into and/or
        accessed through the Services may include NPPI and it is each party’s
        obligation to keep all such information confidential and secure.
        Accordingly, each party (as applicable) shall (i) restrict access to
        Services and the information contained therein to those employees who
        have a need to know as part of their official duties; and (ii) with
        respect to Plan Agent, unless otherwise required by law or pursuant to a
        party’s policies, purge all bulk information received through the
        Services, whether stored electronically or on hard copy by a party,
        within ninety (90) days of initial receipt in order to prevent such bulk
        data from being made into a competing product with the Services. Each
        party will implement policies and procedures to prevent unauthorized use
        of its user IDs (where such user IDs permit access to the other party’s
        systems or applications or data (as applicable)) (“User IDs”), in
        connection with the Services. The designated administrator of Plan
        Agent’s account with {COMPANY_NAME.medium} shall be responsible for
        appropriate use, access and protection of User IDs and will notify{' '}
        {COMPANY_NAME.medium} promptly whenever a user ID is no longer
        authorized for use and access to {COMPANY_NAME.medium}’s systems,
        applications or data should be disabled. Each party will promptly notify
        the other, in writing if such party reasonably suspects or confirms that
        one of its User IDs has been (1) lost, stolen, compromised, misused or
        used, accessed, (2) acquired in an unauthorized manner or by any
        unauthorized person, or (3) used for any purpose other than legitimate
        business reasons. Subject to Section 12, each party shall remain liable
        for all costs associated therewith and shall further reimburse the other
        party for any expenses it incurs due to such party’s failure to prevent
        such impermissible use or access of User IDs and/or the Services or the
        other Party’s data, or any actions required as a result thereof.
      </p>

      <p>
        <b>8. Computerized Data Requirements.</b> You will provide us with
        electronic files identifying Participants for whom Search Services are
        requested, including Participant Information, in a format agreed to by
        us. The upload of the electronic files will serve as evidence of your
        direction to perform Search Services for each Participant and that you
        have obtained all required consents, approvals, permits and
        authorizations required for engagement of {COMPANY_NAME.medium} to
        perform the Services on behalf of a Plan, and to share a Plan’s
        Participant Information with us and Data Vendor from time to time. Each
        party will use reasonable practices to avoid introducing any viruses or
        other malware into the other’s systems by such electronic files. It is
        the responsibility of each party to encrypt such electronic files to the
        extent and in a manner necessary to protect the confidentiality of the
        information contained in such files.
      </p>

      <p>
        <b>9. Authorized Parties.</b> In addition to the directions provided
        pursuant to Section 8 of this Agreement, you may direct us to act upon
        directions, whether written or oral, by telephone, mail or email, and we
        may rely upon the direction of any individual whom we reasonably believe
        is authorized to act on your behalf.{' '}
      </p>

      <p>
        <b>
          10. Disclaimer of Warranties. All Search Services are provided “AS
          IS”. You acknowledge and agree that Data Vendors obtain data from
          selected public records and third-party sources, which may or may not
          be completely thorough and accurate, and that you shall not rely on
          the accuracy or completeness of information supplied through the
          Search Services. Due to the nature of public record information, the
          public records and commercially available data sources used in the
          Search Services may contain errors. Data Vendor source data is
          sometimes reported or entered inaccurately, processed poorly or
          incorrectly, and is generally not free from defect. The Search
          Services are not the source of data, nor are they a comprehensive
          compilation of the data. BEFORE RELYING ON ANY DATA CONTAINED IN A
          REPORT, IT SHOULD BE INDEPENDENTLY VERIFIED AND WE MAKE NO, AND HEREBY
          DISCLAIM, ANY WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO THE SEARCH
          SERVICES. WE DO NOT WARRANT THE CORRECTNESS, COMPLETENESS,
          MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF THE SEARCH
          SERVICES OR REPORTS AND INFORMATION PROVIDED THEREIN.
        </b>
      </p>

      <p>
        <b>11. Indemnification.</b> Notwithstanding Section 12, you will
        indemnify and hold us harmless from any and all liability, claims,
        damages, costs or expenses (including reasonable attorneys’ fees)
        (collectively “Damages”) arising from or claimed to have arisen from (a)
        your or Plan’s use of the Reports and other information or data provided
        to you through the Search Services, (b) your or Plan’s material breach
        of this Agreement, including without limitation, any representation,
        warranty or certification made in this Agreement or an applicable Order
        Form, (c) your or Plan’s breach of your or its confidentiality and
        information security obligations and specifically any Security Breach,
        (d) inaccurate Participant Information provided by you or your
        authorized agent; or (e) your or Plan’s gross negligence, bad faith or
        willful misconduct.{' '}
      </p>
      <p>
        We will indemnify and hold you harmless from any and all third-party
        Damages arising from or claimed to have arisen from (a) our material
        breach of this Agreement, including any representation or warranty made
        by us in this Agreement, except Damages arising from your negligence,
        bad faith or willful misconduct; or (b) our gross negligence, bad faith
        or willful misconduct.
      </p>

      <p>
        <b>12. Limitation of Liability.</b> In no event shall the terms of the
        Plan or this Agreement, either expressly or by implication, be deemed to
        impose upon us any power or responsibility other than those set forth
        specifically in this Agreement.{' '}
      </p>
      <p>
        Under no circumstances and in no event shall {COMPANY_NAME.medium} be
        liable to you or Plan (or to any third party claiming through you) for
        any Damages arising out of or caused in whole or in part by use of the
        Search Services{' '}
        <b>
          and you agree to release and hold us harmless from Damages you may
          incur as a result of the use of the Services or inaccuracy of any
          information contained therein.
        </b>{' '}
        Notwithstanding the foregoing, if liability can be imposed, you agree
        that our aggregate liability for any and all Damages, regardless of the
        cause of the Damages, shall not exceed the amount of fees paid by you
        under this Agreement in the twelve months preceding the event that gave
        rise to the Damages, even if you were advised of the possibility of such
        Damages. IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER FOR ANY
        INDIRECT, PUNITIVE, SPECIAL INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
        NATURE WHATSOEVER.
      </p>
      <p>
        YOU ACKNOWLEDGE THAT THE DISCLAIMERS AND LIMITATIONS IN SECTIONS 10 AND
        12 OF THIS AGREEMENT ARE AN ESSENTIAL PART OF THIS AGREEMENT, INCLUDING
        THE ALLOCATION OF RISKS THEREIN, AND ARE THE BASIS FOR ENABLING US TO
        OFFER THE SERVICES TO YOU FOR THE FEES SPECIFIED.
      </p>
      <p>
        The terms of these limitations on liability will survive the termination
        of this Agreement.
      </p>

      <p>
        <b>13. Arbitration.</b> Any dispute, claim or controversy arising out of
        or relating to this Agreement, or the breach, termination, enforcement,
        interpretation or validity thereof, including any challenge to the
        making of this Agreement or the determination of the scope or
        applicability of this Agreement to arbitrate, will be determined by
        arbitration in Chicago, Illinois, to the exclusion of any other venue or
        forum, before a sole arbitrator, in accordance with the laws of the
        State of Illinois. The arbitration will be administered by Judicial
        Arbitration and Mediation Services (“JAMS”) under its Comprehensive
        Arbitration Rules and Procedures (“JAMS Rules”) and will be conducted by
        a retired judge who is experienced in dispute resolution. No
        consequential, indirect, special, or punitive damages will be awarded.
        Notwithstanding any other rules to the contrary, no arbitration
        proceeding brought against us will be consolidated with any other
        arbitration proceeding without our consent. Judgment may be entered upon
        any award granted in any arbitration in any court of competent
        jurisdiction in Chicago, Illinois, or in any other court having
        jurisdiction. Each party shall pay its own costs, fees, and expenses
        (including legal fees); provided, however, that each shall pay one-half
        of all fees paid to JAMS and the arbitrator. You agree that you and a
        Plan may bring claims and disputes to arbitration only in your
        individual capacity or for a Plan, and not as a plaintiff or class
        member in any purported class or representative arbitration. The parties
        specifically agree and acknowledge that the JAMS Consumer Arbitration
        Minimum Standards do not and shall not apply to any arbitration that
        arises. This includes, but is not limited to, any provisions of the JAMS
        Consumer Arbitration Minimum Standards that allocate the costs and fees
        associated with the arbitration, that set the venue for the arbitration,
        or any other provision of those Standards that conflicts with the terms
        of this Agreement.
      </p>

      <p>
        <b>14. Term.</b> This Agreement will remain in full force and effect
        until terminated by either party at any time upon thirty (30) days’
        written notice.{' '}
      </p>
      <p>
        We may terminate the Agreement or suspend your use of Services (a)
        immediately and without notice, if we discover you or Plan are not using
        Services for a legitimate business or Plan purpose; (b) you are past due
        on payment of undisputed fees owed and have not responded to request for
        payment; or (c) you or Plan materially breach or otherwise fail to
        comply with this Agreement and have not cured such breach within fifteen
        (15) days of notice specifying the alleged breach. Sections 4, 5, 6, 7,
        10, 11, 12 and 13 shall survive the termination of this Agreement.{' '}
      </p>

      <p>
        <b>15. Miscellaneous.</b>
      </p>
      <p>
        (a) This Agreement will be governed by and construed in accordance with
        the laws of the State of Illinois to the extent not preempted by
        controlling federal law. Any controversies, claims, counterclaims,
        crossclaims, or disputes arising out of or in any way related to this
        Agreement, whether sounding in tort, contract, equity, or statute, shall
        be governed by the laws of the State of Illinois, without reference to
        that state’s conflict of law rules or principles. You hereby submit to
        the jurisdiction of courts of competent jurisdiction located in the
        State of Illinois.
        <br />
        (b) No party will be in breach of this Agreement as a result of, nor
        will either party be liable to the other party for, liabilities,
        damages, or other losses arising out of delays in performance caused by
        circumstances or events beyond the reasonable control of the delaying
        party. <br />
        (c) Any written notice required to be given pursuant to this Agreement
        will be deemed effective on the earliest of (i) actual receipt, (ii) the
        next business day following deposit for overnight delivery with a
        nationally recognized overnight courier service, and (iii) the same day
        following transmission of an electronic mail message (“Email”) during
        regular business hours, in each case, with fees, if any, prepaid and
        addressed to the party at the address set forth below or at such other
        address as that party may notify the other of in writing in accordance
        with this paragraph. Under this Agreement, an Email transmission is a
        writing, and the term “address” shall include a party’s Email address.
        Each party is entitled to rely on the contact information contained in
        this Agreement until it has received written notification of a change in
        such information and has had a reasonable period to react to such
        change. By using the Services, you and Plan consent to receiving
        electronic communications from us. These electronic communications may
        include without limitation notices about your Services, your invoices or
        payments, changes to the fees or the Agreement, reports of your
        violations of the Agreement, suspension of your use of the Services,
        termination of the Agreement, changes to Services, availability of new
        services, or other information relating to us and the Services.
        <br />
        (d) {COMPANY_NAME.medium} may assign or transfer this Agreement, or any
        of its rights and obligations under it upon written notice to you,
        provided the assignee agrees in writing to the obligations of the
        assigning party set forth in this Agreement. You and/or the Plan may not
        assign or transfer this Agreement, or any of its rights and obligations
        under it, without our written consent. <br />
        (e) This Agreement may be amended in any respect and at any time
        (including retroactively) to comply with relevant laws, without prior
        notice or consent. This Agreement may be amended for any other reason,
        which amendment will be deemed effective upon the delivery of the notice
        of the amendment to you, unless you object thereto by notifying us in
        writing, within 30 calendar days from the date the notice is delivered.{' '}
        <br />
        (f) If any provision of this Agreement is held by a court of competent
        jurisdiction to be invalid or unenforceable, the remaining provisions
        will continue to be fully effective.
        <br />
        (g) This Agreement, together with all Order Forms, the Flow Down Terms,
        and all current and future fee and pricing documents, to the extent
        applicable, constitutes the entire agreement between the parties with
        respect to access and use of the Services. The Agreement supersedes all
        other previous statements, communications or agreements, whether oral or
        written, including press releases, advertising and sales literature, and
        specifically includes any click through agreement or terms, with respect
        to the Services, accepted in connection with accessing the Services,
        which are incorporated herein by reference.
        <br />
        (h) This Agreement may be executed in any number of counterparts, each
        of which shall be deemed an original, and the counterparts shall
        constitute one and the same instrument.
      </p>
      <br />
      <p>
        Search Services will not be provided unless the applicable boxes are
        checked, and corresponding information is completed, below.
      </p>
      <br />
      <p>
        IN WITNESS WHEREOF, the parties have caused this Agreement to be
        executed by the party indicated below.
      </p>
    </>
  )
}

export default SearchServicesLanguageOfAgreement
