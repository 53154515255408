import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon as MTCIcon } from 'images/inspira-logo.svg'
import * as globalActions from '../main/actions'
import { compose } from 'recompose'
import { connect } from 'react-redux'

const propTypes = {
  setSupportModalShown: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

const defaultProps = {}

function WorkFlowHeader({ setSupportModalShown, title }) {
  return (
    <header className="header">
      <div id="header-action-area" className="header-action-area--contact">
        <div className="header-workflow">
          <div className="logo-wrapper">
            <MTCIcon className="header-mtc-logo" aria-hidden="true" />
          </div>
          <div className="header-text-wrapper">
            <h1 className="text-title--extra-large">{title}</h1>
          </div>
          <div className="header-contact-button-wrapper">
            <button type="button" onClick={() => setSupportModalShown(true)}>
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}
const mapDispatchToProps = {
  setSupportModalShown: globalActions.setSupportModalShown,
}

WorkFlowHeader.propTypes = exact(propTypes)
WorkFlowHeader.defaultProps = defaultProps

export default compose(connect(null, mapDispatchToProps))(WorkFlowHeader)
