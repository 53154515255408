import { createAction } from 'redux-actions'

export const clearServicesAgreementDetails = createAction(
  'CLEAR_SERVICES_AGREEMENT_DETAILS'
)

export const setShouldTriggerAllServicesAgreementsFetch = createAction(
  'SET_SHOULD_TRIGGER_ALL_SERVICES_AGREEMENTS_FETCH'
)

export const setSearchShouldTriggerAllServicesAgreementsFetch = createAction(
  'SET_SEARCH_SHOULD_TRIGGER_ALL_SERVICES_AGREEMENTS_FETCH'
)

export const setShouldTriggerServicesAgreementDetailsFetch = createAction(
  'SET_SHOULD_TRIGGER_SERVICES_AGREEMENT_DETAILS_FETCH'
)

export const resetSearchOrderCost = createAction('RESET_SEARCH_ORDER_COST')

export const setShouldTriggerSearchesFetch = createAction(
  'SET_SHOULD_TRIGGER_SEARCHES_FETCH'
)
