import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon as Checkmark } from 'images/plan-mapping-icons/checkmark.svg'
import { Icon as Exclamation } from 'images/plan-mapping-icons/exclamation.svg'
import { Icon as Info } from 'images/plan-mapping-icons/info.svg'

export const Variant = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFORMATIONAL: 'informational',
}

const propTypes = {
  type: PropTypes.oneOf(Object.values(Variant)).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isBadge: PropTypes.bool,
}
const defaultProps = {
  className: '',
  isBadge: false,
}

const getTypeIcon = (type) => {
  switch (type) {
    case Variant.SUCCESS:
      return Checkmark
    case Variant.INFORMATIONAL:
      return Info
    default:
      return Exclamation
  }
}

function Notification({ children, type, className, isBadge }) {
  const classes = classNames('notification', className, type, {
    'notification--badge': isBadge,
  })
  const TypeIcon = getTypeIcon(type)
  return (
    <div className={classes}>
      <TypeIcon aria-hidden="true" />
      <span>{children}</span>
    </div>
  )
}

Notification.propTypes = propTypes
Notification.defaultProps = defaultProps

export default Notification
