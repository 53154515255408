import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { Redirect, useHistory } from 'react-router-dom'
import { ExternalLink, FlatfileButton } from 'components'
import {
  RecordTypes,
  searchOrderProhibitedCompaniesRegex,
} from 'flatfile-config'
import { formatAndValidateSearchRecord } from 'utils'
import * as Types from 'types'
import { isEmpty } from 'lodash'
import { FlowActions } from '../components'
import { Icon as DownloadIcon } from 'images/download-icon.svg'
import { Icon as UploadIcon } from 'images/upload-icon.svg'
import { Path, ReasonForDiscard, SearchTypeID } from 'config'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
  resetDiscardedParticipants: PropTypes.func.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function UploadFileView({
  newSearchFlowData,
  setNewSearchFlowData,
  resetDiscardedParticipants,
}) {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(null)

  const handleContinue = () => history.push(Path.UPLOAD_SUMMARY)

  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />

  return (
    <>
      <div className="card full-height upload-participant-file-view-container">
        <div className="form-masthead">
          <h2>Next, let’s upload your Search Services file.</h2>
        </div>
        <div className="form-section">
          <div className="form-header">
            <h3 className="flow-section-headers">
              Upload Search Services File
            </h3>
          </div>
          <FlatfileButton
            recordType={RecordTypes.SEARCH}
            validators={
              newSearchFlowData.searchType === SearchTypeID.BENEFICIARY_SEARCH
                ? { dob: [{ validate: 'required' }] }
                : {}
            }
            buttonContent={
              <>
                Upload Participant File <UploadIcon aria-hidden="true" />
              </>
            }
            handleError={(error) => {
              if (!error) setErrorMessage(null)
              else setErrorMessage(error.message)
            }}
            handleData={(validData, discardedData) => {
              setNewSearchFlowData({
                ...newSearchFlowData,
                recordsData: validData,
              })
              const discardedParticipantsWithReason = discardedData.map(
                (participant) => {
                  const isProhibitedPlanFiduciary = new RegExp(
                    searchOrderProhibitedCompaniesRegex,
                    'i'
                  ).test(participant.planSponsorName)
                  const reasonForDiscard = isProhibitedPlanFiduciary
                    ? ReasonForDiscard.PROHIBITED_COMPANY
                    : ReasonForDiscard.INVALID_DATA
                  return {
                    ...participant,
                    reasonForDiscard,
                  }
                }
              )
              resetDiscardedParticipants(discardedParticipantsWithReason)
            }}
            handleRecords={formatAndValidateSearchRecord}
            handleRecordsSubmit={handleContinue}
          />
          {!!errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </div>
        <div className="form-header">
          <h3 className="is-marginless">File Instructions</h3>
        </div>
        <p>
          To help get you started, please use the provided CSV template linked
          below.
        </p>
        <p>
          Already familiar with our template or want to input information
          manually? Click, the "Upload Participant File" button.
        </p>
        <p>
          Once your participant file is uploaded, if required information is not
          provided or passes validation, these rows will be discarded and
          provided in a discarded participant file after your file is
          successfully submitted. If this occurs and more details are needed,
          please contact your Relationship Manager.
        </p>
        <p>
          <ExternalLink
            link={process.env.REACT_APP_SEARCH_RECORD_TEMPLATE_URL}
            className="link-text"
            id="search-template"
          >
            Search Services Template
            <DownloadIcon aria-hidden="true" />
          </ExternalLink>
        </p>
      </div>
      <FlowActions
        handleSubmit={(e) => {
          e.preventDefault()
          // if, for instance, flatfile error exists, do nothing
          if (errorMessage) return
          if (isEmpty(newSearchFlowData.recordsData)) {
            setErrorMessage(
              'Please provide the required file in order to continue'
            )
            return
          }
          handleContinue()
        }}
      />
    </>
  )
}

UploadFileView.propTypes = propTypes
UploadFileView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
  resetDiscardedParticipants: actions.resetDiscardedParticipants,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadFileView
)
