import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button, SubmitButton } from 'lp-components'
import { ScrollErrorForm, FileInput } from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

const defaultProps = {}

function UploadSignedPlanAgreementForm({ handleSubmit, handleCancel }) {
  return (
    <Formik
      initialValues={{
        signedAgreement: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        signedAgreement: Yup.mixed().required(
          'Please upload a signed Services Agreement in order to continue.'
        ),
      })}
    >
      {({ setFieldValue, setFieldError, setFieldTouched, isSubmitting }) => {
        return (
          <ScrollErrorForm>
            <FileInput
              name="signedAgreement"
              accept={{
                'application/pdf': ['.pdf'],
              }}
              subtext={<p>Supports: PDF</p>}
              handleError={(message) => {
                setFieldValue('signedAgreement', '', false)
                setFieldTouched('signedAgreement', true, false)
                setFieldError('signedAgreement', message)
              }}
              invalidTypeErrorMessage="Only PDFs are supported. Please provide another document."
              sizeTooLargeErrorMessage="PDF is too large. Must be less than 25 MB."
              invalidPdfPageCountErrorMessage="The number of pages in your file submission is incorrect. Please provide another file."
              maxSize={25e6}
              minPdfPageCount={5}
              maxPdfPageCount={7}
            />
            <div className="form-actions">
              <SubmitButton submitting={isSubmitting}>
                Upload Agreement
              </SubmitButton>
              <Button onClick={handleCancel} variant="text">
                Cancel
              </Button>
            </div>
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

UploadSignedPlanAgreementForm.propTypes = exact(propTypes)
UploadSignedPlanAgreementForm.defaultProps = defaultProps

export default React.memo(UploadSignedPlanAgreementForm)
