import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Resources } from './views'
import Layout from './Layout'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  window.appEventData.pop()
  var appEventData = window.appEventData || []
  appEventData.push({
    event: 'Page Load Completed',
  })
  return (
    <Switch>
      <Route
        exact
        path={path + '/'}
        render={(routeProps) => {
          return (
            <Layout>
              <Resources {...routeProps} />
            </Layout>
          )
        }}
      />
      <Redirect path="*" to="/resources" />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
