import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  Input,
  MaskedInput,
  RadioGroup,
  ScrollErrorForm,
  LabelWithTooltip,
  PlanEinTooltipContent,
} from 'components'
import { SubmitButton, Button } from 'lp-components'
import {
  SigningMethod,
  signingMethodOptions,
  COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT,
} from 'config'
import { Icon as WetSignatureIcon } from 'images/signing-method-icons/wet-signature.svg'
import { Icon as ElectronicSignatureIcon } from 'images/signing-method-icons/electronic-signature.svg'

const propTypes = {
  handleCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

const defaultProps = {}

function getSigningMethodIcon(method) {
  switch (method) {
    case SigningMethod.ELECTRONIC_SIGNATURE:
      return ElectronicSignatureIcon
    case SigningMethod.WET_SIGNATURE:
      return WetSignatureIcon
    default:
      return () => null
  }
}

function SigningMethodLabel({ htmlFor, option }) {
  const SignatureIcon = getSigningMethodIcon(option.key)
  return (
    <label htmlFor={htmlFor}>
      <div className="icon-container">
        <SignatureIcon aria-hidden="true" />
      </div>
      <div className="description-container">
        <p className="label">{option.key}</p>
        <p className="description">{option.description}</p>
      </div>
    </label>
  )
}

const signingMethods = signingMethodOptions.filter(
  (option) => option.key !== SigningMethod.E_SIGN_RSP
)

function AddPlanAgreementForm({ handleCancel, isSubmitting }) {
  return (
    <ScrollErrorForm>
      <div className="form-body">
        <div className="row">
          <div className="one-half column">
            <Input
              label="Complete Legal Plan Name"
              name="planName"
              placeholder="Enter complete legal plan name"
              labelComponent={LabelWithTooltip}
              tooltipContent={COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT}
              tooltipAriaLabel="Complete legal plan name information"
            />
          </div>
          <div className="one-half column">
            <MaskedInput
              label="Employer Identification Number (EIN)"
              name="planEin"
              placeholder="Enter employer identification number"
              maskOptions={{
                numericOnly: true,
                blocks: [2, 7],
                delimiter: '-',
              }}
              labelComponent={LabelWithTooltip}
              tooltipContent={<PlanEinTooltipContent />}
              tooltipAriaLabel="Plan EIN information"
            />
          </div>
        </div>
        <div className="row">
          <RadioGroup
            className="card-radio-group"
            name="signingMethod"
            label="What is your preferred signing method?"
            options={signingMethods}
            radioInputProps={{
              labelComponent: SigningMethodLabel,
            }}
          />
        </div>
      </div>
      <div className="form-actions">
        <SubmitButton submitting={isSubmitting}>Continue</SubmitButton>
        <Button className="button-cancel" onClick={handleCancel} variant="text">
          Cancel
        </Button>
      </div>
    </ScrollErrorForm>
  )
}

AddPlanAgreementForm.propTypes = exact(propTypes)
AddPlanAgreementForm.defaultProps = defaultProps

export default React.memo(AddPlanAgreementForm)
