import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { ScrollErrorForm, RadioGroup } from 'components'
import { FlowActions } from '../components'
import * as Yup from 'yup'
import { map } from 'lodash'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}
const defaultProps = {}
const fiduciarySearchOptions = [
  {
    key: '1',
    value: 'Send final letter via Certified Mail™ (incurs additional $15 fee)',
  },
  {
    key: '2',
    value: 'Don’t send final letter',
  },
]
const fiduciarySearchValues = map(fiduciarySearchOptions, 'value')

function FiduciarySearchForm({ onSubmit, initialValues }) {
  const fiduciarySearchSchema = Yup.object({
    finalMailing: Yup.string()
      .oneOf(fiduciarySearchValues, 'Must be a valid mailing preference')
      .required('Required'),
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={fiduciarySearchSchema}
    >
      {() => {
        return (
          <ScrollErrorForm>
            <div className="radio-container-input">
              <p>
                Fiduciary Search includes three outbound mailings via
                First-Class Mail®, two phone calls, and a web self-report
                portal.
              </p>
              <p>
                Once initial outreaches are complete, you may choose for PBI to
                send a final letter via Certified Mail™ to the best known
                address for the remaining not found participants.
              </p>
              <RadioGroup
                options={fiduciarySearchValues}
                name="finalMailing"
                label={false}
              />
            </div>
            <FlowActions />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}
FiduciarySearchForm.propTypes = propTypes
FiduciarySearchForm.defaultProps = defaultProps

export default FiduciarySearchForm
