import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useClickAway } from 'react-use'
import {
  CheckboxGroup,
  SearchableSelect,
  ScrollErrorForm,
  DateInput,
} from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { SubmitButton } from 'lp-components'
import { ServicesAgreementStatus, AccountAudience } from 'config'
import { uniq, compact } from 'lodash'
import * as Types from 'types'
import { sortSelectOptions } from 'utils'
import { selectors as globalSelectors } from 'global-reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'

// Convert enums from config to list as options for form inputs
const agreementStatusOptions = Object.values(ServicesAgreementStatus)

const propTypes = {
  filters: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary)
    .isRequired,
  currentUser: Types.user.isRequired,
}

const defaultProps = {}

function FakeLegend({ label }) {
  return <label>{label}</label>
}

function PlanAgreementsFilterDialog({
  filters,
  handleSubmit,
  clearAllFilters,
  handleClose,
  servicesAgreements,
  currentUser,
}) {
  const ref = useRef(null)

  useClickAway(ref, (e) => {
    const targetEl = e.target
    if (!document.body.contains(targetEl)) return
    handleClose()
  })

  // dynamically generate plan sponsor and agreement type select options
  const uniquePlanSponsorList = uniq(
    compact(servicesAgreements.map((a) => a.planSponsorCompany.name))
  )
  const uniqueInitialInvestmentList = uniq(
    compact(servicesAgreements.map((a) => a.portalAgreementType))
  )

  const filterSchema = Yup.object().shape({
    agreementStatus: Yup.array().test(
      'Valid agreement status',
      `Invalid value(s). Must be one of the following: ${agreementStatusOptions.join(
        ', '
      )}`,
      (val) => {
        return val.every((el) => agreementStatusOptions.includes(el))
      }
    ),
    initialInvestment: Yup.array().test(
      'Valid initial investment',
      `Invalid value(s). Must select valid initial investments.`,
      (val) => {
        return val.every((el) => uniqueInitialInvestmentList.includes(el))
      }
    ),
    planSponsor: Yup.array().test(
      'Valid plan sponsor',
      `Invalid value(s). Must select valid plan sponsors.`,
      (val) => {
        return val.every((el) => uniquePlanSponsorList.includes(el))
      }
    ),
    startLastUpdatedDate: Yup.date(),
    endLastUpdatedDate: Yup.date(),
  })

  return (
    <div
      className="filter-formik-container"
      ref={ref}
      onBlur={(e) => {
        if (ref.current.contains(e.relatedTarget ?? e.target)) return
        handleClose()
      }}
    >
      <Formik
        initialValues={filters}
        validationSchema={filterSchema}
        onSubmit={(values) => {
          handleSubmit(values)
          handleClose()
        }}
      >
        {({
          values: { startLastUpdatedDate, endLastUpdatedDate },
          isSubmitting,
        }) => (
          <ScrollErrorForm>
            <div className="services-agreements-filter filter-dialog-container">
              <div className="filter-row">
                <CheckboxGroup
                  name="agreementStatus"
                  label="Agreement Status"
                  options={sortSelectOptions(agreementStatusOptions)}
                  labelComponent={FakeLegend} // legends don't respond to flex styling
                />
              </div>
              <div className="filter-row">
                <SearchableSelect
                  name="initialInvestment"
                  label="Initial Investment"
                  options={sortSelectOptions(uniqueInitialInvestmentList)}
                  isMulti={true}
                  placeholder="Select Initial Investment(s)"
                  marginTop="0"
                />
              </div>
              {currentUser.accountAudience !==
                AccountAudience.PLAN_FIDUCIARY && (
                <div className="filter-row">
                  <SearchableSelect
                    name="planSponsor"
                    options={sortSelectOptions(uniquePlanSponsorList)}
                    isMulti={true}
                    placeholder="Select Plan Sponsor(s)"
                    marginTop="0"
                  />
                </div>
              )}
              <div className="filter-row">
                <fieldset aria-labelledby="date-range-legend">
                  <div className="fake-legend" id="date-range-legend">
                    Date Created
                  </div>
                  <div id="filter-date-range">
                    <DateInput
                      name="startLastUpdatedDate"
                      label={false}
                      aria-label="start date range of last updated date"
                      selectsStart
                      startDate={startLastUpdatedDate}
                      endDate={endLastUpdatedDate}
                    />
                    <div id="date-separator" aria-hidden="true">
                      -
                    </div>
                    <DateInput
                      name="endLastUpdatedDate"
                      label={false}
                      aria-label="end date range of last updated date"
                      selectsEnd
                      startDate={startLastUpdatedDate}
                      endDate={endLastUpdatedDate}
                      minDate={startLastUpdatedDate}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="filter-row filter-actions-container">
                <div className="filter-actions">
                  <button
                    type="button"
                    id="filter-btn-clear"
                    className="button-text"
                    onClick={() => {
                      clearAllFilters()
                      handleClose()
                    }}
                  >
                    Clear all filters
                  </button>
                </div>
                <div className="filter-actions">
                  <button
                    type="button"
                    id="filter-btn-cancel"
                    className="button-text"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <SubmitButton submitting={isSubmitting}>
                    Apply Filters
                  </SubmitButton>
                </div>
              </div>
            </div>
          </ScrollErrorForm>
        )}
      </Formik>
    </div>
  )
}

PlanAgreementsFilterDialog.propTypes = propTypes
PlanAgreementsFilterDialog.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

export default compose(connect(mapStateToProps, null))(
  PlanAgreementsFilterDialog
)
