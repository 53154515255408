import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { ScrollErrorForm, RadioGroup } from 'components'
import { FlowActions } from '../components'
import * as Yup from 'yup'
import { map } from 'lodash'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}
const defaultProps = {}

const numberOfMailingsOptions = [
  {
    key: '1',
    value: 'One address',
  },
  {
    key: '2',
    value: 'Two addresses',
  },
]
const numberOfMailingsValues = map(numberOfMailingsOptions, 'value')

const oneAddressOption = numberOfMailingsOptions[0]
const twoAddressesOption = numberOfMailingsOptions[1]
const mailingMethod = [
  {
    key: 'First-Class Mail®',
    value: 'First-Class Mail®',
  },
  {
    key: 'Certified Mail™',
    value: 'Certified Mail™',
  },
]
const mailingMethodValues = map(mailingMethod, 'value')

function ConfirmedSearchForm({ onSubmit, initialValues }) {
  const confirmedSearchSchema = Yup.object({
    numberofMailings: Yup.string()
      .oneOf(numberOfMailingsValues, 'Must be a valid mailing preference')
      .required('Required'),
    mailingMethod: Yup.string().when('numberofMailings', {
      is: oneAddressOption.value,
      then: (schema) =>
        schema
          .oneOf(mailingMethodValues, 'Must be a mailing method')
          .required('Required'),
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={confirmedSearchSchema}
    >
      {({ values }) => {
        const { numberofMailings } = values
        const showOneAddress = numberofMailings == oneAddressOption.value
        const showTwoAddresses = numberofMailings == twoAddressesOption.value
        if (numberofMailings === 'Two addresses') {
          values.mailingMethod = 'First-Class Mail®'
        }
        return (
          <ScrollErrorForm>
            <div className="radio-container-input">
              <p>
                Mailings are sent to the most recent address(es) found for a
                participant.
              </p>
              <p>
                Select the number of addresses that should receive mailings.
              </p>
              <RadioGroup
                options={numberOfMailingsValues}
                name="numberofMailings"
                label={false}
              />
              {showOneAddress && (
                <div>
                  <div className="form-header">
                    <h3>Mailing Method</h3>
                  </div>
                  <div className="radio-container-input">
                    <p>
                      Select the mailing method that you would like to use.{' '}
                    </p>
                    <RadioGroup
                      options={mailingMethod}
                      name="mailingMethod"
                      label={false}
                    />
                  </div>
                </div>
              )}
              {showTwoAddresses && (
                <div>
                  <p>
                    Mailings will be sent to participants via First-Class Mail®.
                  </p>
                </div>
              )}
            </div>
            <FlowActions />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}
ConfirmedSearchForm.propTypes = propTypes
ConfirmedSearchForm.defaultProps = defaultProps

export default ConfirmedSearchForm
