import React from 'react'
import PropTypes from 'prop-types'
import ServiceAgreementDetailsForm from '../forms/ServiceAgreementDetailsForm'
import { selectors as globalSelectors } from 'global-reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Types from 'types'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { mergeAll } from 'lodash/fp'

const propTypes = {
  currentUser: Types.user.isRequired,
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
}
const defaultProps = {
  newSearchFlowData: null,
}

const baseInitialValues = {
  contactID: '',
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: '',
}

function ServiceAgreementDetailsView({
  currentUser,
  newSearchFlowData,
  setNewSearchFlowData,
}) {
  const history = useHistory()
  return (
    <div className="card full-height search-services-agreement-view">
      <div className="search-services-agreement-form">
        <div className="form-masthead">
          <h2>Let’s capture company details & contact information</h2>
          <p>
            This information will help us create a new Services Agreement for
            you. Then you can review and sign the agreement in the next step.
          </p>
        </div>
        <div className="form-header">
          <h3>Company Details</h3>
        </div>
        <div className="company-details-container">
          <dl>
            <div className="company-details">
              <dt>Company Name</dt>
              <dd>{currentUser.accountName}</dd>
            </div>
            <div>
              <dt>Country</dt>
              <dd>{currentUser.accountBillingCountry}</dd>
            </div>
            <div>
              <dt>Street Address</dt>
              <dd>{currentUser.accountBillingStreet}</dd>
            </div>
            <div>
              <dt>City</dt>
              <dd>{currentUser.accountBillingCity ?? '-'}</dd>
            </div>
            <div>
              <dt>State/Province/Region</dt>
              <dd>{currentUser.accountBillingState ?? '-'}</dd>{' '}
            </div>
            <div>
              <dt>Zip Code</dt>
              <dd>{currentUser.accountBillingPostalCode}</dd>
            </div>
          </dl>
        </div>
        <ServiceAgreementDetailsForm
          initialValues={mergeAll([
            baseInitialValues,
            {
              contactID: currentUser.contactID,
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
              title: currentUser.userTitle ?? undefined,
              email: currentUser.userEmail,
              phone: currentUser.userPhone ?? undefined,
            },
            newSearchFlowData,
          ])}
          onSubmit={(params) => {
            setNewSearchFlowData({
              ...newSearchFlowData,
              ...params,
            })
            history.push('/search/add-new-services-agreement')
          }}
        />
      </div>
    </div>
  )
}

ServiceAgreementDetailsView.propTypes = propTypes
ServiceAgreementDetailsView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ServiceAgreementDetailsView
)
