import React from 'react'

const propTypes = {}

const defaultProps = {}

function PlanEinTooltipContent() {
  return (
    <>
      An Employer Identification Number (EIN) is also known as{' '}
      <b>
        <em>a Federal Tax Identification Number</em>
      </b>
      , and is the 9-digit number (format: XX-XXXXXXX) that is used to identify
      a business entity.
    </>
  )
}

PlanEinTooltipContent.propTypes = propTypes
PlanEinTooltipContent.defaultProps = defaultProps

export default React.memo(PlanEinTooltipContent)
