import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  SearchableSelect,
  ScrollErrorForm,
  LabelWithTooltip,
  ConfirmModal,
  Notification,
  Tooltip,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FlowActions, PlanMappingMatchMessage } from '../components'
import { displayEin, sortSelectOptions } from 'utils'
import { groupBy, concat, every, map, reduce } from 'lodash'
import {
  COMPANY_NAME,
  NO_SERVICES_AGREEMENT_OPTION,
  Path,
  AroActionItems,
} from 'config'
import { useHistory, Link } from 'react-router-dom'
import { Icon as InfoIcon } from 'images/info.svg'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary)
    .isRequired,
  isAlreadyCompleted: PropTypes.bool,
}

const defaultProps = {
  isAlreadyCompleted: false,
}

const generateGroupedOptions = (exactMatch, likelyMatches, allOptions) => {
  if (exactMatch) {
    const { true: exactOption, false: remainingOptions } = groupBy(
      allOptions,
      ({ value }) => value === exactMatch.serviceAgreementID
    )
    return [
      { label: 'Exact Match', options: exactOption },
      remainingOptions && {
        label: 'Other Plans',
        options: remainingOptions,
      },
    ].filter(Boolean)
  }

  if (likelyMatches) {
    const likelyMatchesIds = likelyMatches.map(
      ({ serviceAgreementID }) => serviceAgreementID
    )
    const { true: likelyOptions, false: remainingOptions } = groupBy(
      allOptions,
      ({ value }) => likelyMatchesIds.includes(value)
    )
    return [
      {
        label: likelyMatches.length > 1 ? 'Likely Matches' : 'Likely Match',
        options: likelyOptions,
      },
      remainingOptions && {
        label: 'Other Plans',
        options: remainingOptions,
      },
    ].filter(Boolean)
  }

  return [
    {
      label: 'All Plans',
      options: allOptions,
    },
  ]
}

const isMissingAllPlans = (planHash) =>
  every(
    planHash,
    ({ servicesAgreement }) =>
      servicesAgreement === NO_SERVICES_AGREEMENT_OPTION.value
  )

function PlanMappingForm({
  initialValues,
  selectOptions,
  onSubmit,
  servicesAgreements,
  isAlreadyCompleted,
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const errorCardRef = useRef()
  const history = useHistory()

  const planNames = sortSelectOptions(
    map(initialValues, (_, key) => {
      return {
        name: window.atob(key),
        encodedName: key,
      }
    }),
    'name'
  )
  const selectOptionValues = concat(
    selectOptions,
    NO_SERVICES_AGREEMENT_OPTION
  ).map((option) => option.value)
  const planSchema = Yup.object(
    reduce(
      initialValues,
      (schema, _, key) => {
        schema[key] = Yup.object({
          servicesAgreement: Yup.string()
            .required(
              'Each Complete Legal Plan Name must be mapped to a completed Services Agreement'
            )
            .oneOf(selectOptionValues, 'Must be a valid option'),
        })
        return schema
      },
      {}
    )
  )

  return (
    <div className="workflow-card-container">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (isMissingAllPlans(values)) {
            const container = errorCardRef.current
            if (container) {
              container.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
            return
          }
          onSubmit(values)
        }}
        validationSchema={planSchema}
      >
        {({ values, touched, errors }) => {
          const missingAllPlans = isMissingAllPlans(values)
          return (
            <ScrollErrorForm>
              {missingAllPlans && (
                <div
                  className="form-section form-section--with-top-border plan-mapping error"
                  ref={errorCardRef}
                >
                  <Notification type={NotificationVariant.ERROR}>
                    <p className="text-title--small">
                      Your participant file cannot be submitted when all plans
                      are missing agreements. In order to continue, please get
                      started with adding any missing Services Agreements.
                    </p>
                  </Notification>
                  <button
                    type="button"
                    className="button-primary add-services-agreement"
                    onClick={() => setShowConfirmModal(true)}
                  >
                    {AroActionItems.CREATE_NEW_SERVICES_AGREEMENT}
                  </button>
                </div>
              )}
              {showConfirmModal && (
                <ConfirmModal
                  onClose={() => setShowConfirmModal(false)}
                  onConfirm={() => {
                    setShowConfirmModal(false)
                    history.push({
                      pathname: Path.ADD_NEW_SERVICES_AGREEMENT,
                      state: {
                        ignorePrompt: true,
                      },
                    })
                  }}
                  confirmContent="Yes, Quit & Exit"
                >
                  <h2>Quit & Exit</h2>
                  <p>
                    Are you sure you want to exit the new rollover workflow to
                    continue with adding a new Services Agreement?
                  </p>
                </ConfirmModal>
              )}
              {planNames.map(({ name, encodedName }) => {
                const servicesAgreementValue =
                  values[encodedName].servicesAgreement
                const selectedServicesAgreement = servicesAgreements.find(
                  ({ serviceAgreementID }) =>
                    serviceAgreementID === servicesAgreementValue
                )
                const { exactMatch, likelyMatches } = initialValues[encodedName]
                const groupedOptions = generateGroupedOptions(
                  exactMatch,
                  likelyMatches,
                  selectOptions
                )
                return (
                  <div key={encodedName} className="form-section">
                    <div className="form-header">
                      <h3>{name}</h3>
                    </div>
                    <dl className="plan-content">
                      {!isAlreadyCompleted && (
                        <PlanMappingMatchMessage
                          exactMatchExists={!!exactMatch}
                          likelyMatches={likelyMatches}
                          hasConfirmed={
                            // Cannot solely rely on the existence of a services agreement value to
                            // determine if the user has confirmed the input because if an exact match
                            // exists, the plan would be auto-mapped in the form's initial state
                            (servicesAgreementValue &&
                              servicesAgreementValue !==
                                exactMatch?.serviceAgreementID) ||
                            (!!touched[encodedName]?.servicesAgreement &&
                              !errors[encodedName]?.servicesAgreement)
                          }
                          noAgreementFound={
                            servicesAgreementValue ===
                            NO_SERVICES_AGREEMENT_OPTION.value
                          }
                          missingAllPlans={missingAllPlans}
                        />
                      )}
                      <div className="row">
                        <div className="one-half column">
                          <dt>
                            Complete Legal Plan Name
                            <Tooltip
                              content={'Plan Name In The Participant Data File'}
                              ariaLabel={`Plan Name In The Participant Data File`}
                            >
                              <InfoIcon className="icon-info" />
                            </Tooltip>
                          </dt>
                          <dd>{name}</dd>
                        </div>
                        <div className="one-half column">
                          <SearchableSelect
                            classNamePrefix="mapping-select"
                            name={`${encodedName}[servicesAgreement]`}
                            label="Services Agreement"
                            labelComponent={LabelWithTooltip}
                            tooltipContent={`Plan Name of an agreement that ${COMPANY_NAME['medium']} has on file. If the agreement you're looking for is not listed, select No Services Agreement Found.`}
                            tooltipAriaLabel="Plan mapping and selection information"
                            placeholder="Select Services Agreement"
                            options={concat(
                              NO_SERVICES_AGREEMENT_OPTION,
                              groupedOptions
                            )}
                            formatGroupLabel={(data) => (
                              <div className="select-group-label">
                                <span>{data.label}</span>
                              </div>
                            )}
                          />
                          {selectedServicesAgreement && (
                            <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              className="link-text"
                              to={`${Path.SERVICES_AGREEMENTS}/${selectedServicesAgreement.serviceAgreementID}`}
                              aria-label={`View Services Agreement details for ${selectedServicesAgreement.name}`}
                            >
                              View Services Agreement details
                            </Link>
                          )}
                        </div>
                      </div>
                      {selectedServicesAgreement && (
                        <div className="row">
                          <div className="one-half column">
                            <dt>Initial Investment:</dt>
                            <dd>
                              {selectedServicesAgreement.portalAgreementType}
                            </dd>
                          </div>
                          <div className="one-half column">
                            <dt>Employer Identification Number (EIN):</dt>
                            <dd>
                              {displayEin(selectedServicesAgreement.planEIN)}
                            </dd>
                          </div>
                        </div>
                      )}
                    </dl>
                  </div>
                )
              })}
              <FlowActions />
            </ScrollErrorForm>
          )
        }}
      </Formik>
    </div>
  )
}

PlanMappingForm.propTypes = exact(propTypes)
PlanMappingForm.defaultProps = defaultProps

export default React.memo(PlanMappingForm)
