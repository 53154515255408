import React from 'react'
import { Icon as ComponentErrorIcon } from 'images/component-error.svg'
import { SupportEmailLink, SupportPhoneLink } from 'components'

const propTypes = {}

const defaultProps = {}

function ComponentFailure() {
  return (
    <div className="component-failure">
      <div className="error-state">
        <ComponentErrorIcon aria-hidden="true" />
        <h2 className="error-state__title">
          We’re sorry, it looks like there was an issue
        </h2>
        <p className="error-state__description">
          Please try refreshing the page. If the issue continues, please contact
          support at <SupportEmailLink /> or call at <SupportPhoneLink />.
        </p>
        <button
          type="button"
          className="button-primary"
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </button>
      </div>
    </div>
  )
}

ComponentFailure.propTypes = propTypes
ComponentFailure.defaultProps = defaultProps

export default ComponentFailure
