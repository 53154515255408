import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { clearSessionToken } from 'auth'

const propTypes = {}

const defaultProps = {}

function PasswordView() {
  const { logout } = useAuth0()

  function handleLogout() {
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }

  return (
    <div className="password-view">
      <h1>Password</h1>
      <div className="card">
        <h2>Change Password</h2>
        <p>
          Need to change your password? Please log out of the platform and
          follow the "Don’t remember your password?" link to continue.
        </p>
        <button className="button-primary" onClick={handleLogout} type="button">
          Log Out
        </button>
      </div>
    </div>
  )
}

PasswordView.propTypes = propTypes
PasswordView.defaultProps = defaultProps

export default React.memo(PasswordView)
