import React from 'react'
import { FlowActions as BaseFlowActions } from 'components'
import { Path } from 'config'

const propTypes = {}

const defaultProps = {}

function FlowActions(props) {
  return (
    <BaseFlowActions
      submitContent="Next"
      exitPath={Path.SEARCH_HISTORY}
      {...props}
    />
  )
}

FlowActions.propTypes = propTypes
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
