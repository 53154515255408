import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Types from 'types'

const propTypes = {
  companyOwner: Types.companyOwner,
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {
  companyOwner: null,
}

function SupportContact({ type, email, phone, name }) {
  return (
    <div key={type}>
      <div className="type-info">
        {name && <p className="name">{name}</p>}
        <p className="email">
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        {phone && (
          <p className="phone">
            <a href={`tel:${phone}`}>{phone}</a>
          </p>
        )}
      </div>
    </div>
  )
}

function SupportModal({ companyOwner, onClose }) {
  return (
    <Modal onClose={onClose}>
      <div className="support-modal_info">
        <h2>Contact Us</h2>
        <p>We're here to help you get the answers you need.</p>
      </div>
      <hr />
      <div className="support-modal_product-types">
        <p>
          <strong>For Website Support</strong>
        </p>
        <div className="website-support">
          <p>
            Email us at{' '}
            <a
              className="email-support"
              href={`mailto:RSP_CS_Support@inspirafinancial.com`}
            >
              RSP_CS_Support@inspirafinancial.com
            </a>
          </p>
          <p className="website-support-schedule">
            Monday-Friday, 8am to 5pm CT
          </p>
        </div>
        {companyOwner ? (
          <>
            <p>
              <strong>
                For all other questions, reach out to your Relationship Manager.
              </strong>
            </p>
            <SupportContact type="Company Owner" {...companyOwner} />
          </>
        ) : (
          <>
            <p>
              <strong>
                For all other questions, reach out to Customer Support.
              </strong>
            </p>
            <SupportContact email="RS_Sales@inspirafinancial.com" />
          </>
        )}
      </div>
      <button className="button-primary" type="button" onClick={onClose}>
        Close
      </button>
    </Modal>
  )
}

SupportModal.propTypes = propTypes
SupportModal.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    companyOwner: globalSelectors.companyOwner(state),
  }
}

export default compose(connect(mapStateToProps))(SupportModal)
