import React from 'react'
import { useDropzone, ErrorCode } from 'react-dropzone'
import PropTypes from 'prop-types'
import { withFormikAdapter } from 'utils'
import { FileNameContainer } from 'components'
import classnames from 'classnames'
import { LabeledField, InputLabel } from 'lp-components'
import { isEmpty, uniqBy, flatten, isArray, concat, compact } from 'lodash'
import { flashErrorMessage } from 'redux-flash'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'

const DEFAULT_MIN_PDF_PAGE_COUNT = 1
const DEFAULT_MAX_PDF_PAGE_COUNT = Infinity

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  accept: PropTypes.object,
  handleError: PropTypes.func.isRequired,
  invalidTypeErrorMessage: PropTypes.string,
  sizeTooLargeErrorMessage: PropTypes.string,
  invalidPdfPageCountErrorMessage: PropTypes.string,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  subtext: PropTypes.node,
  id: PropTypes.string,
  showLabel: PropTypes.bool,
  minPdfPageCount: PropTypes.number,
  maxPdfPageCount: PropTypes.number,
}

const defaultProps = {
  maxSize: null,
  multiple: false,
  subtext: null,
  invalidTypeErrorMessage: null,
  sizeTooLargeErrorMessage: null,
  invalidPdfPageCountErrorMessage: null,
  showLabel: false,
  minPdfPageCount: DEFAULT_MIN_PDF_PAGE_COUNT,
  maxPdfPageCount: DEFAULT_MAX_PDF_PAGE_COUNT,
}

/* 
Setting accepted file types: react-dropzone expects an object with 
  a common MIME type as keys and an array of file extensions as values (e.g., 
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'text/html': ['.html', '.htm'],
      }
    })
See https://react-dropzone.js.org/#section-accepting-specific-file-types 
*/

// Use the same shape as react-dropzone's ErrorCode
// https://github.com/react-dropzone/react-dropzone/blob/master/src/utils/index.js
const invalidPdfPageCountRejectionErr = {
  code: 'invalid-pdf-page-count',
  message: 'Page count for a pdf file is not met.',
}

const getFieldValueAsArray = (value) => {
  if (isArray(value)) {
    return value
  }
  if (isEmpty(value)) {
    return []
  }
  return [value]
}

const getUniqueErrors = (rejectedFiles) => {
  const errors = flatten(rejectedFiles.map((file) => file.errors))
  return uniqBy(errors, 'code')
}

const generateErrorMessage = (
  rejectedFiles,
  invalidTypeMessage = null,
  sizeTooLargeMessage = null,
  invalidPdfPageCountErrorMessage = null
) => {
  const errors = getUniqueErrors(rejectedFiles)
  return errors
    .map(({ message, code }) => {
      if (code === ErrorCode.FileInvalidType) {
        return invalidTypeMessage || message
      }
      if (code === ErrorCode.FileTooLarge) {
        return sizeTooLargeMessage || message
      }
      if (code === invalidPdfPageCountRejectionErr.code) {
        return invalidPdfPageCountErrorMessage || message
      }
      return message
    })
    .join('; ')
}

const generateFileId = (file) =>
  file.path + '-' + file.size + '-' + file.lastModified

function LabelComponent({ showLabel, ...rest }) {
  return (
    <InputLabel
      {...rest}
      className={classnames({ 'visually-hidden': !showLabel })}
    />
  )
}

// Validate each file in acceptedFiles by page count
// Returns a promise with an array of objects, each describing the outcome of the mapped files
function validateAcceptedFilesByPageCount({
  acceptedFiles,
  pdfjsLib,
  minPdfPageCount,
  maxPdfPageCount,
}) {
  const promises = acceptedFiles.map((file) => {
    const url = window.URL.createObjectURL(file)
    const loadingTask = pdfjsLib.getDocument(url)
    return loadingTask.promise
      .then(({ numPages }) => {
        // Use the same shape as react-dropzone library
        // acceptedFiles: [ File ]
        // rejectedFiles: [{ file: File, errors: [{ code: '', message: '' }] }]
        if (numPages < minPdfPageCount || numPages > maxPdfPageCount) {
          return {
            file,
            errors: [invalidPdfPageCountRejectionErr],
          }
        }
        return file
      })
      .finally(() => window.URL.revokeObjectURL(url))
  })
  return Promise.allSettled(promises)
}

function FileInput(props) {
  const {
    input: { name, value, onChange },
    meta,
    accept,
    subtext,
    maxSize,
    handleError,
    invalidTypeErrorMessage,
    sizeTooLargeErrorMessage,
    invalidPdfPageCountErrorMessage,
    multiple,
    id,
    showLabel, // eslint-disable-line no-unused-vars
    minPdfPageCount,
    maxPdfPageCount,
    ...rest
  } = props

  const dispatch = useDispatch()

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    // Loaded via <script> tag, create shortcut to access PDF.js exports
    const pdfjsLib = window['pdfjs-dist/build/pdf']

    const validatePdfPageCount =
      minPdfPageCount > DEFAULT_MIN_PDF_PAGE_COUNT ||
      maxPdfPageCount !== DEFAULT_MAX_PDF_PAGE_COUNT

    let allAcceptedFiles = []
    let allRejectedFiles = [...rejectedFiles]

    // If there's validation on pdf page count, validate only if pdfjsLib is loaded
    // Skip pdf page validations completely if the pdfjsLib fails to load
    if (pdfjsLib && validatePdfPageCount) {
      // The workerSrc property shall be specified once pdfjsLib is loaded
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js'

      const validations = await validateAcceptedFilesByPageCount({
        acceptedFiles,
        pdfjsLib,
        minPdfPageCount,
        maxPdfPageCount,
      })
      validations.forEach(({ status, value }, index) => {
        // If the file (promise) is rejected (ie. library is unable to ingest), value is undefined &
        // it skips page count validation. The file will still be considered as an accepted file.
        if (status === 'rejected') {
          allAcceptedFiles.push(acceptedFiles[index])
        } else {
          // File that passes the page count validation will be part of allAcceptedFiles
          if (value.errors) allRejectedFiles.push(value)
          // File that fails the page count validation will get pushed to allRejectedFiles
          else allAcceptedFiles.push(value)
        }
      })
    } else allAcceptedFiles = acceptedFiles

    const errorMessage = generateErrorMessage(
      allRejectedFiles,
      invalidTypeErrorMessage,
      sizeTooLargeErrorMessage,
      invalidPdfPageCountErrorMessage
    )

    if (multiple) {
      const allUniqueAcceptedFiles = compact(
        uniqBy(concat(value, acceptedFiles), (file) => generateFileId(file))
      )

      // If dropped files contain rejected files:
      if (errorMessage) {
        // and there are no existing/accepted files, throw error and fail field validation
        if (!allAcceptedFiles.length) return handleError(errorMessage)
        // and there are existing/accepted files, flash error message while keeping the field valid
        dispatch(flashErrorMessage(errorMessage))
      }
      // For multiple input, store input value as an array of File(s)
      return onChange(allUniqueAcceptedFiles)
    }

    if (errorMessage) return handleError(errorMessage)

    // For single input, store input value as the File itself
    return onChange(acceptedFiles[0])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: multiple ? 0 : 1,
    multiple,
    maxSize,
    accept,
  })

  const existingFiles = getFieldValueAsArray(value)

  return (
    <>
      <Helmet>
        <script
          src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.min.js"
          type="text/javascript"
        />
      </Helmet>
      <LabeledField
        id={id || name}
        className="file-input"
        labelComponent={LabelComponent}
        {...props}
      >
        {(multiple || isEmpty(existingFiles)) && (
          <div
            {...getRootProps()}
            className={classnames('drag-n-drop-zone', {
              isError: !!meta.error && meta.touched,
            })}
          >
            <input {...getInputProps()} name={name} {...rest} />
            <div className="instructions-container">
              {isDragActive ? (
                <p className="drop-text">Drop the file here...</p>
              ) : (
                <>
                  <p className="drop-text">
                    Drag & drop or{' '}
                    <span className="link-primary">browse files</span>
                  </p>
                  {subtext && <div className="subtext">{subtext}</div>}
                </>
              )}
            </div>
          </div>
        )}
        {existingFiles.map((file) => (
          <FileNameContainer
            key={generateFileId(file)}
            fileName={file.name}
            handleDelete={() => {
              if (multiple) {
                return onChange(
                  value.filter(
                    (f) => generateFileId(f) !== generateFileId(file)
                  )
                )
              }
              return onChange('')
            }}
          />
        ))}
      </LabeledField>
    </>
  )
}

FileInput.propTypes = propTypes
FileInput.defaultProps = defaultProps

export default withFormikAdapter()(FileInput)
