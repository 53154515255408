import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon as CheckmarkIcon } from 'images/checkmark.svg'
import classnames from 'classnames'

const propTypes = {
  complete: PropTypes.bool.isRequired,
  current: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

const defaultProps = {}

function CategoryStatusBreadcrumbTab({ current, complete, label }) {
  const isUpcoming = !complete && !current
  return (
    <div
      className={classnames('category-status-container', { current, complete })}
      aria-current={current ? 'true' : null}
    >
      <label
        className={classnames({
          'is-current': current,
          'is-complete': complete,
          'is-upcoming': isUpcoming,
        })}
      >
        {isUpcoming && <>{label}</>}
        {current && <></>}
        {complete && (
          <>
            <CheckmarkIcon aria-hidden="true" />
            {label}
          </>
        )}
      </label>
      {current && <span className="current-stage">{label}</span>}
      {complete && <span className="visually-hidden">{label}</span>}
      {isUpcoming && <span className="visually-hidden">{label}</span>}
      <div className="visual-border" />
    </div>
  )
}

CategoryStatusBreadcrumbTab.propTypes = exact(propTypes)
CategoryStatusBreadcrumbTab.defaultProps = defaultProps

export default React.memo(CategoryStatusBreadcrumbTab)
