// sorts options for a select input alphabetically (ascending)
// default option has case for sensitivity, meaning only strings that differ
// in base letters or case compare as unequal ie. a !== b, a !== A, a === á
// note: locale/language is set to `en`
import { every, get } from 'lodash'

function sortSelectOptions(
  selectOptions,
  key = null, // object property to sort array by
  sortOptions = { sensitivity: 'case' }
) {
  const isOptionsArrayOfObjects = every(
    selectOptions,
    (item) => typeof item === 'object'
  )

  if (isOptionsArrayOfObjects) {
    if (!key) return selectOptions

    // only sort array of objects if given a key
    return selectOptions.sort((a, b) => {
      const optionA = get(a, key)
      const optionB = get(b, key)

      if (!optionA) return 1
      if (!optionB) return -1
      return optionA
        .toString()
        .localeCompare(optionB.toString(), 'en', sortOptions)
    })
  }

  return selectOptions.sort((a, b) =>
    a.toString().localeCompare(b.toString(), 'en', sortOptions)
  )
}

export default sortSelectOptions
