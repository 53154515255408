import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldPropTypes,
  omitLabelProps,
  LabeledField,
  serializeOptions,
} from 'lp-components'
import { withFormikAdapter } from 'utils'
import classNames from 'classnames'

/**
 * Override of lp-components Radio Group to support adding Tooltips to each Radio Group option
 * Each option will have the ability to declare its own tooltip content
 */

const propTypes = {
  ...fieldPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  // Only use ariaLabelledby OR ariaLabel, not both
  ariaLabelledby: PropTypes.string,
  ariaLabel: PropTypes.string,
}

const defaultProps = {
  options: [],
  ariaLabelledby: null,
  ariaLabel: null,
}

const getUniqueId = ({ name, option, id = '' }) => {
  if (!id) return `${name}.${option.value}`
  return `${id}.${option.value}`
}

function Label({ htmlFor, option }) {
  return <label htmlFor={htmlFor}>{option.key}</label>
}

function RadioGroupInput({
  id,
  name,
  value,
  onChange,
  option,
  labelComponent: LabelComponent = Label,
  ...rest
}) {
  const uniqueId = getUniqueId({ name, option, id })
  return (
    <div className="input-wrapper">
      <input
        type="radio"
        {...{
          id: uniqueId,
          name,
          value: option.value,
          checked: value === option.value,
          onChange,
          ...rest,
        }}
      />
      <LabelComponent htmlFor={uniqueId} option={option} />
    </div>
  )
}

function RadioGroup(props) {
  const {
    input: { value, onChange, name },
    meta, // eslint-disable-line no-unused-vars
    options,
    ariaLabelledby,
    ariaLabel,
    radioInputProps,
    className,
    ...rest
  } = omitLabelProps(props)
  const optionObjects = serializeOptions(options)
  return (
    <LabeledField className={classNames('RadioGroup', className)} {...props}>
      <div
        role="radiogroup"
        aria-labelledby={ariaLabelledby}
        aria-label={ariaLabel}
      >
        {optionObjects.map((option) => {
          return (
            <RadioGroupInput
              key={option.value}
              {...{
                name,
                value,
                onChange,
                option,
                ...rest,
                ...radioInputProps,
              }}
            />
          )
        })}
      </div>
    </LabeledField>
  )
}

RadioGroup.propTypes = propTypes
RadioGroup.defaultProps = defaultProps

export default withFormikAdapter()(RadioGroup)
