import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import {
  BackButton,
  FullPageSpinner,
  Spinner,
  Disclosure,
  ActionMenu,
  StatusBadge,
} from 'components'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { displayEin, displayAttribute, formatDate } from 'utils'
import { isEmpty, filter } from 'lodash'
import {
  UploadPlanAgreementModal,
  DownloadNotAvailableModal,
  StartNewRolloverModal,
  ReInitializeDocusignModal,
} from '../components'
import {
  ServicesAgreementStatus,
  TOAST_ERROR_CONTACT_SUPPORT,
  Path,
  AroActionItems,
  SigningMethod,
} from 'config'
import { flashErrorMessage } from 'redux-flash'
import * as actions from '../actions'
import { downloadServicesAgreementFile } from 'api'
import { TabBar } from 'lp-components'
import ServicesAgreementParticipants from './ServicesAgreementParticipants'

const propTypes = {
  fetchServicesAgreementDetails: PropTypes.func.isRequired,
  servicesAgreement: Types.servicesAgreementShow,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  clearServicesAgreementDetails: PropTypes.func.isRequired,
  fetchAvailableInitialInvestments: PropTypes.func.isRequired,
  availableInitialInvestments: PropTypes.arrayOf(
    Types.availableInitialInvestment
  ),
  shouldTriggerServicesAgreementDetailsFetch: PropTypes.bool.isRequired,
  setShouldTriggerServicesAgreementDetailsFetch: PropTypes.func.isRequired,
  setShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
}

const defaultProps = {
  servicesAgreement: null,
  availableInitialInvestments: null,
}

const PARTICIPANTS_INITIAL_FILTERS = {
  status: [],
}

const getActionItems = (
  { status, signingMethod },
  {
    handleDownloadSelect,
    handleUploadSelect,
    handleInitSelect,
    handleResendSelect,
  }
) => {
  const downloadAction = {
    handleSelect: handleDownloadSelect,
    text: AroActionItems.DOWNLOAD_AGREEMENT,
  }
  const uploadAction = {
    handleSelect: handleUploadSelect,
    text: AroActionItems.UPLOAD_SIGNED_AGREEMENT,
  }

  if (status === ServicesAgreementStatus.PENDING_SIGNATURES) {
    if (signingMethod === SigningMethod.GENERATE_DOCUMENT)
      return [downloadAction, uploadAction]
    return [
      downloadAction,
      uploadAction,
      {
        handleSelect: handleResendSelect,
        text: AroActionItems.RESEND_DOCUSIGN_EMAIL,
      },
    ]
  }
  if (status === ServicesAgreementStatus.COMPLETED) {
    return [
      {
        handleSelect: handleInitSelect,
        text: AroActionItems.INITIATE_ROLLOVER,
      },
      {
        externalLink: process.env.REACT_APP_ARO_FUNDING_INSTRUCTIONS_URL,
        text: AroActionItems.VIEW_FUNDING_INSTRUCTIONS,
      },
    ]
  }
  return []
}

const formatAddress = (sectionData, addressType) => {
  let addressComponents = []
  const orderedSuffixes = ['Street', 'State', 'City', 'PostalCode', 'Country']
  orderedSuffixes.forEach((suffix) => {
    const componentValue = sectionData[addressType + suffix]
    if (!componentValue) return
    addressComponents.push(componentValue)
  })

  return addressComponents.join(', ')
}

function PanelContent({
  sectionName,
  companyDetails,
  contactDetails,
  planEIN,
}) {
  const companyAddress = formatAddress(companyDetails, 'billing')
  const contactAddress = formatAddress(contactDetails, 'mailing')
  return (
    <>
      <div className="order-details-container">
        <dl>
          <div className="order-details-column">
            <dt>Company Name</dt>
            <dd>{displayAttribute(companyDetails.name)}</dd>
          </div>
          {planEIN && (
            <div className="order-details-column">
              <dt>Employer Identification Number (EIN)</dt>
              <dd>{planEIN}</dd>
            </div>
          )}
          <div className="order-details-column">
            <dt>Company Phone</dt>
            <dd>{displayAttribute(companyDetails.phone)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Company Website</dt>
            <dd>{displayAttribute(companyDetails.website)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Street Address</dt>
            <dd>{displayAttribute(companyAddress)}</dd>
          </div>
        </dl>
      </div>
      <hr />
      <div className="header-container">
        <h3>{sectionName} Contact Details</h3>
      </div>
      <div className="order-details-container">
        <dl>
          <div className="order-details-column">
            <dt>Contact Name</dt>
            <dd>
              {displayAttribute(
                filter([
                  contactDetails.firstName,
                  contactDetails.lastName,
                ]).join(' ')
              )}
            </dd>
          </div>
          <div className="order-details-column">
            <dt>Title</dt>
            <dd>{displayAttribute(contactDetails.title)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Phone</dt>
            <dd>{displayAttribute(contactDetails.phone)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Email</dt>
            <dd>{displayAttribute(contactDetails.email)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Street Address</dt>
            <dd>{displayAttribute(contactAddress)}</dd>
          </div>
        </dl>
      </div>
    </>
  )
}

function NoDetailsProvidedCard({ sectionName }) {
  return (
    <div className="card no-details">
      <h2 className="section-header">{sectionName} Details</h2>
      <p className="not-provided">Not provided</p>
    </div>
  )
}

const Tab = {
  AGREEMENT: 'agreement',
  PARTICIPANTS: 'participants',
}

function PlanAgreementDetails({
  fetchServicesAgreementDetails,
  servicesAgreement,
  flashErrorMessageHandler,
  clearServicesAgreementDetails,
  fetchAvailableInitialInvestments,
  availableInitialInvestments,
  shouldTriggerServicesAgreementDetailsFetch,
  setShouldTriggerServicesAgreementDetailsFetch,
  setShouldTriggerAllServicesAgreementsFetch,
}) {
  const history = useHistory()
  const [errorExists, setErrorExists] = useState(false)
  const [showStartNewRolloverModal, setShowStartNewRolloverModal] =
    useState(false)
  const [showDownloadNotAvailableModal, setShowDownloadNotAvailableModal] =
    useState(false)
  const [showResendModal, setShowResendModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { servicesAgreementId } = useParams()
  const [currentTab, setCurrentTab] = useState(Tab.AGREEMENT)
  const [participantsFilters, setParticipantsFilters] = useState(
    PARTICIPANTS_INITIAL_FILTERS
  )
  const [participantsSearch, setParticipantsSearch] = useState('')

  useEffect(() => {
    return () => {
      clearServicesAgreementDetails()
      setShouldTriggerServicesAgreementDetailsFetch(true)
    }
  }, [])

  useEffect(() => {
    async function getInitialInvestments() {
      try {
        await fetchAvailableInitialInvestments()
      } catch {
        setErrorExists(true)
      }
    }
    if (!availableInitialInvestments) getInitialInvestments()
  }, [availableInitialInvestments])

  useEffect(() => {
    async function getPlanAgreementDetails() {
      try {
        await fetchServicesAgreementDetails(servicesAgreementId)
        setShouldTriggerServicesAgreementDetailsFetch(false)
      } catch {
        setErrorExists(true)
      }
    }
    if (shouldTriggerServicesAgreementDetailsFetch) getPlanAgreementDetails()
  }, [shouldTriggerServicesAgreementDetailsFetch])

  const isLoading =
    (!servicesAgreement || !availableInitialInvestments) && !errorExists

  if (isLoading) return <FullPageSpinner />
  if (errorExists) {
    flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    return <Redirect to={Path.SERVICES_AGREEMENTS} />
  }

  const {
    name,
    portalAgreementStatus,
    createdDate,
    planEIN,
    planNumber,
    portalAgreementType,
    planType,
    planStatus,
    providerPlanId,
    planSponsorCompany,
    planSponsorContact,
    consultantCompany,
    consultantContact,
    TPACompany,
    TPAContact,
    recordKeeperCompany,
    recordKeeperContact,
    signingMethod,
  } = servicesAgreement

  const actionItems = getActionItems(
    {
      status: portalAgreementStatus,
      signingMethod,
    },
    {
      handleDownloadSelect: async () => {
        try {
          await downloadServicesAgreementFile(servicesAgreementId, name)
        } catch (e) {
          if (e.status === 422) setShowDownloadNotAvailableModal(true)
          else setErrorExists(true)
        }
      },
      handleUploadSelect: () => setShowUploadModal(true),
      handleInitSelect: () => setShowStartNewRolloverModal(true),
      handleResendSelect: () => setShowResendModal(true),
    }
  )
  const displayPlanEinValue = displayEin(planEIN)

  const isBenefitDistributionsDetails = location.pathname.includes(
    'benefit-distributions'
  )

  return (
    <div className="services-agreement-details-view">
      <div className="header-bar">
        <BackButton
          link={(location) => {
            return {
              pathname: isBenefitDistributionsDetails
                ? Path.BD_SERVICES_AGREEMENTS
                : Path.SERVICES_AGREEMENTS,
              search: location.state?.search,
            }
          }}
        >
          Back to all agreements
        </BackButton>
        <div className="header-bar-content">
          <h1 className="page-title">{name}</h1>
          {!isEmpty(actionItems) && (
            <ActionMenu
              items={actionItems}
              ariaLabel="View services agreement action items"
            />
          )}
        </div>
      </div>
      {portalAgreementStatus === ServicesAgreementStatus.COMPLETED && (
        <TabBar
          options={[
            { key: 'Agreement Details', value: Tab.AGREEMENT },
            { key: 'Participants', value: Tab.PARTICIPANTS },
          ]}
          value={currentTab}
          onChange={setCurrentTab}
        />
      )}
      {currentTab === Tab.AGREEMENT && (
        <>
          {shouldTriggerServicesAgreementDetailsFetch && <Spinner />}
          {isBenefitDistributionsDetails ? ( // BD Agreement Details
            <div className="card">
              <div className="header-container">
                <h2>Services Agreement Details</h2>
              </div>
              <div className="order-details-container">
                <dl>
                  <div className="order-details-column">
                    <dt>Participant Name</dt>
                    <dd>{displayAttribute(name)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Status</dt>
                    <dd>
                      <StatusBadge status={portalAgreementStatus} />
                    </dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Funding Method</dt>
                    <dd>{displayAttribute(portalAgreementType)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Completion Date</dt>
                    <dd>{displayAttribute(formatDate(createdDate))}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Distribution Amount</dt>
                    <dd>{displayAttribute('$8,000.00')}</dd>
                  </div>
                </dl>
              </div>
            </div>
          ) : (
            // ARO Agreement Details
            <div className="card">
              <div className="header-container">
                <h2>Services Agreement Details</h2>
              </div>
              <div className="order-details-container">
                <dl>
                  <div className="order-details-column">
                    <dt>Plan Name</dt>
                    <dd>{displayAttribute(name)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Agreement Status</dt>
                    <dd>
                      <StatusBadge status={portalAgreementStatus} />
                    </dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Date Created</dt>
                    <dd>{displayAttribute(formatDate(createdDate))}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Three-digit Plan Number (PN)</dt>
                    <dd>{displayAttribute(planNumber)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Provider Plan ID #</dt>
                    <dd>{displayAttribute(providerPlanId)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Initial Investment</dt>
                    <dd>{displayAttribute(portalAgreementType)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Plan Type</dt>
                    <dd>{displayAttribute(planType)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Plan Status</dt>
                    <dd>{displayAttribute(planStatus)}</dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
          <div className="optional-section">
            <div className="accordion-container">
              {isEmpty(planSponsorCompany) && isEmpty(planSponsorContact) ? (
                <NoDetailsProvidedCard sectionName="Plan Sponsor" />
              ) : (
                <Disclosure toggleText="Plan Sponsor Details" headingLevel={2}>
                  <PanelContent
                    sectionName="Plan Sponsor"
                    companyDetails={planSponsorCompany}
                    contactDetails={planSponsorContact}
                    planEIN={displayPlanEinValue}
                  />
                </Disclosure>
              )}
              {!isEmpty(TPACompany) && !isEmpty(TPAContact) ? (
                <Disclosure toggleText="TPA Details" headingLevel={2}>
                  <PanelContent
                    sectionName="TPA"
                    companyDetails={TPACompany}
                    contactDetails={TPAContact}
                  />
                </Disclosure>
              ) : !isEmpty(consultantCompany) && !isEmpty(consultantContact) ? (
                <Disclosure toggleText="Consultant Details" headingLevel={2}>
                  <PanelContent
                    sectionName="Consultant"
                    companyDetails={consultantCompany}
                    contactDetails={consultantContact}
                  />
                </Disclosure>
              ) : (
                <NoDetailsProvidedCard sectionName="TPA" />
              )}
              {isEmpty(recordKeeperCompany) && isEmpty(recordKeeperContact) ? (
                <NoDetailsProvidedCard sectionName="Recordkeeper" />
              ) : (
                <Disclosure toggleText="Recordkeeper Details" headingLevel={2}>
                  <PanelContent
                    sectionName="Recordkeeper"
                    companyDetails={recordKeeperCompany}
                    contactDetails={recordKeeperContact}
                  />
                </Disclosure>
              )}
            </div>
          </div>
        </>
      )}
      {currentTab === Tab.PARTICIPANTS && (
        <ServicesAgreementParticipants
          isBenefitDistributionsDetails={isBenefitDistributionsDetails}
          servicesAgreementId={servicesAgreementId}
          baseFilters={PARTICIPANTS_INITIAL_FILTERS}
          filters={participantsFilters}
          handleFilter={(filters) => setParticipantsFilters(filters)}
          search={participantsSearch}
          handleSearch={(q) => setParticipantsSearch(q)}
        />
      )}
      {showUploadModal && (
        <UploadPlanAgreementModal
          onClose={() => setShowUploadModal(false)}
          servicesAgreement={servicesAgreement}
          onSubmitSuccess={() => {
            setShowUploadModal(false)
            setShouldTriggerServicesAgreementDetailsFetch(true)
            setShouldTriggerAllServicesAgreementsFetch(true)
          }}
        />
      )}
      {showDownloadNotAvailableModal && (
        <DownloadNotAvailableModal
          onClose={() => setShowDownloadNotAvailableModal(false)}
        />
      )}
      {showStartNewRolloverModal && (
        <StartNewRolloverModal
          onClose={() => setShowStartNewRolloverModal(false)}
          onContinue={() =>
            history.push({
              pathname: '/automatic-rollovers/upload-file',
              state: {
                hasConfirmed: true,
              },
            })
          }
        />
      )}
      {showResendModal && (
        <ReInitializeDocusignModal
          agreementId={servicesAgreementId}
          onClose={() => setShowResendModal(false)}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    servicesAgreement: selectors.servicesAgreement(state),
    availableInitialInvestments: selectors.availableInitialInvestments(state),
    shouldTriggerServicesAgreementDetailsFetch:
      selectors.shouldTriggerServicesAgreementDetailsFetch(state),
  }
}

const mapDispatchToProps = {
  fetchServicesAgreementDetails: apiActions.fetchServicesAgreementDetails,
  flashErrorMessageHandler: flashErrorMessage,
  clearServicesAgreementDetails: actions.clearServicesAgreementDetails,
  fetchAvailableInitialInvestments: apiActions.fetchAvailableInitialInvestments,
  setShouldTriggerServicesAgreementDetailsFetch:
    actions.setShouldTriggerServicesAgreementDetailsFetch,
  setShouldTriggerAllServicesAgreementsFetch:
    actions.setShouldTriggerAllServicesAgreementsFetch,
}

PlanAgreementDetails.propTypes = propTypes
PlanAgreementDetails.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PlanAgreementDetails
)
