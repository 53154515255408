const TIMER_START_DATE_KEY = 'timer-start-date'

export function setTimerStartDate(timerStartDate) {
  return localStorage.setItem(TIMER_START_DATE_KEY, timerStartDate)
}

export function getTimerStartDate() {
  return new Date(localStorage.getItem(TIMER_START_DATE_KEY))
}

// Url to redirect back to after login - when user is logged out due to inactivity
const TIMEOUT_REDIRECT_KEY = 'redirectBackTo'
export const setTimeoutRedirect = (value) =>
  sessionStorage.setItem(TIMEOUT_REDIRECT_KEY, value)
export const getTimeoutRedirect = () =>
  sessionStorage.getItem(TIMEOUT_REDIRECT_KEY)
export const clearTimeoutRedirect = () =>
  sessionStorage.removeItem(TIMEOUT_REDIRECT_KEY)

// Sets an array of strings (uuid of AccordionItem) to be expanded within the participant file upload view
// https://github.com/springload/react-accessible-accordion#preexpanded-string-optional-default-
const PARTICIPANT_FILE_UPLOAD_EXPANDED_ITEMS_KEY =
  'participant-file-upload-expanded-items'
export const setParticipantFileUploadExpandedItems = (array) =>
  localStorage.setItem(
    PARTICIPANT_FILE_UPLOAD_EXPANDED_ITEMS_KEY,
    JSON.stringify(array)
  )
export const getParticipantFileUploadExpandedItems = () =>
  JSON.parse(localStorage.getItem(PARTICIPANT_FILE_UPLOAD_EXPANDED_ITEMS_KEY))
export const clearParticipantFileUploadExpandedItems = () =>
  localStorage.removeItem(PARTICIPANT_FILE_UPLOAD_EXPANDED_ITEMS_KEY)
