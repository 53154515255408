/**
 * @name convertToSearchParams
 * @param {Object}
 * @returns {String}
 */

import { forIn, isArray, isDate, isEmpty, isNil } from 'lodash'
import { format } from 'date-fns'

function convertToSearchParams(obj) {
  let params = new URLSearchParams()

  forIn(obj, (value, key) => {
    if (isBlank(value)) return

    const valueToSet = normalizeValue(value)

    if (Array.isArray(valueToSet)) {
      valueToSet.forEach((item) => {
        params.append(key, item)
      })
    } else {
      params.append(key, valueToSet)
    }
  })

  return params.toString()
}

// ---- PRIVATE ----
function normalizeValue(value) {
  if (Array.isArray(value)) return value.map(normalizeValue)
  if (isDate(value)) return format(value, 'yyyy-MM-dd')

  return value
}

function isBlank(value) {
  if (isArray(value)) return isEmpty(value)
  return isNil(value) || value === ''
}

export default convertToSearchParams
