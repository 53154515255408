import { mapValues } from 'lodash'

function convertNullToEmptyString(res) {
  if (res instanceof Array) {
    return res.map((el) => convertNullToEmptyString(el))
  }
  if (res instanceof Object) {
    return mapValues(res, (val) => convertNullToEmptyString(val))
  }
  return res ?? ''
}

export default convertNullToEmptyString
