import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as Types from 'types'
import { Icon as SortIcon } from 'images/utility-icons/sort-white.svg'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuLink,
  MenuItem,
} from '@reach/menu-button'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      Types.actionMenuItem,
      Types.actionMenuLink,
      Types.actionMenuExternalLink,
    ])
  ).isRequired,
  ariaLabel: PropTypes.string,
}

const defaultProps = {
  ariaLabel: 'View action items',
}

function ActionMenu({ items, ariaLabel }) {
  return (
    <div className="action-menu">
      <Menu>
        {({ isExpanded }) => {
          return (
            <>
              <MenuButton
                className={classNames('button-primary', { open: isExpanded })}
                aria-label={ariaLabel}
              >
                Actions
                <SortIcon />
              </MenuButton>
              <MenuList className="action-menu-dropdown-content">
                {items.map((item) => {
                  if (item.link) {
                    return (
                      <MenuLink key={item.text} as={Link} to={item.link}>
                        {item.text}
                      </MenuLink>
                    )
                  }
                  if (item.externalLink) {
                    return (
                      <MenuLink
                        key={item.text}
                        target="_blank"
                        href={item.externalLink}
                        className="menu-item"
                      >
                        {item.text}
                      </MenuLink>
                    )
                  }
                  return (
                    <MenuItem
                      key={item.text}
                      onSelect={item.handleSelect}
                      className="menu-item"
                    >
                      {item.text}
                    </MenuItem>
                  )
                })}
              </MenuList>
            </>
          )
        }}
      </Menu>
    </div>
  )
}

ActionMenu.propTypes = exact(propTypes)
ActionMenu.defaultProps = defaultProps

export default React.memo(ActionMenu)
