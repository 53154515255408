import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ScrollErrorForm, ConfirmationCheckbox, ConfirmModal } from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FlowActions } from '../components'
import * as Types from 'types'
import { isEmpty } from 'lodash'
import PaymentFields, { paymentSchema } from 'components/PaymentFields'
import { E_SIGNATURE_CONFIRMATION_TEXT, COMPANY_NAME } from 'config'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentEnabled: PropTypes.bool.isRequired,
  currentUser: Types.user.isRequired,
  paymentMethod: Types.paymentMethod,
  onConfirmDeletePayment: PropTypes.func.isRequired,
  isDeletingPaymentMethod: PropTypes.bool.isRequired,
  hasBalance: PropTypes.bool.isRequired,
  isMockedPayment: PropTypes.bool.isRequired,
}

const defaultProps = {}

const formatAddressProperty = (property, stringSeparator = ' ') => {
  return property ? property + stringSeparator : ''
}

function formatBillingAddress({ name, address1, city, state, zipCode }) {
  const billingName = formatAddressProperty(name, ', ')
  const billingAddress = formatAddressProperty(address1)
  const billingCity = formatAddressProperty(city)
  const billingState = formatAddressProperty(state)
  const billingZip = formatAddressProperty(zipCode)
  return billingName + billingAddress + billingCity + billingState + billingZip
}

function ConfirmSubmissionForm({
  onSubmit,
  initialValues,
  paymentEnabled,
  currentUser,
  paymentMethod,
  onConfirmDeletePayment,
  isDeletingPaymentMethod,
  hasBalance,
  isMockedPayment,
}) {
  const [showRemovePaymentModal, setShowRemovePaymentModal] = useState(false)
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const hasPaymentMethod = !isEmpty(paymentMethod)
  const isPaymentRequired = paymentEnabled && !hasPaymentMethod
  const confirmSubmissionSchema = Yup.object({
    confirm: Yup.boolean()
      .required('This checkbox must be checked in order to submit search')
      .oneOf([true], 'This checkbox must be checked in order to submit search'),
    paymentDetails: Yup.lazy(() => {
      if (isPaymentRequired) return paymentSchema
      return Yup.object().nullable()
    }),
    confirmPayment: Yup.lazy(() => {
      if (paymentEnabled && hasBalance)
        return Yup.boolean().required('Required').oneOf([true], 'Required')
      return Yup.boolean().nullable()
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={confirmSubmissionSchema}
    >
      {({ isSubmitting }) => (
        <ScrollErrorForm>
          <>
            {paymentEnabled && hasBalance && (
              <>
                <div className="form-header">
                  <div id="payment-details-header">
                    <h3 className="flow-section-headers">Payment Details</h3>
                    {hasPaymentMethod && !isMockedPayment && (
                      <button
                        className="replace-payment-button"
                        aria-label="Delete payment method"
                        onClick={(e) => {
                          e.preventDefault()
                          setShowRemovePaymentModal(true)
                        }}
                      >
                        Replace
                      </button>
                    )}
                    {hasPaymentMethod && isMockedPayment && (
                      <button
                        className="replace-payment-button"
                        aria-label="Add payment method"
                        onClick={() => setShowAddPaymentModal(true)}
                      >
                        Add New Card
                      </button>
                    )}
                  </div>
                </div>
                {hasPaymentMethod &&
                  !isDeletingPaymentMethod &&
                  !isMockedPayment && (
                    <section className="confirm-order-details">
                      <dl>
                        <div>
                          <dt>Credit or Debit Card</dt>
                          <dd>{`${paymentMethod.cardType}: ${
                            paymentMethod.creditCardLastFour
                              ? paymentMethod.creditCardLastFour
                              : paymentMethod.cardLabel
                          }`}</dd>
                        </div>
                        <div className="full-row">
                          <dt>Billing Address</dt>
                          <dd>{formatBillingAddress(paymentMethod)}</dd>
                        </div>
                      </dl>
                    </section>
                  )}
                {showRemovePaymentModal && !isMockedPayment && (
                  <ConfirmModal
                    onClose={() => setShowRemovePaymentModal(false)}
                    onConfirm={() => {
                      setShowRemovePaymentModal(false)
                      onConfirmDeletePayment()
                    }}
                    confirmContent="Yes, Replace"
                    className="remove-payment-modal"
                  >
                    <h2>Replace Payment Details</h2>
                    <p>
                      Please note - you can only have one card on file.
                      Replacing it here will replace it across the platform.
                    </p>
                  </ConfirmModal>
                )}
                {showAddPaymentModal && hasPaymentMethod && isMockedPayment && (
                  <ConfirmModal
                    onClose={() => setShowAddPaymentModal(false)}
                    onConfirm={() => {
                      setShowAddPaymentModal(false)
                      onConfirmDeletePayment()
                    }}
                    confirmContent="Yes"
                    className="remove-payment-modal"
                  >
                    <h2>Add Payment Details</h2>
                  </ConfirmModal>
                )}
                {!hasPaymentMethod && !isDeletingPaymentMethod && (
                  <section className="confirm-order-details">
                    <div id="add-new-payment-form">
                      <PaymentFields sectionName="paymentDetails" />
                    </div>
                  </section>
                )}
                {!isMockedPayment && (
                  <div className="payment-confirmation">
                    <ConfirmationCheckbox
                      name="confirmPayment"
                      label={`I authorize ${COMPANY_NAME.long} to charge my credit card for the total amount of this purchase for search services. I understand that this charge will appear on my credit card statement as ${COMPANY_NAME.long}. I further certify that I am the authorized user of the credit card and that all information provided is true and accurate.`}
                    />
                  </div>
                )}
              </>
            )}
            <div className="form-header" id="e-signature-header-container">
              <h3 className="flow-section-headers">E-Signature</h3>
            </div>
            <section className="confirm-order-details">
              <dl>
                <div className="user-details">
                  <dt>First Name</dt>
                  <dd>{currentUser.firstName}</dd>
                </div>
                <div className="user-details">
                  <dt>Last Name</dt>
                  <dd>{currentUser.lastName}</dd>
                </div>
                <div className="user-details">
                  <dt>Title</dt>
                  <dd>{currentUser.title}</dd>
                </div>
              </dl>
            </section>
            <div className="e-signature">
              <ConfirmationCheckbox
                name="confirm"
                label={E_SIGNATURE_CONFIRMATION_TEXT}
              />
            </div>
          </>
          <FlowActions isSubmitting={isSubmitting} submitContent="Submit" />
        </ScrollErrorForm>
      )}
    </Formik>
  )
}

ConfirmSubmissionForm.propTypes = propTypes
ConfirmSubmissionForm.defaultProps = defaultProps

export default ConfirmSubmissionForm
