import React from 'react'
import exact from 'prop-types-exact'
import * as Types from 'types'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AVAILABLE_STATUSES } from 'config'
import { getProductConfig } from 'utils'
import { startsWith } from 'lodash'
import { ExternalLink } from 'components'

const propTypes = {
  subscribedProducts: PropTypes.arrayOf(Types.subscribedProduct).isRequired,
  headingAs: PropTypes.oneOf(['h1', 'h2']),
}

const defaultProps = {
  headingAs: 'h2',
}

const isAvailable = (product) => {
  return AVAILABLE_STATUSES.includes(product.status)
}
const isInternal = (link) => {
  return startsWith(link, '/')
}
const ProductAffordance = ({ link, product }) => {
  if (isAvailable(product)) {
    if (isInternal(link)) {
      return (
        <Link to={link} className="button-primary-outline">
          Open
        </Link>
      )
    }
    return (
      <ExternalLink link={link} className="button-primary-outline">
        Open
      </ExternalLink>
    )
  }
  return (
    <span>
      Pending Authorization
      {product.signerName && ` by ${product.signerName}`}
    </span>
  )
}

function ProductPortal({ subscribedProducts, headingAs: HeadingComp }) {
  if (!subscribedProducts.length) {
    return (
      <div className="product-portal">
        <p>You currently have no subscribed products</p>
      </div>
    )
  }
  return (
    <div className="product-portal">
      <HeadingComp>Your Product(s)</HeadingComp>
      {subscribedProducts.map((product) => {
        const { icon: Icon, productLink } = getProductConfig(product.type)
        if (!Icon) {
          // eslint-disable-next-line
          console.error(`No config found for: ${product.type}`)
          return null
        }
        return (
          <div key={product.type} className="product-portal-container">
            <div className="product-portal-header">
              <Icon width="47" height="47" aria-hidden="true" />
              <p className="product-type">{product.type}</p>
              <ProductAffordance link={productLink} product={product} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

ProductPortal.propTypes = exact(propTypes)
ProductPortal.defaultProps = defaultProps

export default React.memo(ProductPortal)
