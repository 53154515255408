function downloadFile(data, { fileName, mimeType }) {
  const blob = new Blob([data], { type: mimeType })
  const encodedUrl = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.setAttribute('href', encodedUrl)
  link.setAttribute('download', fileName)
  document.body.appendChild(link) // required for Firefox

  link.click()
  document.body.removeChild(link)
}

export default downloadFile
