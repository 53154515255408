import { toString, isEmpty, lowerCase, reduce, uniqBy, concat } from 'lodash'

const POTENTIAL_MATCHES = {
  EXACT: 'potentialExactMatches',
  LIKELY: 'potentialLikelyMatches',
}

// Find exact or likely matches for each unique plan (used in ARO plan mapping step)
function getMatchesForPlans(recordsByPlan, allPlans) {
  return reduce(
    recordsByPlan,
    (matchesByPlan, records, planName) => {
      const planMatches = records.reduce(
        (matchHash, record) => {
          const addMatches = (matches, category) => {
            if (!matches) return
            matchHash[category] = uniqBy(
              concat(matchHash[category], matches),
              'serviceAgreementID'
            )
          }
          // Add exact matches by plan ein & 3-digit plan number
          addMatches(
            getExactMatchesByEinAndNumber(record, allPlans),
            POTENTIAL_MATCHES.EXACT
          )
          // Add exact matches by plan ein & plan name
          addMatches(
            getExactMatchesByEinAndName(record, allPlans),
            POTENTIAL_MATCHES.EXACT
          )
          // Add exact matches by plan name & plan sponsor name
          addMatches(
            getExactMatchesByNameAndSponsor(record, allPlans),
            POTENTIAL_MATCHES.EXACT
          )
          // If there is at least one exact match found from above, skip finding likely matches
          if (matchHash.potentialExactMatches.length) return matchHash

          // Add likely matches by plan name
          addMatches(
            getLikelyMatchesByName(record, allPlans),
            POTENTIAL_MATCHES.LIKELY
          )
          // If there is at least one likely match by plan name, skip finding likely matches by ein or sponsor
          if (matchHash.potentialLikelyMatches.length) return matchHash

          // Add likely matches by plan ein
          addMatches(
            getLikelyMatchesByEin(record, allPlans),
            POTENTIAL_MATCHES.LIKELY
          )
          // Add likely matches by plan sponsor name
          addMatches(
            getLikelyMatchesBySponsor(record, allPlans),
            POTENTIAL_MATCHES.LIKELY
          )

          return matchHash
        },
        {
          [POTENTIAL_MATCHES.EXACT]: [],
          [POTENTIAL_MATCHES.LIKELY]: [],
        }
      )

      // For a match to be truly "exact", there can only be one unique match.
      // Therefore, if there is more than 1 "exact" match found for a given plan after iterating
      // every record that belongs to the plan, store them as "likely" matches instead
      const { potentialExactMatches, potentialLikelyMatches } = planMatches
      if (potentialExactMatches.length === 1) {
        matchesByPlan[planName] = {
          exactMatch: potentialExactMatches[0],
        }
      } else {
        const likelyMatches = uniqBy(
          concat(potentialExactMatches, potentialLikelyMatches),
          'serviceAgreementID'
        )
        matchesByPlan[planName] = likelyMatches.length
          ? {
              likelyMatches,
            }
          : {}
      }

      return matchesByPlan
    },
    {}
  )
}

// ----- PRIVATE -----

// Find exact match(es) by plan ein & 3-digit plan number
const getExactMatchesByEinAndNumber = (record, plans) => {
  if (!record.planEin || !record.planNumber) return

  const standardizedPlanEin = standardizeNumber(record.planEin)
  const standardizedPlanNumber = standardizeNumber(record.planNumber)

  return plans.filter(({ planEIN, planNumber }) => {
    if (!planEIN || !planNumber) return false
    return (
      standardizeNumber(planEIN) === standardizedPlanEin &&
      standardizeNumber(planNumber) === standardizedPlanNumber
    )
  })
}

// Find exact match(es) by plan ein & plan name
const getExactMatchesByEinAndName = (record, plans) => {
  if (!record.planEin || !record.planName) return

  const standardizedPlanEin = standardizeNumber(record.planEin)
  const standardizedPlanName = standardizeString(record.planName)

  return plans.filter(({ planEIN, name }) => {
    if (!planEIN || !name) return false
    return (
      standardizeNumber(planEIN) === standardizedPlanEin &&
      standardizeString(name) === standardizedPlanName
    )
  })
}

// Find exact match(es) by plan name & plan sponsor name
const getExactMatchesByNameAndSponsor = (record, plans) => {
  if (!record.planName || !record.planSponsor) return

  const standardizedPlanName = standardizeString(record.planName)
  const standardizedPlanSponsorName = standardizeString(record.planSponsor)

  return plans.filter(({ name, planSponsorCompany }) => {
    if (!name || isEmpty(planSponsorCompany) || !planSponsorCompany.name)
      return false
    return (
      standardizeString(name) === standardizedPlanName &&
      standardizeString(planSponsorCompany.name) === standardizedPlanSponsorName
    )
  })
}

// Find likely matches by plan name
const getLikelyMatchesByName = (record, plans) => {
  if (!record.planName) return

  const standardizedPlanName = standardizeString(record.planName)

  return plans.filter(({ name }) => {
    if (!name) return false
    return standardizeString(name) === standardizedPlanName
  })
}

// Find likely matches by plan ein
const getLikelyMatchesByEin = (record, plans) => {
  if (!record.planEin) return

  const standardizedPlanEin = standardizeNumber(record.planEin)

  return plans.filter(({ planEIN }) => {
    if (!planEIN) return false
    return standardizeNumber(planEIN) === standardizedPlanEin
  })
}

// Find likely matches by plan sponsor name
const getLikelyMatchesBySponsor = (record, plans) => {
  if (!record.planSponsor) return

  const standardizedPlanSponsorName = standardizeString(record.planSponsor)

  return plans.filter(({ planSponsorCompany }) => {
    if (isEmpty(planSponsorCompany) || !planSponsorCompany.name) return false
    return (
      standardizeString(planSponsorCompany.name) === standardizedPlanSponsorName
    )
  })
}

// Remove non-digit characters from numeric input (e.g., "33-1010101" -> "331010101")
const standardizeNumber = (input) => {
  return toString(input)
    .match(/[0-9]/g)
    ?.join('')
}
// Convert string input to lower case (e.g., "Cool-plan  " => "cool plan")
const standardizeString = (input) => {
  return lowerCase(input)
}

export default getMatchesForPlans
