import React from 'react'
import PropTypes from 'prop-types'
import { TableColumn } from 'lp-components'
import { PaginatedAndSortableTable, TableHeading } from 'components'
import * as Types from 'types'

const propTypes = {
  data: PropTypes.arrayOf(Types.searchParticipant),
}

const defaultProps = {}

function SearchRecordsSummaryTable({ data }) {
  return (
    <div className="search-records-summary-table-container">
      <TableHeading title="Search Records File Summary" />
      <PaginatedAndSortableTable
        data={data}
        disableSort={true}
        tableId="search-records-summary-table"
      >
        <TableColumn name="planSponsorName" label="Plan Fiduciary Name" />
        <TableColumn name="planName" label="Plan Name" />
        <TableColumn
          name="planEin"
          label="Employer Identification Number (EIN)"
        />
        <TableColumn name="planNumber" label="Three-digit plan number (PN)" />
        <TableColumn
          name="ssn"
          label="Participant SSN"
          format={() => 'xxx-xx-xxxxx'} // format sensitive information
        />
        <TableColumn name="firstName" label="Participant First Name" />
        <TableColumn name="middleName" label="Participant Middle Name" />
        <TableColumn name="lastName" label="Participant Last Name" />
        <TableColumn name="address1" label="Participant Address 1" />
        <TableColumn name="address2" label="Participant Address 2" />
        <TableColumn name="address3" label="Participant Address 3" />
        <TableColumn name="staet" label="Participant State" />
        <TableColumn name="zip" label="Participant Zip" />
        <TableColumn name="dob" label="Participant DOB" />
        <TableColumn name="dod" label="Participant DOD" />
        <TableColumn name="phone" label="Phone Number" />
        <TableColumn name="workFor" label="Work For" />
        <TableColumn name="reason" label="Reason" />
      </PaginatedAndSortableTable>
    </div>
  )
}

SearchRecordsSummaryTable.propTypes = propTypes
SearchRecordsSummaryTable.defaultProps = defaultProps

export default SearchRecordsSummaryTable
