import React, { useEffect, useMemo } from 'react'
import { usePrevious } from 'react-use'
import { withFormikAdapter } from 'utils'
import { DateInput as BaseDateInput } from 'lp-components'
import { parseISO, isAfter, differenceInDays, addDays } from 'date-fns'

function parseDate(value) {
  if (!value || value instanceof Date) return value
  return parseISO(value)
}

function DateInput({ selectsEnd, startDate, endDate, minDate, ...rest }) {
  const parsedStartDate = useMemo(() => parseDate(startDate), [startDate])
  const parsedEndDate = useMemo(() => parseDate(endDate), [endDate])
  const prevParsedStartDate = usePrevious(parsedStartDate)

  useEffect(() => {
    if (!selectsEnd || !parsedStartDate || !parsedEndDate) return

    if (isAfter(parsedStartDate, parsedEndDate)) {
      // Clear the end date if it was selected _before_ the start date
      if (!prevParsedStartDate) rest.input.onChange('')

      const diffInDays = differenceInDays(parsedEndDate, prevParsedStartDate)
      if (diffInDays > 0) {
        const newValue = addDays(parsedStartDate, diffInDays)
        rest.input.onChange(newValue)
      }
    }
  }, [parsedStartDate, parsedEndDate, selectsEnd])

  return (
    <BaseDateInput
      startDate={parsedStartDate}
      endDate={parsedEndDate}
      minDate={parseDate(minDate)}
      isClearable={true}
      selectsEnd={selectsEnd}
      placeholderText="MM/DD/YYYY"
      {...rest}
    />
  )
}

export default withFormikAdapter()(DateInput)
