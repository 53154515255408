import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { AddNewServicesAgreementForm } from '../forms'
import { useHistory } from 'react-router-dom'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Path } from 'config'
import { isEqual } from 'lodash'
import { selectors as globalSelectors } from '../../reducer'

const propTypes = {
  completeServicesAgreementForm: Types.completeServicesAgreementForm,
  resetErisapediaSearches: PropTypes.func.isRequired,
  setCompleteServicesAgreementForm: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
}

const defaultProps = {
  completeServicesAgreementForm: {
    planDetails: {
      name: '',
      ein: '',
    },
    signingMethod: '',
    option: '',
  },
}

function AddNewServicesAgreementView({
  completeServicesAgreementForm,
  resetErisapediaSearches,
  setCompleteServicesAgreementForm,
  currentUser,
}) {
  const history = useHistory()

  const handleSubmit = (values) => {
    const { planDetails: existingPlanDetails } = completeServicesAgreementForm
    const hasExistingValues = !!existingPlanDetails.ein

    if (
      hasExistingValues &&
      !isEqual(existingPlanDetails, values.planDetails)
    ) {
      resetErisapediaSearches()
      setCompleteServicesAgreementForm({ ...values, option: '' })
    } else {
      setCompleteServicesAgreementForm(values)
    }

    history.push({
      pathname: Path.SELECT_COMPANY_INFORMATION,
      state: {
        search: history.location.state?.search,
      },
    })
  }

  return (
    <div className="card full-height complete-services-agreement-view">
      <div className="complete-services-agreement-form-container">
        <div className="form-masthead">
          <h2>Identify Plan and Signature Preference</h2>
        </div>
        <div className="form-section form-section--with-top-border">
          <AddNewServicesAgreementForm
            initialValues={completeServicesAgreementForm}
            onSubmit={handleSubmit}
            accountAudience={currentUser.accountAudience}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    completeServicesAgreementForm:
      selectors.completeServicesAgreementForm(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  setCompleteServicesAgreementForm: actions.setCompleteServicesAgreementForm,
  resetErisapediaSearches: actions.resetErisapediaSearches,
}

AddNewServicesAgreementView.propTypes = propTypes
AddNewServicesAgreementView.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddNewServicesAgreementView
)
