import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon as BackIcon } from 'images/utility-icons/back-arrow.svg'

const propTypes = {
  link: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

function BackButton({ link, children }) {
  return (
    <div className="back-button-container">
      <Link to={link} className="button-text">
        <BackIcon className="icon-arrow-left" aria-hidden="true" />
        {children}
      </Link>
    </div>
  )
}

BackButton.propTypes = propTypes
BackButton.defaultProps = defaultProps

export default React.memo(BackButton)
