import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import { tippyPlugins } from 'utils'
import { noop } from 'lodash'

const { hideOnEsc, hideOnPopperBlur } = tippyPlugins

const propTypes = {
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  placement: PropTypes.string,
  trigger: PropTypes.string,
  preventHide: PropTypes.bool,
  delay: PropTypes.array,
  aria: PropTypes.object,
  zIndex: PropTypes.number,
  onCreate: PropTypes.func,
  onShow: PropTypes.func,
}

const defaultProps = {
  placement: 'top',
  trigger: 'mouseenter focus',
  preventHide: false,
  delay: [300, 200],
  aria: {
    content: 'describedby',
  },
  zIndex: 9999999,
  onCreate: noop,
  onShow: noop,
}

function BaseTooltip({
  children,
  content,
  placement,
  trigger,
  preventHide,
  delay,
  aria,
  zIndex,
  onCreate,
  onShow,
  ...rest
}) {
  return (
    <Tippy
      content={content}
      placement={placement}
      trigger={trigger}
      plugins={preventHide ? [] : [hideOnEsc, hideOnPopperBlur]} // Prevents the tooltip from hiding on blur & escape when set to true
      hideOnClick={!preventHide} // Must be enabled when preventHide is set to true
      delay={delay}
      aria={aria}
      zIndex={zIndex}
      onCreate={onCreate}
      onShow={onShow}
      interactive={true}
      role="tooltip"
      {...rest}
    >
      {children}
    </Tippy>
  )
}

BaseTooltip.propTypes = propTypes
BaseTooltip.defaultProps = defaultProps

export default React.memo(BaseTooltip)
