import { configureApi, http, HttpError } from 'lp-requests'
import { middleware as configureMiddleware } from 'lp-redux-api'
import { flow, mapValues } from 'lodash'
import { getSessionToken } from './auth'
import { formatUrl, formatDate, downloadFile } from 'utils'
import { COMPANY_NAME } from 'config'

// Configure lp-redux-api middleware

// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before() {
  return {
    bearerToken: getSessionToken(),
  }
}

function replaceValueWithNull(res, valueToReplace = 'Null') {
  if (res instanceof Array) {
    // res is an array of objects
    return res.map((obj) => replaceValueWithNull(obj, valueToReplace))
  } else if (res instanceof Object) {
    // res is an object
    return mapValues(res, (value) =>
      replaceValueWithNull(value, valueToReplace)
    )
  } else if (res === valueToReplace) {
    return null
  } else {
    return res
  }
}

async function simpleFetch(url, { accept }) {
  const formattedUrl = formatUrl(`${process.env.REACT_APP_API_URL}/${url}`)
  return fetch(formattedUrl, {
    method: 'GET',
    headers: {
      Accept: accept,
      Authorization: `Bearer ${getSessionToken()}`,
    },
  }).then((res) => {
    if (!res.ok) throw new HttpError(res.status, res.statusText, res)
    return res
  })
}

async function fetchAndDownload(url, fetchOptions, downloadOptions) {
  return simpleFetch(url, fetchOptions)
    .then((res) => res.arrayBuffer())
    .then((buffer) => downloadFile(buffer, downloadOptions))
}

async function contentTypeFetchAndDownload(url, fetchOptions, downloadOptions) {
  return simpleFetch(url, fetchOptions).then(async (res) => {
    const contentType = res.headers.get('Content-Type')
    const contentTypeClean = contentType.split(';')[0]

    downloadOptions.mimeType = contentType

    if (contentTypeClean === 'application/csv') {
      downloadOptions.mimeType = 'text/csv'
      downloadFile(await res.arrayBuffer(), downloadOptions)
    } else if (
      contentTypeClean === 'application/pdf' ||
      contentTypeClean === 'application/zip'
    ) {
      const blob = new Blob([new Uint8Array(await res.arrayBuffer())])
      downloadFile(blob, downloadOptions)
    }
  })
}

const onSuccess = flow([replaceValueWithNull])

// Any transformations of failed responses can go here.
function onFailure(res) {
  return res
}

export const config = {
  root: process.env.REACT_APP_API_URL,
  before,
  onSuccess,
  onFailure,
  mode: 'cors',
  // Override lp-redux-api default
  // MS API expects requests & responses as camelCase pattern
  // Additionally, abbreviations & acronyms are received or returned as ALL CAPS ie. "TPACompany"
  // APIs with exceptions to this global default should be handled individually where they are defined
  decamelizeBody: false,
  decamelizeQuery: false,
  camelizeResponse: false,
}

export const middleware = configureMiddleware(http, config)
export const api = configureApi(config)

// APIs that require access to the response meta -----------

export const downloadSearchOrderResults = async (id, number) => {
  const url = `/searchOrders/${id}/results`
  const fileName = `${formatDate(Date.now(), {
    formattingString: 'ddMMMY',
  })}_${number}_SEARCH-RESULTS`
  const fetchOptions = {
    accept: [
      'application/csv',
      'application/pdf',
      'application/zip',
      'binary/octet-stream',
    ],
  }
  const downloadOptions = { fileName }

  return contentTypeFetchAndDownload(url, fetchOptions, downloadOptions)
}

export const downloadServicesAgreementFile = async (id, planName) => {
  const url = `/planAgreementFile/${id}`
  const fileName = `${COMPANY_NAME.medium} Automatic Rollover Services Agreement ${planName}`
  const fetchOptions = { accept: 'binary/octet-stream' }
  const downloadOptions = { fileName, mimeType: 'application/pdf' }

  return fetchAndDownload(url, fetchOptions, downloadOptions)
}

export const downloadStableValueFactSheet = async (id, agreementTypeName) => {
  const url = `/availablePlanAgreementTypes/${id}`
  const fileName = `${agreementTypeName} Fact Sheet`
  const fetchOptions = { accept: 'binary/octet-stream' }
  const downloadOptions = { fileName, mimeType: 'application/pdf' }

  return fetchAndDownload(url, fetchOptions, downloadOptions)
}

export const downloadSearchServiceAgreementFile = async (
  serviceAgreementId,
  serviceAgreementName
) => {
  const url = `/searchServicesAgreement/attachment/${serviceAgreementId}`
  const fileName = `${COMPANY_NAME.medium} Search Services Agreement ${serviceAgreementName}`
  const fetchOptions = { accept: 'binary/octet-stream' }
  const downloadOptions = { fileName, mimeType: 'application/pdf' }

  return fetchAndDownload(url, fetchOptions, downloadOptions)
}
