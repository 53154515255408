import React, { useEffect } from 'react'
import { Icon as ErrorIcon } from 'images/error.svg'
import { useHistory } from 'react-router-dom'
import { SupportEmailLink, SupportPhoneLink } from 'components'

const propTypes = {}

const defaultProps = {}

function ErrorPage() {
  useEffect(() => {
    window.appEventData.pop()
    var appEventData = window.appEventData || []
    appEventData.push({
      event: 'Page Load Completed',
    })
  }, [])
  const history = useHistory()
  return (
    <div id="error-page-container">
      <div className="error-state">
        <ErrorIcon aria-hidden="true" />
        <h1 className="error-state__title">Looks like something went wrong</h1>
        <p className="error-state__description">
          An unexpected error has occurred. Please try refreshing the page or
          log out and reconnect. If the issue continues, please contact support
          at <SupportEmailLink /> or call at <SupportPhoneLink />.
        </p>
        <button
          type="button"
          className="button-primary"
          onClick={() => history.goBack()}
        >
          Refresh Page
        </button>
      </div>
    </div>
  )
}

ErrorPage.propTypes = propTypes
ErrorPage.defaultProps = defaultProps

export default ErrorPage
