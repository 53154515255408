import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { ProductPortal } from 'components'

const propTypes = {
  subscribedProducts: PropTypes.arrayOf(Types.subscribedProduct).isRequired,
}

const defaultProps = {}

function Products({ subscribedProducts }) {
  useEffect(() => {
    window.appEventData.pop()
    var appEventData = window.appEventData || []
    appEventData.push({
      event: 'Page Load Completed',
    })
  }, [])
  return (
    <div className="products">
      <ProductPortal subscribedProducts={subscribedProducts} headingAs="h1" />
    </div>
  )
}

Products.propTypes = propTypes

Products.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    subscribedProducts: globalSelectors.orderedSubscribedProducts(state),
    nonSubscribedProductTypes:
      globalSelectors.orderedNonSubscribedProductTypes(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Products)
