import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  MaskedInput,
  RadioGroup,
  ScrollErrorForm,
  LabelWithTooltip,
  PlanEinTooltipContent,
} from 'components'
import { FlowActions } from '../components'
import {
  SigningMethod,
  signingMethodOptions,
  COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT,
  EIN_REGEX,
  AccountAudience,
} from 'config'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Icon as WetSignatureIcon } from 'images/signing-method-icons/wet-signature.svg'
import { Icon as ElectronicSignatureIcon } from 'images/signing-method-icons/electronic-signature.svg'

const propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  accountAudience: PropTypes.oneOf(Object.values(AccountAudience)).isRequired,
}

const defaultProps = {
  initialValues: {},
}

function getSigningMethodIcon(method) {
  switch (method) {
    case SigningMethod.ELECTRONIC_SIGNATURE:
      return ElectronicSignatureIcon
    case SigningMethod.WET_SIGNATURE:
      return WetSignatureIcon
    default:
      return () => null
  }
}

function SigningMethodLabel({ htmlFor, option }) {
  const SignatureIcon = getSigningMethodIcon(option.key)
  return (
    <label htmlFor={htmlFor}>
      <div className="icon-container">
        <SignatureIcon aria-hidden="true" />
      </div>
      <div className="description-container">
        <p className="label">{option.key}</p>
        <p className="description">{option.description}</p>
      </div>
    </label>
  )
}

const signingMethods = signingMethodOptions.filter(
  (option) => option.key !== SigningMethod.E_SIGN_RSP
)
const signingMethodValues = signingMethods.map((option) => option.value)

function AddNewServicesAgreementForm({
  initialValues,
  onSubmit,
  accountAudience,
}) {
  const validationSchema = Yup.object().shape({
    planDetails: Yup.object({
      name: Yup.string()
        .max(80, 'Must be 80 characters or less')
        .required('Required'),
      ein: Yup.string()
        .length(10, 'Must be 9 numeric digits in format XX-XXXXXXX')
        .matches(RegExp(EIN_REGEX), 'Invalid EIN')
        .required('Required'),
    }),
    signingMethod: Yup.lazy(() => {
      if (accountAudience === AccountAudience.PLAN_FIDUCIARY)
        return Yup.string().nullable()
      return Yup.string()
        .oneOf(signingMethodValues, 'Must be a valid signing method')
        .required('Required')
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {() => {
        return (
          <ScrollErrorForm>
            <div className="form-container">
              <div className="form-column">
                <Input
                  label="Complete Legal Plan Name"
                  name="planDetails[name]"
                  placeholder="Enter complete legal plan name"
                  labelComponent={LabelWithTooltip}
                  tooltipContent={COMPLETE_LEGAL_PLAN_NAME_TOOLTIP_CONTENT}
                  tooltipAriaLabel="Complete legal plan name information"
                />
              </div>
              <div className="form-column">
                <MaskedInput
                  label="Employer Identification Number (EIN)"
                  name="planDetails[ein]"
                  placeholder="Enter employer identification number"
                  maskOptions={{
                    numericOnly: true,
                    blocks: [2, 7],
                    delimiter: '-',
                  }}
                  labelComponent={LabelWithTooltip}
                  tooltipContent={<PlanEinTooltipContent />}
                  tooltipAriaLabel="Plan EIN information"
                />
              </div>
              {accountAudience !== AccountAudience.PLAN_FIDUCIARY && (
                <div className="form-column full-row">
                  <RadioGroup
                    className="card-radio-group"
                    name="signingMethod"
                    label="What is your preferred signing method?"
                    options={signingMethods}
                    radioInputProps={{
                      labelComponent: SigningMethodLabel,
                    }}
                  />
                </div>
              )}
            </div>
            <FlowActions preventBack />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

AddNewServicesAgreementForm.propTypes = propTypes
AddNewServicesAgreementForm.defaultProps = defaultProps

export default AddNewServicesAgreementForm
