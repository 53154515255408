import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import * as actions from './actions'
import { compose } from 'redux'
import { unset, set } from 'lodash/fp'

const reducerKey = 'newSearchFlow'
const slice = 'root.newSearchFlow'

const initialState = {
  discardedParticipants: [],
}

const reducer = handleActions(
  {
    [actions.setNewSearchFlowData]: setState('newSearchFlowData'),
    [actions.clearNewSearchFlowData]: (state) => {
      return compose(
        unset('newSearchFlowData'),
        set('discardedParticipants', initialState.discardedParticipants)
      )(state)
    },
    [actions.resetDiscardedParticipants]: setState('discardedParticipants'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  newSearchFlowData: select('newSearchFlowData'),
  discardedParticipants: select('discardedParticipants'),
}

export { reducer, selectors, reducerKey }
