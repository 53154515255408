import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { AccountDetails, HelpDetails } from 'components'
import { Icon as MTCIcon } from 'images/inspira-logo.svg'
import { Icon as HelpIcon } from 'images/help-outline-thick.svg'
import { NavLink, Link } from 'react-router-dom'
import { selectors as globalSelectors } from '../main/reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isSearchServicesEnabled, isBenefitDistributionsEnabled } from 'config'

const propTypes = {
  isAdmin: PropTypes.bool.isRequired,
}

const defaultProps = {}
function Header({ isAdmin }) {
  return (
    <header className="header">
      <div id="header-action-area">
        <Link className="mtc-logo" to="/home" aria-label="Home">
          <MTCIcon className="header-mtc-logo" />
        </Link>
        <div className="header-action-area-left">
          <ul>
            <li>
              <NavLink to="/home">Home</NavLink>
            </li>
            <li>
              <NavLink to="/products/automatic-rollovers/services-agreements">
                Automatic Rollovers
              </NavLink>
            </li>
            {isSearchServicesEnabled() && (
              <li>
                <NavLink to="/products/search/history">Search Services</NavLink>
              </li>
            )}
            {isBenefitDistributionsEnabled() && (
              <li>
                <NavLink to="/products/benefit-distributions/history">
                  Benefit Distributions
                </NavLink>
              </li>
            )}
            {isAdmin && (
              <li>
                <NavLink to="/company">Manage Account</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/resources">Resources</NavLink>
            </li>
          </ul>
        </div>
        <div className="header-right">
          <div className="header-action-area-right">
            <HelpDetails helpIcon={<HelpIcon />} />
            <AccountDetails />
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

Header.displayName = 'Header'

function mapStateToProps(state) {
  return {
    isAdmin: globalSelectors.isAdmin(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
