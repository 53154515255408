import React from 'react'
import PropTypes from 'prop-types'
import * as globalActions from '../main/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'

const propTypes = {
  setSupportModalShown: PropTypes.func.isRequired,
  helpIcon: PropTypes.node.isRequired,
}

const defaultProps = {}

function HelpDetails({ setSupportModalShown, helpIcon }) {
  return (
    <div className="help-details">
      <button
        type="button"
        className="help-icon-btn"
        onClick={() => setSupportModalShown(true)}
        aria-label="show contact details"
      >
        {helpIcon}
      </button>
    </div>
  )
}

const mapDispatchToProps = {
  setSupportModalShown: globalActions.setSupportModalShown,
}

HelpDetails.propTypes = propTypes
HelpDetails.defaultProps = defaultProps

export default compose(connect(null, mapDispatchToProps))(HelpDetails)
