import { has, flatten } from 'lodash'
import * as Sentry from '@sentry/browser'

/* It is possible for a select field to have an initial value not found in the options (data issue),
  in which case the first dropdown option appears selected in the UI, even though the form value is 
  set to the original value, which can potentially lead to a conflicting error message to the user. 
  Shim: manually convert the initial value to an empty string, so no option is pre-selected. */

// note: this util doesn't support multi select

function getSelectInitialValue(currentValue, selectOptions) {
  if (!currentValue) return ''

  const allSelectValues = getAllValues(selectOptions)

  if (allSelectValues.includes(currentValue)) return currentValue
  Sentry.captureException(new Error(`No option found for: ${currentValue}`))
  return ''
}

// ----- PRIVATE -----

const getAllValues = (optionArray) => {
  return flatten(
    optionArray.map((option) => {
      if (has(option, 'options')) return getAllValues(option.options)
      if (has(option, 'value')) return option.value
      return option
    })
  )
}

export default getSelectInitialValue
