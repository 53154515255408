import {
  createRequest,
  createPutRequest,
  createPostRequest,
  createStubRequest,
} from 'lp-redux-api'
import { getSessionToken } from 'auth'
import {
  getServicesAgreementSubmissionType,
  convertNullToEmptyString,
  removeAttributes,
} from 'utils'
import { ServicesAgreementSubmissionType } from 'config'

export const fetchUserProfile = createRequest('FETCH__USER_PROFILE', () => ({
  url: '/userProfiles',
  onSuccess: (response) => response[0],
  // camelizeResponse set to true due to the property "Retirement ServicesPortal Profile"
  camelizeResponse: true,
}))

export const updateLastLogin = createPutRequest('SET_LAST_LOGIN', () => ({
  url: '/userProfiles/logins',
  body: {
    loginDate: '',
  },
}))

export const reinitializeDocusign = createPutRequest(
  'REINITIALIZE_DOCUSIGN',
  (servicesAgreementId) => ({
    url: `/planAgreement/reInitializeDocusign/${servicesAgreementId}`,
  })
)

export const fetchServiceAgreements = createRequest(
  'FETCH__SERVICE_AGREEMENTS',
  () => ({
    url: '/user/serviceAgreements',
  })
)

export const fetchSearches = createRequest('FETCH__SEARCHES', () => ({
  url: '/searches',
}))

export const updateSearchOrder = createPutRequest(
  'UPDATE_SEARCH_ORDER',
  (body) => ({
    url: '/searchOrders',
    body,
  })
)

export const fetchOrderDetails = createRequest(
  'FETCH_ORDER_DETAILS',
  (orderId) => ({
    url: `/searchOrders/${orderId}`,
    onSuccess: (response) => {
      const searchOrder = response[0]
      return {
        orderId, // shim until API is updated to include value
        ...searchOrder,
      }
    },
  })
)

export const createUser = createPostRequest('CREATE_USER', (body) => ({
  url: '/users',
  body,
}))

export const editUser = createPutRequest('EDIT_USER', (contactId, body) => ({
  url: `/users/${contactId}`,
  body,
}))

export const updatePlanAgreement = createPutRequest(
  'UPDATE_PLAN_AGREEMENT',
  (agreementId, body) => ({
    url: `/planAgreements/${agreementId}`,
    body,
  })
)

export const fetchCompanyUsers = createRequest('FETCH_COMPANY_USERS', () => ({
  url: '/users',
}))

export const fetchPlanSponsors = createRequest('FETCH_PLAN_SPONSORS', () => ({
  url: '/planSponsors',
  // conversion of null values to empty string helps with initial values when passing into formik
  onSuccess: (res) => convertNullToEmptyString(res),
}))

export const fetchRecordkeepers = createRequest('FETCH_RECORDKEEPERS', () => ({
  url: '/recordKeepers',
  // conversion of null values to empty string helps with initial values when passing into formik
  onSuccess: (res) => convertNullToEmptyString(res),
}))

export const fetchTpas = createRequest('FETCH_TPAS', () => ({
  url: '/thirdPartyAdministrators',
  onSuccess: (res) => convertNullToEmptyString(res),
}))

export const fetchConsultants = createRequest('FETCH_CONSULTANTS', () => ({
  url: '/consultants',
  onSuccess: (res) => convertNullToEmptyString(res),
}))

export const fetchServicesAgreements = createRequest(
  'FETCH_SERVICES_AGREEMENTS',
  { url: '/planAgreements' }
)

export const fetchCompletedServicesAgreements = createRequest(
  'FETCH_COMPLETED_SERVICES_AGREEMENTS',
  () => ({
    url: '/planAgreements',
    query: { agreementStatus: 'Completed' },
  })
)

export const createParticipantsData = createPostRequest(
  'CREATE_PARTICIPANT_DATA',
  ({ fileName, participants, summary }) => {
    return {
      url: '/participantFile',
      body: {
        fileName,
        summary,
        participants: removeAttributes(participants, [
          'reasonForDiscard',
          'mappedPlanName',
        ]),
      },
    }
  }
)

export const fetchAvailableInitialInvestments = createRequest(
  'FETCH_AVAILABLE_INITIAL_INVESTMENTS',
  () => ({
    url: '/availablePlanAgreementTypes',
  })
)

export const fetchServicesAgreementDetails = createRequest(
  'FETCH_SERVICES_AGREEMENT_DETAILS',
  (id) => ({
    url: '/planAgreement',
    query: { serviceAgreementId: id },
    onSuccess: (response) => response[0],
  })
)

export const submitServicesAgreementFile = createPostRequest(
  'SUBMIT_SERVICES_AGREEMENT_FILE',
  ({ planAgreementId, formData }) => ({
    url: '/submitPlanAgreementFile',
    body: formData,
    overrideHeaders: true,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
      submissionType: planAgreementId
        ? ServicesAgreementSubmissionType.EXISTING_AGREEMENT
        : ServicesAgreementSubmissionType.NEW_AGREEMENT,
    },
  })
)

export const submitServicesAgreementForm = createPostRequest(
  'SUBMIT_SERVICES_AGREEMENT_FORM',
  ({ planAgreementId, signingMethod, formData }) => ({
    url: '/submitPlanAgreement',
    headers: {
      submissionType: getServicesAgreementSubmissionType(
        planAgreementId,
        signingMethod
      ),
    },
    body: formData,
  })
)

export const submitSearchServicesAgreementForm = createPostRequest(
  'SUBMIT_SEARCH_SERVICES_AGREEMENT_FORM',
  (data) => ({
    url: '/submitPlanAgreement',
    headers: {
      submissionType: ServicesAgreementSubmissionType.SEARCH_SERVICES_AGREEMENT,
    },
    body: data,
  })
)

export const fetchServicesAgreementParticipants = createRequest(
  'FETCH_SERVICES_AGREEMENT_PARTICIPANTS',
  (id) => ({
    url: `/participants/${id}`,
    onSuccess: (res) => ({
      servicesAgreementId: id,
      participants: res,
    }),
  })
)

export const fetchCompanyOwner = createRequest('FETCH_COMPANY_OWNER', () => ({
  url: '/users/companyOwner',
}))

export const fetchErisapediaSearches = createRequest(
  'FETCH_ERISAPEDIA_SEARCHES',
  ({ name, ein }) => ({
    url: '/erisapediaSearch',
    query: {
      planName: name,
      ein,
    },
    onSuccess: (res) => convertNullToEmptyString(res),
  })
)

export const fetchPaymentMethod = createRequest('FETCH_PAYMENT_METHOD', () => ({
  url: `/paymentInfo`,
  onSuccess: (res) => (res.length >= 1 ? res[res.length - 1] : [{ mock: '' }]),
}))

export const deletePaymentMethod = createStubRequest(
  'DELETE_PAYMENT_METHOD',
  () => ({}),
  { delay: 2500 }
)

export const fetchSearchServices = createRequest(
  'FETCH_SEARCH_SERVICES',
  () => ({
    url: '/searchServices',
  })
)

export const generateSearchOrderCost = createPostRequest(
  'GENERATE_SEARCH_ORDER_COST',
  ({ searchService, numberOfSearches, finalMailing }) => ({
    url: '/searchServices/orderCost',
    body: { searchService, numberOfSearches, finalMailing },
  })
)

// Card Services

export const saveCompletePaymentDetails = createPostRequest(
  'SAVE_COMPLETE_PAYMENT_DETAILS',
  ({ paymentDetails }) => ({
    url: '/paymentInfo',
    body: paymentDetails,
  })
)

export const fetchCardToken = createRequest('FETCH_CARD_TOKEN', () => ({
  url: '/cardServices/token',
  camelizeResponse: true,
}))

export const updateSearchOrderDownloadDate = createPutRequest(
  'UPDATE_SEARCH_ORDER_DOWNLOAD_DATE',
  (searchOrderId) => ({
    url: `/searchOrders/${searchOrderId}/downloadedDates`,
    body: {},
    onSuccess: (res) => res,
  })
)
