import React, { useState, useRef, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FlatfileButton as BaseFlatFileButton } from '@flatfile/react'
import { getFlatfileConfig, RecordNames, RecordTypes } from 'flatfile-config'
import { ConfirmModal, Spinner, FileNameContainer } from 'components'
import { isEmpty, noop, has } from 'lodash'
import { COMPANY_NAME } from 'config'

// handleData handles valid data from the Flatfile results
// handleRecords is used to re-format, validate, and/or correct data (on every row) during the import
// handleRecordsSubmit is invoked when the user is done reviewing data and submits the records on the ff modal

const propTypes = {
  recordType: PropTypes.oneOf(Object.values(RecordTypes)).isRequired,
  validators: PropTypes.object,
  handleError: PropTypes.func.isRequired,
  handleData: PropTypes.func.isRequired,
  handleRecords: PropTypes.func,
  handleRecordsSubmit: PropTypes.func,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  confirmationContent: PropTypes.element,
  buttonContent: PropTypes.node.isRequired,
}

const defaultProps = {
  validators: {},
  handleRecords: noop,
  handleRecordsSubmit: noop,
  fileName: null,
  setFileName: noop,
  confirmationContent: null,
}

function useForceUpdate() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  return forceUpdate
}

function findMissingFields(record, fields) {
  return fields.filter((field) => !has(record, field))
}

/*
Given an array of records and an array of fields, assign each field with an empty string to each record:
 e.g., records = [ { firstName: "Jane" }, { firstName: "John" } ]; fields = [ "middleName", "email" ]
-> [ { firstName: "Jane", middleName: "", email: "" }, { firstName: "John", middleName: "", email: "" } ]
*/
function assignFields(records, fields) {
  return fields.reduce((accumulatedRecords, field) => {
    return accumulatedRecords.map((record) => ({
      ...record,
      [field]: '',
    }))
  }, records)
}

function FlatfileButton({
  confirmationContent,
  recordType,
  validators,
  handleError,
  handleData,
  handleRecords,
  handleRecordsSubmit,
  fileName,
  setFileName,
  buttonContent,
}) {
  const forceUpdate = useForceUpdate()
  const isLoaded = useRef(false)
  const isImporterInitialized = useRef(false)
  const isUploadComplete = useRef(false)
  const [isLaunched, setIsLaunched] = useState(false)
  const [flatFileErrorExists, setFlatFileErrorExists] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    if (isLaunched) return
    if (isUploadComplete.current) handleRecordsSubmit()
  }, [isLaunched, handleRecordsSubmit])

  // tracks flatfile's timeout of 5s when attempting to load the importer, beginning at component render
  // https://github.com/FlatFilers/Adapter/blob/bcca7f1efc8dbf3648f32bc64530ac90174f8ef3/src/importer.ts#L336
  // update FF error state when importer is not ready
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoaded.current) setFlatFileErrorExists(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  // sets an error message when an attempt to load the importer has timed out when clicking the Upload File button (action based behavior)
  // setIsLaunched is not updated here to remove the Upload File button, forcing the user to refresh the page
  useEffect(() => {
    if (flatFileErrorExists && isLaunched) {
      handleError({ message: 'Something went wrong, please refresh the page' })
    }
    if (!flatFileErrorExists) handleError(null)
  }, [flatFileErrorExists, isLaunched])

  const recordName = RecordNames[recordType]
  const flatfileConfig = getFlatfileConfig(recordType, validators)
  const fieldKeys = flatfileConfig.fields.map((field) => field.key)

  const onLaunchReady = (launch) => {
    handleError(null)
    setIsLaunched(true)
    launch()
  }

  return (
    <>
      <BaseFlatFileButton
        licenseKey={process.env.REACT_APP_FLATFILE_LICENSE_KEY}
        customer={{
          companyId: `${COMPANY_NAME.short}-RSP`,
          companyName: `${COMPANY_NAME.short} Retirement Services Portal`,
          // placeholder id
          userId: recordType,
        }}
        settings={flatfileConfig}
        onData={async (results) => {
          const { fileName, $data } = results
          const { validData, invalidData } = $data.reduce(
            (acc, { data, valid, sequence }) => {
              // Preserve each record's original row number from the file- this will be used as a unique identifier
              const record = { ...data, row: sequence }
              if (valid) acc.validData.push(record)
              else acc.invalidData.push(record)
              return acc
            },
            { validData: [], invalidData: [] }
          )
          setFileName(fileName)

          // shim until API handles optional fields
          const missingFields = findMissingFields(validData[0], fieldKeys)
          const validDataWithAllFields = assignFields(validData, missingFields)
          handleData(validDataWithAllFields, invalidData)

          const validDataExists = !isEmpty(validData)
          isUploadComplete.current = validDataExists

          if (!validDataExists) {
            return 'No valid records were submitted. Please try again.'
          }
          return `${recordName} records successfully captured`
        }}
        render={(importer, launch) => {
          if (!isImporterInitialized.current) {
            importer.registerRecordHook(handleRecords)

            importer.$ready.then(() => {
              const hasNotYetLoaded = !isLoaded.current
              isLoaded.current = true
              // Reset the error state since it's possible our timeout implementation (in useEffect) completes before the importer loads
              setFlatFileErrorExists(false)
              // Since the UI relies on the ref to render the spinner, force an update.
              // isLoaded cannot be used as local state since it is referenced in
              // an async effect where it could be stale
              if (hasNotYetLoaded) {
                forceUpdate()
                return
              }
            })

            importer.addListener('close', function () {
              setIsLaunched(false)
            })

            isImporterInitialized.current = true
          }

          if (fileName) {
            return (
              <FileNameContainer
                fileName={fileName}
                handleUpload={() => {
                  handleError(null)
                  setIsLaunched(true)
                  launch()
                }}
              />
            )
          }
          return (
            !isLaunched && (
              <>
                {showConfirmModal && (
                  <ConfirmModal
                    className="flatfile-confirm-modal"
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => {
                      setShowConfirmModal(false)
                      onLaunchReady(launch)
                    }}
                    confirmContent="Continue"
                  >
                    {confirmationContent}
                  </ConfirmModal>
                )}
                <button
                  className="button-primary-outline upload-file is-fullwidth"
                  onClick={() => {
                    confirmationContent !== null
                      ? setShowConfirmModal(true)
                      : onLaunchReady(launch)
                  }}
                >
                  {buttonContent}
                </button>
              </>
            )
          )
        }}
      />
      {!isLoaded.current && isLaunched && !flatFileErrorExists && <Spinner />}
    </>
  )
}

FlatfileButton.propTypes = exact(propTypes)
FlatfileButton.defaultProps = defaultProps

export default React.memo(FlatfileButton)
