import React from 'react'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { getProductConfig } from 'utils'
import { ExternalLink, ShowMoreText } from 'components'

const propTypes = {
  productType: Types.productType.isRequired,
}

const defaultProps = {}

function ProductOfferingCard({ productType }) {
  const { icon: Icon, descriptionLink, text } = getProductConfig(productType)
  if (!Icon) {
    // eslint-disable-next-line
    console.error(`No config found for: ${productType}`)
    return null
  }

  return (
    <div>
      <div className="product-card-container">
        <div className="product-card-header">
          <Icon aria-hidden="true" />
          <h3>{productType}</h3>
        </div>
        <ShowMoreText text={text} className="product-offering-card" />
        <div className="product-card-footer">
          <ExternalLink
            link={descriptionLink}
            className="button-primary-outline"
          >
            Learn More
          </ExternalLink>
        </div>
      </div>
    </div>
  )
}

ProductOfferingCard.propTypes = exact(propTypes)
ProductOfferingCard.defaultProps = defaultProps

export default React.memo(ProductOfferingCard)
