import React from 'react'
import { AddressFields } from '../components'
import { Input, MaskedInput, OptionalLabel } from 'components'

function CompanyDetailsFields({ detailsType }) {
  return (
    <>
      <div className="form-column">
        <Input
          name={`${detailsType}.company[name]`}
          label="Company Name"
          placeholder="Enter company name"
        />
      </div>
      <div className="form-column">
        <MaskedInput
          name={`${detailsType}.company[phone]`}
          label="Company Phone"
          placeholder="Enter phone"
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 0, 3, 4],
            delimiters: ['(', ')', ' ', '-'],
          }}
        />
      </div>
      <div className="form-column">
        <Input
          name={`${detailsType}.company[website]`}
          label="Company Website"
          labelComponent={OptionalLabel}
          placeholder="Enter website"
        />
      </div>
      <AddressFields detailsType={detailsType} addressType="company" />
    </>
  )
}

export default CompanyDetailsFields
