import { handleActions } from 'redux-actions'
import { selectorForSlice, setState, unsetState } from 'lp-redux-utils'
import * as actions from './actions'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { set, unset, get } from 'lodash/fp'
import { compose } from 'redux'
import { concat, differenceBy, groupBy, forIn, map } from 'lodash'
import { createSelector } from 'reselect'
import { AudienceType } from 'config'

const reducerKey = 'automaticRollovers'
const slice = 'root.automaticRollovers'

const initialState = {
  participantsForSubmission: [],
  discardedParticipants: [],
}

const reducer = handleActions(
  {
    [actions.setLegaleseConfirmationForm]: setState(
      'legaleseConfirmationForm',
      (action, _, form) => ({ ...form, ...action.payload })
    ),
    [actions.setParticipantsForSubmission]: (state, action) => {
      return compose(
        set('participantsForSubmission', action.payload),
        unset('participantsAgreementHash')
      )(state)
    },
    [actions.resetDiscardedParticipants]: setState('discardedParticipants'),
    [actions.setDiscardedParticipants]: (state, action) => {
      const { participants, reasonForDiscard } = action.payload
      // Add the reason for discard (used for generating the discarded participants csv file)
      const discardedParticipantsWithReason = participants.map(
        (participant) => ({
          ...participant,
          reasonForDiscard,
        })
      )

      // When setting discarded participants (e.g., for missing agreement or terminating DB),
      // participantsForSubmission selector needs to be updated as well since it's possible
      // for the user to go back to previous steps in the workflow and change their selections:
      // 1) Move participants discarded for the same reason to participantsSubmission selector
      // 2) Replace them with the participants from action.payload
      // 3) Remove the payload participants from participantsSubmission, using row as the unique ID

      const discardedParticipants = get('discardedParticipants', state) || []
      const participantsForSubmission =
        get('participantsForSubmission', state) || []

      const {
        true: participantsDiscardedForSameReason = [],
        false: participantsDiscardedForDifferentReason = [],
      } = groupBy(
        discardedParticipants,
        (participant) => participant.reasonForDiscard === reasonForDiscard
      )

      const updatedDiscardedParticipants = concat(
        participantsDiscardedForDifferentReason,
        discardedParticipantsWithReason
      )
      const updatedParticipantsForSubmission = differenceBy(
        concat(participantsForSubmission, participantsDiscardedForSameReason),
        participants,
        'row'
      )
      return compose(
        set('discardedParticipants', updatedDiscardedParticipants),
        set('participantsForSubmission', updatedParticipantsForSubmission)
      )(state)
    },
    [actions.setParticipantFileName]: setState('participantFileName'),
    [actions.setParticipantsAgreementHash]: setState(
      'participantsAgreementHash'
    ),
    [actions.updateParticipantsWithMappedAgreement]: (state, action) => {
      const completedServicesAgreements =
        get('completedServicesAgreements', state) || []

      let participantsForSubmission = []
      forIn(action.payload, ({ servicesAgreement: id, participants }) => {
        const mappedServicesAgreement = completedServicesAgreements.find(
          (agreement) => agreement.serviceAgreementID === id
        )
        if (!mappedServicesAgreement) return
        const participantsWithServicesAgreement = participants.map(
          (participant) => ({
            ...participant,
            // Add mapped agreement's name from the database for the summary table
            mappedPlanName: mappedServicesAgreement.name,
            // Add additional data for POST participants data payload:
            serviceAgreementID: mappedServicesAgreement.serviceAgreementID,
            agreementID: mappedServicesAgreement.agreementID,
            agreementType: mappedServicesAgreement.portalAgreementType,
          })
        )
        participantsForSubmission.push(...participantsWithServicesAgreement)
      })

      const discardedParticipants = get('discardedParticipants', state) || []

      return compose(
        set('participantsForSubmission', participantsForSubmission),
        set(
          'discardedParticipants',
          differenceBy(discardedParticipants, participantsForSubmission, 'row')
        )
      )(state)
    },
    [actions.clearParticipantsData]: (state) => {
      return compose(
        unset('legaleseConfirmationForm'),
        unset('participantFileName'),
        unset('participantsAgreementHash'),
        unset('planConfirmationAcknowledgementsForm'),
        set(
          'participantsForSubmission',
          initialState.participantsForSubmission
        ),
        set('discardedParticipants', initialState.discardedParticipants)
      )(state)
    },
    [actions.setCompleteServicesAgreementForm]: setState(
      'completeServicesAgreementForm',
      (action, _, form) => ({ ...form, ...action.payload })
    ),
    [actions.clearCompleteServicesAgreementForm]: unsetState(
      'completeServicesAgreementForm'
    ),
    [apiActions.fetchPlanSponsors]: setOnSuccess('planSponsors'),
    [apiActions.fetchRecordkeepers]: setOnSuccess('recordkeepers'),
    [apiActions.fetchTpas]: setOnSuccess('tpas'),
    [apiActions.fetchConsultants]: setOnSuccess('consultants'),
    [apiActions.fetchCompletedServicesAgreements]: setOnSuccess(
      'completedServicesAgreements'
    ),
    [actions.setPlanConfirmationAcknowledgementsForm]: setState(
      'planConfirmationAcknowledgementsForm',
      (action, _, form) => ({ ...form, ...action.payload })
    ),
    [apiActions.fetchErisapediaSearches]: setOnSuccess('erisapediaSearches'),
    [actions.resetErisapediaSearches]: unsetState('erisapediaSearches'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  planSponsors: select('planSponsors'),
  recordkeepers: select('recordkeepers'),
  tpas: select('tpas'),
  consultants: select('consultants'),
  legaleseConfirmationForm: select('legaleseConfirmationForm'),
  participantsForSubmission: select('participantsForSubmission'),
  discardedParticipants: select('discardedParticipants'),
  participantFileName: select('participantFileName'),
  participantsAgreementHash: select('participantsAgreementHash'),
  completedServicesAgreements: select('completedServicesAgreements'),
  completeServicesAgreementForm: select('completeServicesAgreementForm'),
  planConfirmationAcknowledgementsForm: select(
    'planConfirmationAcknowledgementsForm'
  ),
  erisapediaSearches: select('erisapediaSearches'),
}

selectors.transformedPlanSponsors = createSelector(
  [selectors.planSponsors],
  (planSponsors) => {
    if (!planSponsors) return
    return map(planSponsors, ({ planSponsorCompany, planSponsorContact }) => ({
      company: {
        ...planSponsorCompany,
        type: AudienceType.PLAN_SPONSOR,
      },
      contact: planSponsorContact,
    }))
  }
)

selectors.transformedRecordkeepers = createSelector(
  [selectors.recordkeepers],
  (recordkeepers) => {
    if (!recordkeepers) return
    return map(
      recordkeepers,
      ({ recordKeeperCompany, recordKeeperContact }) => ({
        company: {
          ...recordKeeperCompany,
          type: AudienceType.RECORD_KEEPER,
        },
        contact: recordKeeperContact,
      })
    )
  }
)

selectors.transformedTpas = createSelector([selectors.tpas], (tpas) => {
  if (!tpas) return
  return map(tpas, ({ tpaCompany, tpaContact }) => ({
    company: {
      ...tpaCompany,
      type: AudienceType.TPA,
    },
    contact: tpaContact,
  }))
})

selectors.transformedConsultants = createSelector(
  [selectors.consultants],
  (consultants) => {
    if (!consultants) return
    return map(consultants, ({ consultantCompany, consultantContact }) => ({
      company: {
        ...consultantCompany,
        type: AudienceType.CONSULTANT,
      },
      contact: consultantContact,
    }))
  }
)

export { reducer, selectors, reducerKey }
