import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { Formik } from 'formik'
import { SubmitButton } from 'lp-components'
import { ScrollErrorForm } from 'components'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PaymentFields, { paymentSchema } from 'components/PaymentFields'
import {
  US_COUNTRY_VALUE,
  CREDIT_CARD_OPTION,
  TOAST_ERROR_CONTACT_SUPPORT,
  SAVED_PAYMENT_METHOD_SUCCESS_MESSAGE,
} from 'config'
import * as apiActions from 'api-actions'
import { flashErrorMessage } from 'redux-flash'
import * as globalActions from '../../actions'
import * as profileActions from '../../profile/actions'

const propTypes = {
  setShouldTriggerPaymentInfoFetch: PropTypes.func.isRequired,
  saveCompletePaymentDetails: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
}
const defaultProps = {}

function AddNewPaymentForm({
  setShouldTriggerPaymentInfoFetch,
  flashErrorMessageHandler,
  saveCompletePaymentDetails,
  showSuccessModal,
}) {
  const [creditCardKey, setCreditCardKey] = useState(Date.now())

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const paymentDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      country: values.country,
      state: values.state,
      city: values.city,
      address1: values.address1,
      address2: values.address2,
      zipCode: values.zip,
      paymentToken: values.cardData.paymentToken,
      fraudId: values.cardData.fraud,
      creditCardLastFour: values.cardData.last4Digits,
      cardExpMonth: values.cardData.exp.split('/')[0],
      cardExpYear: values.cardData.exp.split('/')[1],
      cardType: values.cardData.ccType,
      cardStatus: 'Active',
      cardLabel: values.cardData.cardLabel,
      email: null,
      phone: null,
    }
    try {
      await saveCompletePaymentDetails({ paymentDetails })
      setSubmitting(false)
      showSuccessModal({
        title: 'Success',
        message: SAVED_PAYMENT_METHOD_SUCCESS_MESSAGE,
      })
      setShouldTriggerPaymentInfoFetch(true)
      resetForm()
    } catch (err) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }

  return (
    <Formik
      initialValues={{
        paymentType: CREDIT_CARD_OPTION.value,
        firstName: '',
        lastName: '',
        cardData: '',
        country: US_COUNTRY_VALUE,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      }}
      validationSchema={paymentSchema}
      onSubmit={(params, { setSubmitting, resetForm }) => {
        handleSubmit(params, { setSubmitting, resetForm })
        setSubmitting(true)
        setCreditCardKey(Date.now()) // only needed because we aren't unmounting the form
      }}
    >
      {({ isSubmitting }) => {
        return (
          <ScrollErrorForm id="add-new-payment-form">
            {/* Add additional name fields */}
            <PaymentFields key={creditCardKey} />
            <SubmitButton submitting={isSubmitting}>
              Replace Payment
            </SubmitButton>
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

function mapStateToProps(state) {
  return {
    completePaymentDetails: selectors.completePaymentDetails(state),
  }
}

const mapDispatchToProps = {
  setShouldTriggerPaymentInfoFetch:
    profileActions.setShouldTriggerPaymentInfoFetch,
  saveCompletePaymentDetails: apiActions.saveCompletePaymentDetails,
  flashErrorMessageHandler: flashErrorMessage,
  showSuccessModal: globalActions.showSuccessModal,
}

AddNewPaymentForm.propTypes = propTypes
AddNewPaymentForm.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddNewPaymentForm
)
