import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Accordion,
  FlatfileButton,
  ExternalLink,
  Notification,
  ScrollErrorForm,
  ConfirmationCheckbox,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import {
  getParticipantFileUploadExpandedItems,
  setParticipantFileUploadExpandedItems,
} from 'local-storage'
import { RecordTypes } from 'flatfile-config'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Spinner } from 'lp-components'
import {
  formatAndValidateParticipantRecord,
  useParticipantsSummary,
} from 'utils'
import * as actions from '../actions'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { FlowActions, ParticipantsSummaryTable } from '../components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { COMPANY_NAME, Path, ReasonForDiscard } from 'config'
import { Icon as NewIcon } from 'images/utility-icons/new.svg'

const propTypes = {
  legaleseConfirmationForm: PropTypes.object,
  setLegaleseConfirmationForm: PropTypes.func.isRequired,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  setParticipantsForSubmission: PropTypes.func.isRequired,
  resetDiscardedParticipants: PropTypes.func.isRequired,
  participantFileName: PropTypes.string,
  setParticipantFileName: PropTypes.func.isRequired,
  fetchCompletedServicesAgreements: PropTypes.func.isRequired,
  completedServicesAgreements: PropTypes.arrayOf(
    Types.servicesAgreementSummary
  ),
}

const defaultProps = {
  legaleseConfirmationForm: {},
  completedServicesAgreements: null,
  participantFileName: null,
}
const accordionItems = [
  {
    uuid: 'instructions',
    heading: 'Instructions',
    panel: (
      <>
        <p>
          Please follow the steps below to upload your participant data file.
          The participant data submission process works best if you use the EZ
          Worksheet Template above, but it is not required.
        </p>
        <ol className="instructions-list">
          <li>
            After clicking on Upload File below, you will be asked to start the
            upload process by selecting your file.
          </li>
          <li>
            Select the file and then choose the name of the tab that your data
            is contained in, if applicable.
          </li>
          <li>
            Confirm the "Header Row" in your file where the names of the columns
            are specified. The row immediately after the header row must be the
            first row of participant data.
          </li>
          <li>
            Match the column names from your file to the column names that{' '}
            {COMPANY_NAME['long']} requires for submission. If you are missing
            any required columns, you will be notified of this when you attempt
            to proceed to the next step. If you use the template file, the
            system will auto-match all the headers and you only need to review
            and advance to the next step.
          </li>
          <li>
            Review the rows of participant data provided and resolve any issues
            identified by the system. Data from your original file may be
            reformatted by the system to a standard format (such as SSN, EIN, or
            Date of Birth). All cells highlighted in red must be corrected
            before you are able to proceed to the next step in the submission
            process. If you attempt to proceed without correcting all issues
            identified, the invalid rows will not be included in the submission
            to {COMPANY_NAME['long']}.
          </li>
        </ol>
        <p>
          Note: If you find that your file has many issues, it may be faster to
          make edits to the file offline in Excel then upload again when the
          changes have been made.
        </p>
      </>
    ),
  },
]

function UploadParticipantFileView({
  legaleseConfirmationForm,
  setLegaleseConfirmationForm,
  participantsForSubmission,
  setParticipantsForSubmission,
  resetDiscardedParticipants,
  participantFileName,
  setParticipantFileName,
  fetchCompletedServicesAgreements,
  completedServicesAgreements,
}) {
  const history = useHistory()
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState(null)
  const [error, setError] = useState(null)
  const formRef = useRef()
  const { data: participantsSummaryData } = useParticipantsSummary(
    participantsForSubmission
  )
  const [expandedItems, setExpandedItems] = useState(
    getParticipantFileUploadExpandedItems() || []
  )

  useEffect(() => {
    async function getCompletedServicesAgreements() {
      try {
        await fetchCompletedServicesAgreements()
      } catch {
        setError(true)
      }
    }
    getCompletedServicesAgreements()
  }, [])

  const handleSubmit = async (values) => {
    // if, for instance, flatfile error exists, do nothing
    if (errorMessage) return
    if (isEmpty(participantsForSubmission)) {
      setErrorMessage('Please provide a valid file in order to continue')
      return
    }
    setParticipantFileUploadExpandedItems(expandedItems) // set expanded items in localStorage
    setLegaleseConfirmationForm(values)
    history.push({
      pathname: '/automatic-rollovers/plan-mapping',
      state: {
        search: location.state?.search,
      },
    })
  }

  if (!completedServicesAgreements && !error) {
    return (
      <div className="upload-participant-file-view-container">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <Redirect
        push
        to={{
          pathname: '/error',
          state: {
            ignorePrompt: true, // No need to warn the user about leaving the flow
            search: location.state?.search,
          },
        }}
      />
    )
  }

  // Prevent the user from accessing the flow if the user 1) has no completed services agreements
  // or 2) did not go through the start-new-rollover modal from the table view first

  if (!completedServicesAgreements.length) {
    return (
      <Redirect
        push
        to={{
          pathname: Path.SERVICES_AGREEMENTS,
          state: {
            ignorePrompt: true,
            search: location.state?.search,
          },
        }}
      />
    )
  }
  if (!location.state?.hasConfirmed) {
    return (
      <Redirect
        push
        to={{
          pathname: Path.SERVICES_AGREEMENTS,
          state: {
            ignorePrompt: true,
            showStartNewRolloverModal: true,
            search: location.state?.search,
          },
        }}
      />
    )
  }

  return (
    <div className="card full-height upload-participant-file-view-container">
      <div className="form-masthead">
        <h2>Let’s get started with the participant file.</h2>
      </div>
      <div className="form-section form-section--new-spreadsheet">
        <div className="form-header">
          <h3>New Participant Data File Spreadsheet</h3>
        </div>
        <Notification type={NotificationVariant.WARNING}>
          <p className="text-title--small">
            Please note we have updated the Data File Spreadsheet. The format
            has changed slightly and using this updated spreadsheet will allow
            for a quicker submission of the data.
          </p>
          <p className="is-flex">
            <ExternalLink
              link={process.env.REACT_APP_ARO_EZ_WORKSHEET_TEMPLATE_URL}
              className="link-text"
              aria-label="Download the new updated spreadsheet"
            >
              Download Updated Spreadsheet
            </ExternalLink>
            <NewIcon className="new" aria-hidden="true" />
          </p>
        </Notification>
      </div>
      <div className="form-section form-section--no-bottom-margin">
        <div className="form-card-content upload-container">
          <div className="form-header">
            <h3 className="is-marginless">Upload Participant File</h3>
            <p>
              Once you've completed filling out the updated spreadsheet, upload
              it below.
            </p>
          </div>
          <FlatfileButton
            recordType={RecordTypes.ARO_PARTICIPANT}
            buttonContent="Upload File"
            handleError={(error) => {
              if (!error) setErrorMessage(null)
              else setErrorMessage(error.message)
            }}
            handleData={(validData, discardedData) => {
              setParticipantsForSubmission(validData)
              const discardedParticipantsWithReason = discardedData.map(
                (participant) => ({
                  ...participant,
                  reasonForDiscard: ReasonForDiscard.INVALID_DATA,
                })
              )
              resetDiscardedParticipants(discardedParticipantsWithReason)
            }}
            handleRecords={formatAndValidateParticipantRecord}
            setFileName={setParticipantFileName}
            fileName={participantFileName}
            confirmationContent={<ConfirmContent />}
          />
          {!!errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          {!isEmpty(participantsSummaryData) && (
            <ParticipantsSummaryTable
              data={participantsSummaryData}
              title={<h4>Participant File Summary</h4>}
            />
          )}
        </div>
      </div>
      <div className="form-section">
        <div className="form-header">
          <h3 className="is-marginless">
            Important Information: Please Review
          </h3>
        </div>
        <p>
          Generally, participants of terminating defined benefit plans are not
          eligible for rollover, unless the plan is not covered by the Pension
          Benefit Guaranty Corporation (PBGC). This includes distributions made
          in contemplation of plan termination but before the formal
          commencement of termination proceedings. It is the plan
          administrator's responsibility to determine whether the plan is
          covered by the PBGC and whether participant accounts are eligible for
          rollover.
        </p>
        <p>
          Active defined benefit and active defined contribution plan
          participant accounts may be eligible for rollover when the present
          value of the participant's nonforfeitable accrued benefit does not
          exceed $7,000. In limited circumstances, accounts with accrued
          benefits above $7,000 may be eligible for rollover. It is the plan
          administrator's responsibility to determine eligibility for each
          participant.
        </p>
        <Formik
          initialValues={{
            confirm: false,
            ...legaleseConfirmationForm,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            confirm: Yup.boolean()
              .required('Required')
              .oneOf([true], 'Required'),
          })}
          innerRef={formRef}
        >
          <ScrollErrorForm>
            <ConfirmationCheckbox
              name="confirm"
              label="I acknowledge that I have read and understand the information above."
            />
          </ScrollErrorForm>
        </Formik>
      </div>
      {isEmpty(participantsForSubmission) && (
        <div className="workflow-card-container">
          <Accordion
            items={accordionItems}
            initialItemsExpanded={expandedItems}
            onChange={(itemUuids) => setExpandedItems(itemUuids)}
            headingLevel={3}
          />
        </div>
      )}
      <FlowActions
        handleSubmit={() => formRef.current.handleSubmit()}
        preventBack
      />
    </div>
  )
}

const ConfirmContent = () => {
  return (
    <>
      <h1 className="text-headline--medium">New Participant File Template</h1>
      <p>
        Before you upload your file, please ensure you are using our new
        Participant Data File Spreadsheet template.{' '}
        <ExternalLink
          link={process.env.REACT_APP_ARO_EZ_WORKSHEET_TEMPLATE_URL}
          className="link-text"
        >
          Click here to download.
        </ExternalLink>
      </p>
    </>
  )
}

UploadParticipantFileView.propTypes = propTypes

UploadParticipantFileView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    legaleseConfirmationForm: selectors.legaleseConfirmationForm(state),
    participantsForSubmission: selectors.participantsForSubmission(state),
    participantFileName: selectors.participantFileName(state),
    completedServicesAgreements: selectors.completedServicesAgreements(state),
  }
}

const mapDispatchToProps = {
  setLegaleseConfirmationForm: actions.setLegaleseConfirmationForm,
  setParticipantsForSubmission: actions.setParticipantsForSubmission,
  resetDiscardedParticipants: actions.resetDiscardedParticipants,
  setParticipantFileName: actions.setParticipantFileName,
  fetchCompletedServicesAgreements: apiActions.fetchCompletedServicesAgreements,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadParticipantFileView
)
