import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

const defaultProps = {
  children: null,
  as: 'h2',
}

function TableHeading({ title, children, as: TitleComponent }) {
  return (
    <div className="table-heading">
      <TitleComponent>{title}</TitleComponent>
      {children}
    </div>
  )
}

TableHeading.propTypes = propTypes
TableHeading.defaultProps = defaultProps

export default TableHeading
