import { format } from 'date-fns'
import { parseDateFromString } from 'utils'

// Formats a string date, default is the following: "2021-08-26T19:42:54.000Z" to "26 Aug, 2021 at 2:42 PM" (for CST)
// Default formattingString can be override: https://date-fns.org/v2.28.0/docs/format
function formatDate(
  val,
  { formattingString = "d MMM',' y 'at' h:mm a", ignoreTimezone = false } = {}
) {
  const parsedDate = parseDateFromString(val, { ignoreTimezone })

  if (!parsedDate) return val

  return format(parsedDate, formattingString)
}

export default formatDate
