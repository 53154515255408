import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../actions'
import { AddNewServicesAgreementForm } from '../forms'
import { useHistory, Redirect } from 'react-router-dom'
import {
  Path,
  SEARCH_SERVICES_AGREEMENT_SUCCESS_MESSAGE,
  isSearchPaymentEnabled,
  TOAST_ERROR_CONTACT_SUPPORT,
} from 'config'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as productActions from '../../products/actions'
import { isEmpty } from 'lodash'
import { selectors as globalSelectors } from 'global-reducer'
import { flashErrorMessage } from 'redux-flash'

const propTypes = {
  currentUser: Types.user.isRequired,
  showSuccessModal: PropTypes.func.isRequired,
  newSearchFlowData: Types.newSearchFlowData,
  submitSearchServicesAgreementForm: PropTypes.func.isRequired,
  setSearchShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function AddNewServicesAgreementView({
  currentUser,
  showSuccessModal,
  newSearchFlowData,
  submitSearchServicesAgreementForm,
  setSearchShouldTriggerAllServicesAgreementsFetch,
  flashErrorMessageHandler,
}) {
  const history = useHistory()

  const handleSubmit = async () => {
    const { firstName, lastName, title, phone, email } = newSearchFlowData

    // The current user info is updated in the global reducer on success of this call
    // If these fields change, then the reducer logic needs to be updated as well
    try {
      await submitSearchServicesAgreementForm({
        company: [
          {
            contact: {
              name: {
                title,
                firstName,
                lastName,
              },
              communication: {
                email,
                phone,
              },
            },
          },
        ],
      })
      setSearchShouldTriggerAllServicesAgreementsFetch(true)
      history.push({
        pathname: Path.SEARCH_HISTORY,
        state: {
          ignorePrompt: true, // No need to warn the user about leaving the flow
        },
      })
      showSuccessModal({
        title: 'Success',
        message: SEARCH_SERVICES_AGREEMENT_SUCCESS_MESSAGE,
        actions: [
          isSearchPaymentEnabled() && {
            className: 'button-primary',
            label: 'Add Payment Details',
            url: Path.ADD_PAYMENT_DETAILS,
          },
        ].filter(Boolean),
      })
    } catch (error) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }

  if (isEmpty(newSearchFlowData))
    return <Redirect to={Path.SERVICE_AGREEMENT_DETAILS} />

  return (
    <div className="card full-height complete-search-agreement-view">
      <div className="complete-search-agreement-form-container">
        <div className="form-masthead">
          <h2>Lastly, review and agree to the agreement</h2>
        </div>
        <div className="form-section">
          <AddNewServicesAgreementForm
            initialValues={{
              confirm: false,
              dialogRead: false,
            }}
            onSubmit={handleSubmit}
            userDetails={{
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
              title: currentUser.userTitle,
            }}
          />
        </div>
      </div>
    </div>
  )
}

AddNewServicesAgreementView.propTypes = propTypes
AddNewServicesAgreementView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  showSuccessModal: globalActions.showSuccessModal,
  submitSearchServicesAgreementForm:
    apiActions.submitSearchServicesAgreementForm,
  setNewSearchFlowData: actions.setNewSearchFlowData,
  setSearchShouldTriggerAllServicesAgreementsFetch:
    productActions.setSearchShouldTriggerAllServicesAgreementsFetch,
  flashErrorMessageHandler: flashErrorMessage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(AddNewServicesAgreementView)
)
