import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

function useSearchParams() {
  const { search } = useLocation()

  return useMemo(() => {
    return queryString.parse(search)
  }, [search])
}

export default useSearchParams
