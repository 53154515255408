import { handleActions } from 'redux-actions'
import { selectorForSlice, setState } from 'lp-redux-utils'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { setOnSuccess } from 'lp-redux-api'

const reducerKey = 'company'
const slice = 'root.company'

const initialState = {
  triggerUsersFetch: true,
}

const reducer = handleActions(
  {
    [apiActions.fetchCompanyUsers]: setOnSuccess('companyUsers'),
    [actions.setTriggerUsersFetch]: setState('triggerUsersFetch'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  companyUsers: select('companyUsers'),
  triggerUsersFetch: select('triggerUsersFetch'),
}

export { reducer, selectors, reducerKey }
