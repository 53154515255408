import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'lp-components'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { compose } from 'redux'
import { connect } from 'react-redux'

const propTypes = {
  agreementId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reinitializeDocusign: PropTypes.func.isRequired,
}

const defaultProps = {}

function ReinitializeDocusignModal({
  agreementId,
  isSubmitting,
  onClose,
  reinitializeDocusign,
}) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const handleContinue = async () => {
    try {
      await reinitializeDocusign(agreementId)
      setSuccess(true)
      setError(null)
    } catch (e) {
      setSuccess(false)
      setError(e)
    }
  }

  function ModalCloseButton() {
    return (
      <div className="modal-actions">
        <Button onClick={onClose}>Close</Button>
      </div>
    )
  }

  function SuccessContent() {
    return (
      <>
        <h1>Request Confirmed</h1>
        <p>
          Your request to resend the DocuSign email has been received. Please
          allow a few minutes for processing.
        </p>
        <ModalCloseButton />
      </>
    )
  }

  function ErrorContent() {
    return (
      <>
        <h1>Request Failed</h1>
        <p>
          We're sorry, but your DocuSign request could not be processed at this
          time. Please try again a few minutes. If the problem persists please
          contact Support Team.
        </p>
        <ModalCloseButton />
      </>
    )
  }

  function DefaultContent() {
    return (
      <>
        <h1>Resend DocuSign Email</h1>
        <p>
          Resending the DocuSign Email will re-initialize the DocuSign workflow.
          Please note that links in any previously received email notifications
          will no longer work and the recipient must use the link in the new
          email.
        </p>
        <p>
          If you wish to proceed, please allow a few moments for the new
          DocuSign emails to be sent.
        </p>
        <div className="modal-actions">
          <Button submitting={isSubmitting} onClick={handleContinue}>
            Resend DocuSign Email
          </Button>
          <button className="button-text" onClick={onClose}>
            Close
          </button>
        </div>
      </>
    )
  }

  return (
    <Modal id="resend-email-modal" onClose={onClose}>
      {success && <SuccessContent />}
      {error && <ErrorContent />}
      {!success && !error && <DefaultContent />}
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    isSubmitting: apiSelectors.isLoading(
      state,
      apiActions.reinitializeDocusign
    ),
  }
}

const mapDispatchToProps = {
  reinitializeDocusign: apiActions.reinitializeDocusign,
}

ReinitializeDocusignModal.propTypes = propTypes
ReinitializeDocusignModal.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReinitializeDocusignModal
)
