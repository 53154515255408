import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { SubmitButton } from 'lp-components'
import { Formik } from 'formik'
import { withPermission } from 'utils'
import * as Yup from 'yup'
import { useClickAway } from 'react-use'
import { SearchOrderStatus, SearchTypeID } from 'config'
import {
  CheckboxGroup,
  DateInput,
  DropdownCheckboxGroup,
  ScrollErrorForm,
} from 'components'

// Convert enums from config to list as options for form inputs
const statusOptions = Object.values(SearchOrderStatus)
const searchTypeOptions = Object.values(SearchTypeID)

const emptyFilterValues = {
  status: [],
  searchType: [],
  planFiduciary: [],
  startSubmittedDate: '',
  endSubmittedDate: '',
}

const propTypes = {
  setShowFilterModal: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  planFiduciaries: PropTypes.array,
}

const defaultProps = {}

const FakeLegend = ({ label }) => <label>{label}</label>

function SearchOrdersFilterDialog({
  setShowFilterModal,
  filters,
  setFilters,
  planFiduciaries,
}) {
  const ref = useRef(null)

  useClickAway(ref, (e) => {
    const targetEl = e.target
    if (!document.body.contains(targetEl)) return
    setShowFilterModal(false)
  })

  const clearAllFilters = () => {
    setFilters(emptyFilterValues)
    setShowFilterModal(false)
  }

  const filterSchema = Yup.object().shape({
    status: Yup.array(),
    searchType: Yup.array(),
    planFiduciary: Yup.array(),
    startSubmittedDate: Yup.string(),
    endSubmittedDate: Yup.string(),
  })

  return (
    <div className="filter-formik-container" ref={ref}>
      <Formik
        initialValues={filters}
        validationSchema={filterSchema}
        onSubmit={(values) => {
          setShowFilterModal(false)
          setFilters(values)
        }}
      >
        {({ values, isSubmitting }) => (
          <ScrollErrorForm>
            <div className="filter-dialog-container">
              <div className="filter-row">
                <CheckboxGroup
                  name="status"
                  label="Status"
                  options={statusOptions}
                  labelComponent={FakeLegend}
                />
              </div>
              <div className="filter-row">
                <CheckboxGroup
                  name="searchType"
                  label="Search Type"
                  options={searchTypeOptions}
                  labelComponent={FakeLegend}
                />
              </div>
              {!withPermission('PlanFiduciary') && (
                <div className="filter-row">
                  <DropdownCheckboxGroup
                    name="planFiduciary"
                    options={planFiduciaries}
                  />
                </div>
              )}
              <div className="filter-row">
                <fieldset aria-labelledby="date-range-legend">
                  <div className="fake-legend" id="date-range-legend">
                    Submitted Date
                  </div>
                  <div id="filter-date-range">
                    <DateInput
                      name="startSubmittedDate"
                      label={false}
                      aria-label="start submitted date range"
                      selectsStart
                      startDate={values.startSubmittedDate}
                      endDate={values.endSubmittedDate}
                    />
                    <div id="date-separator" aria-hidden="true">
                      -
                    </div>
                    <DateInput
                      name="endSubmittedDate"
                      label={false} // set to false to match design
                      aria-label="end submitted date range"
                      selectsEnd
                      startDate={values.startSubmittedDate}
                      endDate={values.endSubmittedDate}
                      minDate={values.startSubmittedDate}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="filter-row filter-actions-container">
                <div className="filter-actions">
                  <button
                    type="button"
                    id="filter-btn-clear"
                    className="button-text"
                    onClick={clearAllFilters}
                  >
                    Clear all filters
                  </button>
                </div>
                <div className="filter-actions">
                  <button
                    type="button"
                    id="filter-btn-cancel"
                    className="button-text"
                    onClick={() => setShowFilterModal(false)}
                  >
                    Cancel
                  </button>
                  <SubmitButton submitting={isSubmitting}>
                    Apply Filters
                  </SubmitButton>
                </div>
              </div>
            </div>
          </ScrollErrorForm>
        )}
      </Formik>
    </div>
  )
}

SearchOrdersFilterDialog.propTypes = propTypes
SearchOrdersFilterDialog.defaultProps = defaultProps

export default SearchOrdersFilterDialog
