import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { useParams, Redirect } from 'react-router-dom'
import { formatDate, tippyPlugins, formatCurrency } from 'utils'
import {
  BackButton,
  FullPageSpinner,
  StatusBadge,
  Tooltip,
  Notification,
} from 'components'
import { Icon as DownloadIcon } from 'images/download.svg'
import { Icon as PrinterIcon } from 'images/printer.svg'
import { noop } from 'lodash'
import {
  SearchOrderStatus,
  SearchOrderTooltip,
  TOAST_ERROR_CONTACT_SUPPORT,
} from 'config'
import { flashErrorMessage } from 'redux-flash'
import { downloadSearchOrderResults } from 'api'
import { Variant as NotificationVariant } from 'components/Notification'

const propTypes = {
  fetchOrderDetails: PropTypes.func.isRequired,
  searchOrder: Types.searchOrder,
  fetchSearchServices: PropTypes.func.isRequired,
  searchServices: PropTypes.arrayOf(Types.searchService),
  flashErrorMessageHandler: PropTypes.func.isRequired,
  searchOrderCost: Types.searchOrderCost,
  generateSearchOrderCost: PropTypes.func.isRequired,
  resetSearchOrderCost: PropTypes.func.isRequired,
}

const defaultProps = {
  searchOrder: null,
}

const { hideOnEsc, hideOnPopperBlur, preventEventBubbling } = tippyPlugins

function useSearchOrderDownload(orderId, searchOrder) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const handleDownload = async () => {
    try {
      setIsLoading(true)
      await downloadSearchOrderResults(orderId, searchOrder.orderNumber)
    } catch (e) {
      setError(e.message)
    } finally {
      setIsLoading(false)
    }
  }
  return { download: handleDownload, isLoading, error }
}
function SearchOrderDetails({
  searchOrder,
  fetchOrderDetails,
  fetchSearchServices,
  searchServices,
  flashErrorMessageHandler,
  searchOrderCost,
  generateSearchOrderCost,
  resetSearchOrderCost,
}) {
  const { orderId } = useParams()
  const [error, setError] = useState(false)
  const {
    download,
    isLoading: isDownloading,
    error: downloadError,
  } = useSearchOrderDownload(orderId, searchOrder)

  useEffect(() => {
    fetchOrderDetails(orderId).catch(() => {
      setError(true)
    })
  }, [])

  useEffect(() => {
    async function generateTotalCost() {
      try {
        if (searchOrder && searchOrder.orderId === orderId) {
          const { type, numberOfSearches, finalMailing } = searchOrder
          await generateSearchOrderCost({
            searchService: type,
            numberOfSearches: parseInt(numberOfSearches),
            finalMailing: finalMailing,
          })
        }
      } catch (e) {
        setError(true)
      }
    }
    generateTotalCost()

    return () => resetSearchOrderCost()
  }, [searchOrder, orderId])

  useEffect(() => {
    async function getSearchServices() {
      try {
        await fetchSearchServices()
      } catch {
        setError(true)
      }
    }
    if (!searchServices) getSearchServices()
  }, [searchServices])

  useEffect(() => {
    if (downloadError) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }, [downloadError, flashErrorMessageHandler])

  const isLoadingOrderDetails =
    (searchOrder?.orderId !== orderId || !searchOrderCost || !searchServices) &&
    !error

  if (isLoadingOrderDetails) return <FullPageSpinner />
  if (error) {
    flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    return <Redirect to="/products/search/history" />
  }

  const searchTypeDetails = searchServices.find(
    ({ searchService }) => searchService === searchOrder.type
  )

  return (
    <>
      <div className="order-details-actions">
        <div className="left-top">
          <BackButton link="/products/search/history">
            Back to all searches
          </BackButton>
        </div>
        <div className="bottom">
          <div className="title">
            <h1>Search Order - #{searchOrder.orderNumber}</h1>
          </div>
          <div className="action-buttons">
            {searchOrder.status === SearchOrderStatus.RESULTS_AVAILABLE && (
              <>
                <button
                  type="button"
                  onClick={isDownloading ? noop : download}
                  className="button-text"
                >
                  <DownloadIcon aria-hidden="true" />
                  Download Results
                </button>
                <div className="divider"></div>
              </>
            )}
            <button
              type="button"
              onClick={() => window.print()}
              className="button-text button-print"
            >
              <PrinterIcon aria-hidden="true" />
              Print Receipt
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="order-details-header">
          <h2 className="title">Search Order Details</h2>
          <p className="company-name">{searchOrder.requestedCompanyName}</p>
        </div>
        <hr />
        <div className="order-details-container">
          <dl>
            <div className="order-details-column">
              <dt>Status</dt>
              <dd className="status-text">
                <Tooltip
                  content={SearchOrderTooltip[searchOrder.status]}
                  plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
                >
                  <StatusBadge status={searchOrder.status} />
                </Tooltip>
              </dd>
            </div>
            <div className="order-details-column">
              <dt>Type</dt>
              <dd>{searchOrder.type}</dd>
            </div>
            <div className="order-details-column">
              <dt>Submitted By</dt>
              <dd>{searchOrder.submittedBy}</dd>
            </div>
            <div className="order-details-column">
              <dt>Submitted On</dt>
              <dd>{formatDate(searchOrder.submittedOn)}</dd>
            </div>
            <div className="order-details-column">
              <dt>Date Completed</dt>
              <dd>{formatDate(searchOrder.dateCompleted)}</dd>
            </div>
            <div className="order-details-column">
              <dt>Number of Records Searched</dt>
              <dd>{Number(searchOrder.numberOfSearches).toFixed(0)}</dd>
            </div>
            <div className="order-details-column">
              <dt>Total</dt>
              <dd>
                {searchOrder?.totalOrderCost
                  ? formatCurrency(searchOrder?.totalOrderCost)
                  : '-'}
              </dd>
            </div>
            {searchOrder.status === SearchOrderStatus.IN_PROGRESS &&
              searchTypeDetails?.turnaroundTime && (
                <div className="order-details-column">
                  <dt>Estimated Turnaround Time</dt>
                  <dd>
                    <Notification
                      type={NotificationVariant.INFORMATIONAL}
                      isBadge={true}
                    >
                      {searchTypeDetails.turnaroundTime}
                    </Notification>
                  </dd>
                </div>
              )}
            {searchOrder.status === SearchOrderStatus.RESULTS_AVAILABLE && (
              <div className="order-details-column">
                <dt>Results Available Until</dt>
                <dd>
                  {formatDate(searchOrder.resultsAvailableUntil, {
                    formattingString: "d MMM',' y",
                    ignoreTimeZone: true,
                  })}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      {isDownloading && <FullPageSpinner showBackground={true} />}
    </>
  )
}

SearchOrderDetails.propTypes = propTypes
SearchOrderDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    searchOrder: selectors.searchOrder(state),
    searchServices: selectors.searchServices(state),
    searchOrderCost: selectors.searchOrderCost(state),
  }
}

const mapDispatchToProps = {
  fetchOrderDetails: apiActions.fetchOrderDetails,
  fetchSearchServices: apiActions.fetchSearchServices,
  flashErrorMessageHandler: flashErrorMessage,
  generateSearchOrderCost: apiActions.generateSearchOrderCost,
  resetSearchOrderCost: actions.resetSearchOrderCost,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchOrderDetails
)
