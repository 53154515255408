import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  isActive: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  controlId: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
}

function QuickFilterCard({
  controlId,
  count,
  icon: Icon,
  isActive,
  label,
  onClick,
}) {
  return (
    <button
      aria-pressed={isActive}
      aria-controls={controlId}
      className="card filter-card"
      onClick={onClick}
    >
      <p>
        <Icon aria-hidden="true" />
        {label}
      </p>
      <span className="count">{count}</span>
    </button>
  )
}

QuickFilterCard.propTypes = exact(propTypes)

export default React.memo(QuickFilterCard)
