import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { flashErrorMessage } from 'redux-flash'
import {
  COMPANY_NAME,
  TOAST_ERROR_CONTACT_SUPPORT,
  NO_SERVICES_AGREEMENT_OPTION,
  Path,
} from 'config'
import {
  formatCurrency,
  formatFileName,
  useParticipantsSummary,
  downloadDiscardedParticipants,
  displayInteger,
} from 'utils'
import { Redirect, useHistory } from 'react-router-dom'
import { FlowActions, ParticipantsSummaryTable } from '../components'
import { isEmpty, sum, some, map } from 'lodash'
import { Icon as DownloadIcon } from 'images/download.svg'
import { ConfirmModal, FullPageSpinner } from 'components'
import { selectors as apiSelectors } from 'lp-redux-api'
import { RecordTypes } from 'flatfile-config'

const propTypes = {
  participantsAgreementHash: PropTypes.object,
  participantFileName: PropTypes.string,
  createParticipantsData: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  discardedParticipants: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  isSubmittingParticipantsData: PropTypes.bool.isRequired,
}

const defaultProps = {
  participantsAgreementHash: null,
  participantFileName: null,
}

const FILE_NAME_PREFIX = 'RSP_'

const scrubIraAmount = (val) => val.replaceAll(/[$]/g, '')

function ReviewAndSubmitView({
  participantsAgreementHash,
  participantFileName,
  createParticipantsData,
  flashErrorMessageHandler,
  participantsForSubmission,
  discardedParticipants,
  isSubmittingParticipantsData,
}) {
  const history = useHistory()
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {
    data: participantsSummaryData,
    overview: participantsSummaryOverview,
  } = useParticipantsSummary(participantsForSubmission)

  const handleSubmit = async ({ hasConfirmed = false }) => {
    if (!hasConfirmed) {
      if (!isEmpty(discardedParticipants) && !hasDownloaded) {
        setShowModal(true)
        return
      }
    }
    const isPartialSubmission = some(
      participantsAgreementHash,
      ({ servicesAgreement }) =>
        servicesAgreement === NO_SERVICES_AGREEMENT_OPTION.value
    )
    // scrub the '$' currency from IRA amounts before submission
    const scrubbedParticipantsForSubmission = map(
      participantsForSubmission,
      ({
        traditionalIraAfterTaxAmount,
        traditionalIraPreTaxAmount,
        rothIraAmount,
        ...rest
      }) => ({
        ...rest,
        traditionalIraAfterTaxAmount: scrubIraAmount(
          traditionalIraAfterTaxAmount
        ),
        traditionalIraPreTaxAmount: scrubIraAmount(traditionalIraPreTaxAmount),
        rothIraAmount: scrubIraAmount(rothIraAmount),
      })
    )
    try {
      await createParticipantsData({
        fileName: formatFileName(participantFileName, FILE_NAME_PREFIX),
        participants: scrubbedParticipantsForSubmission,
        summary: {
          totalPlans: participantsSummaryOverview.totalPlans,
          totalParticipants: participantsSummaryOverview.totalParticipants,
          totalRolloverAmount: formatCurrency(
            sum([
              participantsSummaryOverview.traditionalIraPreTaxAmount,
              participantsSummaryOverview.traditionalIraAfterTaxAmount,
              participantsSummaryOverview.rothIraAmount,
            ])
          ),
        },
      })

      history.push({
        pathname: Path.SERVICES_AGREEMENTS,
        state: {
          ignorePrompt: true,
          showAroSuccessModal: true,
          isPartialSubmission,
        },
      })
    } catch (e) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }

  const handleDownload = () => {
    setHasDownloaded(true)
    downloadDiscardedParticipants({
      participants: discardedParticipants,
      originalFileName: participantFileName,
      recordType: RecordTypes.ARO_PARTICIPANT,
    })
  }

  if (isEmpty(participantsAgreementHash)) {
    return <Redirect to="/automatic-rollovers/upload-file" />
  }

  return (
    <div className="card full-height upload-participant-file-view-container">
      {isSubmittingParticipantsData && <FullPageSpinner showBackground />}
      <div className="form-masthead">
        <h2>Lastly, let’s review your submission.</h2>
      </div>
      <div className="form-section">
        <div className="form-header">
          <h3 className="is-marginless">Submission Summary</h3>
        </div>
        <p>
          Review the summary below of the participants included in this
          submission to verify that it contains all the plans and participants
          that you expected. Participants that were removed at any step in this
          process are not reflected in the table below. After submitting these
          participants, you will receive an email notification as confirmation
          that the submission was successful.
        </p>

        <p>
          {COMPANY_NAME['long']} will review your submission, verify the
          participant details and open accounts (if there are no issues). Please
          refer to the Funding Instructions for details about how to send funds
          for the participants below to {COMPANY_NAME['long']}.
        </p>
      </div>
      {!isEmpty(discardedParticipants) && (
        <div className="form-section discarded-participants">
          <div className="form-header">
            <h3 className="is-marginless">
              Participants Not Included in Submission
            </h3>
          </div>
          <p>
            Some participants will not be included in your submission due to
            issues with those participant's data, their plan type and status,
            agreement status, or their rollover amount. Please download the data
            for these participants for your records and resubmit them after the
            issues has been resolved.
          </p>
          <div className="download-container">
            <span>
              Total Discarded Participants:{' '}
              {displayInteger(discardedParticipants.length)}
            </span>
            <button type="button" onClick={handleDownload}>
              <DownloadIcon aria-hidden="true" />
              Download participant data
            </button>
          </div>
        </div>
      )}
      <div className="form-section">
        <ParticipantsSummaryTable
          data={participantsSummaryData}
          title={<h3>Participant File Submission</h3>}
        />
      </div>
      <FlowActions submitContent="Submit" handleSubmit={handleSubmit} />
      {showModal && (
        <ConfirmModal
          onClose={() => setShowModal(false)}
          onConfirm={() => {
            setShowModal(false)
            handleSubmit({ hasConfirmed: true })
          }}
          confirmContent="Yes, Submit"
        >
          <h2>Download Discard Participants</h2>
          <p>
            Are you sure you want to submit without downloading the discarded
            participants?
          </p>
        </ConfirmModal>
      )}
    </div>
  )
}

ReviewAndSubmitView.propTypes = propTypes
ReviewAndSubmitView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    participantFileName: selectors.participantFileName(state),
    participantsAgreementHash: selectors.participantsAgreementHash(state),
    participantsForSubmission: selectors.participantsForSubmission(state),
    discardedParticipants: selectors.discardedParticipants(state),
    isSubmittingParticipantsData: apiSelectors.isLoading(
      state,
      apiActions.createParticipantsData
    ),
  }
}

const mapDispatchToProps = {
  createParticipantsData: apiActions.createParticipantsData,
  flashErrorMessageHandler: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReviewAndSubmitView
)
