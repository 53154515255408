import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from './actions'
import * as productsActions from '../products/actions'
import PropTypes from 'prop-types'
import { StatusTracker, WorkFlowPageLayout } from 'components'
import * as Types from 'types'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  clearNewSearchFlowData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  stages: PropTypes.arrayOf(Types.stage),
  subject: PropTypes.string.isRequired,
  resetSearchOrderCost: PropTypes.func.isRequired,
}

const defaultProps = {
  currentStage: null,
  stages: [],
}

function Layout({
  currentStage,
  children,
  clearNewSearchFlowData,
  title,
  stages,
  subject,
  resetSearchOrderCost,
}) {
  useEffect(() => {
    return () => {
      clearNewSearchFlowData()
      resetSearchOrderCost()
    }
  }, [])

  return (
    <WorkFlowPageLayout title={title} exitPromptSubject={subject}>
      <StatusTracker stages={stages} currentStageName={currentStage} />

      {children}
    </WorkFlowPageLayout>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {}
}

const mapDispatchToProps = {
  clearNewSearchFlowData: actions.clearNewSearchFlowData,
  resetSearchOrderCost: productsActions.resetSearchOrderCost,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
