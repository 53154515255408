import {
  ADD_PLAN_SPONSOR_OPTION,
  ADD_RECORDKEEPER_OPTION,
  ADD_TPA_OPTION,
  ADD_CONSULTANT_OPTION,
} from 'config'
import { updateContactAddress } from 'utils'

// A util to create an object to setup for payload submission
// `company` property within the payload takes in an array of objects
// with each object representing each role (ie. plan sponsor)
function createAudienceObject({ entityType, entity, selectedValue }) {
  if (!selectedValue) return

  const isForNewEntity =
    selectedValue === ADD_PLAN_SPONSOR_OPTION.value ||
    selectedValue === ADD_RECORDKEEPER_OPTION.value ||
    selectedValue === ADD_TPA_OPTION.value ||
    selectedValue === ADD_CONSULTANT_OPTION.value

  const entityContactObject = updateContactAddress(entity)

  const entityObject = {
    type: entityType,
    name: entity.company.name,
    phone: entity.company.phone,
    website: entity.company.website,
    address: {
      street: entity.company.billingStreet,
      city: entity.company.billingCity,
      state: entity.company.billingState,
      zipcode: entity.company.billingPostalCode,
      country: entity.company.billingCountry,
    },
    contact: {
      id: entityContactObject.ID,
      name: {
        title: entityContactObject.title,
        firstName: entityContactObject.firstName,
        lastName: entityContactObject.lastName,
      },
      communication: {
        email: entityContactObject.email,
        phone: entityContactObject.phone,
      },
      address: {
        street: entityContactObject.mailingStreet,
        city: entityContactObject.mailingCity,
        state: entityContactObject.mailingState,
        zipcode: entityContactObject.mailingPostalCode,
        country: entityContactObject.mailingCountry,
      },
    },
  }

  if (isForNewEntity) return entityObject
  return {
    ...entityObject,
    id: selectedValue,
  }
}

export default createAudienceObject
